import {Card, Switch, Typography} from '@mui/material/';
import {styled} from '@mui/material/styles';
import React from 'react';
import {toggleFlag} from '../ros_init';
import {callService} from '../util';

const StyledMainDiv = styled(Card)({
  width: '16rem',
  display: 'flex',
  alignItems: 'center',
  padding: '1rem',
});

const StyledTextDiv = styled('div')({
  paddingLeft: '0.5rem',
});

const StyledTextBold = styled(Typography)({
  fontWeight: 'bold',
});

const RushModeToggle = ({toggle, instance, rushMode}) => {
  const handleToggle = (isChecked) => {
    const reqMsg = {
      flag: 'rush_mode',
      value: isChecked,
    };
    callService(toggleFlag, instance, reqMsg, 'Rush Mode');
  };

  const isEnabled = rushMode ? 'ENABLED' : 'DISABLED';

  return (
    <StyledMainDiv>
      <Switch
        checked={toggle}
        onClick={(e) => handleToggle(e.target.checked)}
        color="primary"
      />
      <StyledTextDiv>
        <StyledTextBold>RUSH MODE IS {isEnabled}</StyledTextBold>
      </StyledTextDiv>
    </StyledMainDiv>
  );
};

export default RushModeToggle;

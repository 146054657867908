import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {FaCheck} from 'react-icons/fa';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const StyledButton = styled(Button)(({theme}) => ({
  width: '15rem',
  height: '7rem',
  display: 'flex',
  flexDirection: 'column',
  '&:disabled': {
    filter: 'contrast(0.5)',
    backgroundColor: 'rgba(0,0,0,0.65)',
    cursor: 'not-allowed',
    '& span': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
}));

const StyledLabel = styled(Typography)(({theme}) => ({
  position: 'absolute',
  bottom: theme.spacing(1),
  fontSize: '1em',
  fontWeight: 900,
}));

const StyledSuccessContainer = styled(Paper)(({theme}) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  flexDirection: 'column',
  width: '15rem',
  height: '7rem',
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  textTransform: 'uppercase',

  '&:disabled': {
    filter: 'contrast(0.5)',
    backgroundColor: 'rgba(0,0,0,0.65)',
    cursor: 'not-allowed',
    '& span': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
}));

const StyledOverlay = styled('div')({
  position: 'absolute',
  width: '100%',
  height: '100%',
});

const StyledIcon = styled(FaCheck)({
  fontSize: '2em',
});

const StyledDisabledText = styled(Typography)({
  fontWeight: 'bolder',
  position: 'absolute',
  color: '#000000',
  fontSize: '1.875rem',
});

const JogButton = ({
  children,
  label,
  disabled,
  color,
  success,
  onClick,
  disableInProgressProcess,
  disabledText,
  failed,
}) => {
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    if (failed && inProgress) {
      setInProgress(false);
    }
  }, [failed, inProgress]);

  const callback = () => {
    setInProgress(false);
  };

  const handleClick = () => {
    if (!disableInProgressProcess) {
      setInProgress(true);
    }
    onClick(callback);
  };

  if (success) {
    return (
      <StyledSuccessContainer>
        <StyledLabel variant="h6" color="inherit">
          {label}
        </StyledLabel>
        <StyledIcon />
      </StyledSuccessContainer>
    );
  }

  return (
    <StyledButton
      variant="contained"
      color={color ?? 'inherit'}
      onClick={handleClick}
      disabled={disabled}
    >
      {disabled && <StyledDisabledText>{disabledText}</StyledDisabledText>}
      {children}
      <StyledLabel color="inherit">{label}</StyledLabel>
      {/* Uncomment if you want to show the circular progress */}
      {/* {!disableInProgressProcess && inProgress && !success && (
        <CircularProgress className={classes.overlay} size={100} />
      )} */}
    </StyledButton>
  );
};

JogButton.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  disabledText: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  success: PropTypes.bool,
  failed: PropTypes.bool,
};

JogButton.defaultProps = {
  disabledText: '',
};

export default JogButton;

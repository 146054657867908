import {styled} from '@mui/material/styles';

export const StyledRealtimeAlert = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '22rem',
  height: '20rem',
});

export const StyledTopPane = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 0.75rem 0 0.5rem',
  margin: 0,
  height: '2rem',
  borderBottom: 'solid 0.0625rem rgba(0, 0, 0, 0.2)',
});

export const StyledTitle = styled('p')({
  fontSize: '0.8rem',
  fontWeight: 'bold',
  padding: 0,
  margin: 0,
});

export const StyledStatus = styled('div')({
  width: '0.5rem',
  height: '0.5rem',
  padding: 0,
  margin: 0,
  borderRadius: '0.5rem',
  border: 'solid 0.125rem rgba(0, 0, 0, 0.75)',
});

export const StyledBodyPane = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'scroll',
  flex: 8.5,
  '&::-webkit-scrollbar': {
    padding: '0.125rem',
    width: '0.6rem',
    background: 'rgba(0, 0, 0, 0.065)',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#fff',
    borderRadius: '1rem',
  },
});

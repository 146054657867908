import {styled} from '@mui/material/styles';
import {DialogContent} from '@mui/material/';
import JogButton from '../../components/JogButton';

export const StyledRoot = styled(DialogContent)({
  display: 'flex',
  flexDirection: 'column',
});

export const StyledContainer = styled('div')({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  columnGap: '3rem',
});

export const StyledSmallButtonWide = styled(JogButton)({
  height: '7rem',
  marginBottom: '1rem',
});

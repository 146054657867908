import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, {useState} from 'react';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import DraggableDialog from '../components/DraggableDialog';
import MisoDialogTitle from '../components/MisoDialogTitle';
import {getGripperState} from '../ros_init.js';
import {callService} from '../util.js';

const StyledButtonContainer = styled('div')(() => ({
  float: 'center',
  display: 'flex',
  justifyContent: 'space-evenly',
}));

const JogConfirmation = ({
  open,
  openJogModal,
  onClose,
  jogging,
  jogRobot,
  alarms,
  instance,
}) => {
  const [resetting, setResetting] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);

  const getGripper = async () => {
    const request = {};
    await callService(getGripperState, instance, request, 'Get Gripper State');
  };

  const robotAlarms = alarms.filter(
    (alarm) =>
      alarm.code === 'collision' && alarm.component_path === '/agent/robot_arm'
  );

  const windowTitle = jogging ? 'Unjog Robot' : 'Jog Robot';
  const windowQuery =
    jogging && Object.keys(robotAlarms).length > 0
      ? 'Collision must be resolved before Unjogging.'
      : `Are you sure you want to ${jogging ? 'unjog' : 'abort action and jog'} the robot?`;

  const handleJogModalClick = () => {
    getGripper();
    onClose();
    openJogModal();
  };

  const handleJogButtonClick = () => {
    jogRobot();
    getGripper();
    onClose();
    if (!jogging) {
      openJogModal();
    }
  };

  return (
    <div>
      <DraggableDialog
        open={open}
        onClose={onClose}
        aria-labelledby="jog-confirmation-dialog"
        fullWidth={true}
      >
        <MisoDialogTitle id="jog-confirmation-dialog" onClose={onClose}>
          {windowTitle}
        </MisoDialogTitle>
        <p
          style={{
            color: 'red',
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '1.5rem',
          }}
        >
          {windowQuery}
        </p>
        <StyledButtonContainer>
          {Object.keys(robotAlarms).length === 0 ? (
            <>
              <Button onClick={handleJogButtonClick}>
                <Typography variant="h4" color="#000000b3">
                  Yes
                </Typography>
              </Button>
              <Button
                onClick={!jogging ? onClose : handleJogModalClick}
                style={{maxWidth: '60%'}}
              >
                <Typography variant="h4" color="#000000b3">
                  {jogging ? 'No - Continue to Jogging Modal' : 'No'}
                </Typography>
              </Button>
            </>
          ) : (
            <Button onClick={handleJogModalClick} style={{maxWidth: '70%'}}>
              <Typography variant="h4" color="#000000b3">
                Continue to Jogging Modal
              </Typography>
            </Button>
          )}
        </StyledButtonContainer>
      </DraggableDialog>
    </div>
  );
};

JogConfirmation.propTypes = {
  jogging: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  openJogModal: PropTypes.func.isRequired,
  jogRobot: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  location: PropTypes.object.isRequired,
  instance: PropTypes.object.isRequired,
  alarms: PropTypes.array.isRequired,
};

export default JogConfirmation;

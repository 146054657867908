import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {Button, Typography} from '@mui/material/';
import DraggableDialog from '../components/DraggableDialog';
import MisoDialogTitle from '../components/MisoDialogTitle';
import {
  abortBehavior,
  abortTrajectory,
  runFryerPoseCalibration,
  setTargetMode,
} from '../ros_init.js';
import {callService} from '../util';
import {DISABLING_LEVEL, TARGET_STATE, URGENCY_LEVEL} from '../enums.js';

const StyledButtonContainerConfirmation = styled('div')({
  display: 'flex',
  justifyContent: 'space-evenly',
  borderRadius: '0.5rem',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '1rem',
});

const StyledDialogTitle = styled(MisoDialogTitle)({
  padding: 0,
  fontSize: '1.75rem',
  lineHeight: '2rem',
  fontWeight: 700,
});

const StyledButton = styled(Button)({
  margin: '1em',
  width: '90%',
  boxShadow: '0 0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
});

const FryerPoseCalibrationModal = ({open, onClose, user, instance}) => {
  const [calibrating, setCalibrating] = useState(false);
  const [calibratingSuccess, setCalibratingSuccess] = useState(false);
  const [calibratingFailed, setCalibratingFailed] = useState(null);

  const handleOnClick = async () => {
    const abortRequest = {};
    const jogRequest = {
      user: {
        permissions: DISABLING_LEVEL.SUPPORT,
        name: user,
      },
      mode: TARGET_STATE.JOGGING,
      urgency: URGENCY_LEVEL.ASAP,
      pose: '',
      reason: 'support_jogging',
    };

    await callService(
      setTargetMode,
      instance,
      jogRequest,
      'Set Manual Mode',
      null,
      async () => {
        await callService(
          abortBehavior,
          instance,
          abortRequest,
          'Abort Robot Behavior',
          '',
          '',
          async () => {
            await callService(
              abortTrajectory,
              instance,
              abortRequest,
              'Abort Robot Trajectory'
            );
          }
        );
      }
    );

    const request = {
      behavior_name: 'run_fryer_pose_calibration',
      pickup: '',
      dropoff: '',
      pickup_2: '',
      dropoff_2: '',
      end_joints: 'fpc_p1_x',
      rebuild_behavior_cache: true,
    };

    setCalibrating(true);
    setCalibratingFailed(false);

    const allCallback = () => {
      setCalibrating(false);
    };

    const successCallback = () => {
      setCalibrating(false);
      setCalibratingSuccess(true);
      setTimeout(() => {
        setCalibratingSuccess(false);
      }, 1500);
    };

    const errorCallback = () => {
      setCalibrating(false);
      setCalibratingFailed(true);
      setTimeout(() => {
        setCalibratingFailed(null);
      }, 1500);
    };

    callService(
      runFryerPoseCalibration,
      instance,
      request,
      'Fryer Pose Calibration',
      allCallback,
      successCallback,
      errorCallback
    );
  };

  return (
    <div>
      <DraggableDialog
        open={open}
        onClose={onClose}
        aria-labelledby="jog-confirmation-dialog"
        fullWidth
        maxWidth="md"
      >
        <StyledDialogTitle onClose={onClose} disableTypography>
          Pose Calibration
        </StyledDialogTitle>
        <p
          style={{
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center',
            color: '#FC648F',
            fontWeight: 'bold',
            fontSize: '2rem',
            background: '#FCD2DE',
            border: '0.125rem solid #FC648F',
            borderRadius: '0.5rem',
            margin: '0 2rem',
          }}
        >
          Please ensure there are NO BASKETS, SHELVES, AND IN-FRYER LOCALIZERS
          inside the barrier
        </p>
        {!calibrating && (
          <p
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: '1.5rem',
              padding: '2rem 0',
            }}
          >
            Do you want to continue with Pose Calibration?
          </p>
        )}
        {!calibrating ? (
          <StyledButtonContainerConfirmation>
            <StyledButton
              onClick={handleOnClick}
              style={{background: '#525057'}}
            >
              <Typography variant="h5" style={{color: 'white'}}>
                Continue
              </Typography>
            </StyledButton>
            <StyledButton
              onClick={() => {
                onClose();
              }}
            >
              <Typography variant="h5" style={{color: '#5C5C5C'}}>
                Cancel
              </Typography>
            </StyledButton>
          </StyledButtonContainerConfirmation>
        ) : (
          <StyledButtonContainerConfirmation>
            <StyledButton style={{background: '#E7E9EC'}} disabled>
              <Typography variant="h5" style={{color: '#5C5C5C'}}>
                Pose Calibration in Progress
              </Typography>
            </StyledButton>
          </StyledButtonContainerConfirmation>
        )}
      </DraggableDialog>
    </div>
  );
};

FryerPoseCalibrationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  user: PropTypes.string.isRequired,
  instance: PropTypes.object.isRequired,
};

export default FryerPoseCalibrationModal;

import React from 'react';
import SensorToggle from './SensorToggle';
import {Dialog} from '@mui/material/';
import {snakeCaseToNormal} from '../../util';
import {styled} from '@mui/material/styles';
import {Grid2} from '@mui/material';

const StyledContainer = styled('div')({
  padding: '1rem',
  backgroundColor: '#E7E9EC',
});

const StyledTitleText = styled('p')({
  fontWeight: 'bold',
  margin: 0, // Ensure there's no extra margin or padding
});

const StyledGridContainer = styled(Grid2)({
  display: 'flex',
  justifyContent: 'flex-start', // Align items to the left
});

const StyledGridStyle = styled(Grid2)({
  backgroundColor: '#FFFFFF',
  margin: '1rem',
  width: '20rem',
  borderRadius: '1rem',
  boxShadow: '0.25rem 0.25rem #BDBFC1',
});

const StyledSubTitle = styled('p')({
  fontWeight: 'bold',
  color: '#6C6C6C',
});

const RobotSensorControlModal = ({
  open,
  onClose,
  disabledFryerSensorsArray,
  getDispenserLockSensor,
  getGripperSensor,
  numFryerSensors,
  relayState,
  getElevatorSensor,
  instance,
}) => {
  const fryerLocksRelayState =
    relayState !== undefined
      ? relayState.filter((item) => item.name === 'fryer_locks')
      : true;

  return (
    <div>
      <Dialog open={open} onClose={onClose} maxWidth="lg">
        <StyledContainer>
          <div>
            <StyledTitleText>Robot Sensor Control</StyledTitleText>
          </div>
          <StyledGridContainer container>
            <StyledGridStyle item xs={3}>
              <SensorToggle
                title={'Gripper'}
                toggle={getGripperSensor}
                instance={instance}
                toggleName={'getGripperSensor'}
              />
            </StyledGridStyle>
            <StyledGridStyle item xs={3}>
              <SensorToggle
                title={'Elevator'}
                toggle={getElevatorSensor}
                instance={instance}
                toggleName={'getElevatorSensor'}
              />
            </StyledGridStyle>
            <StyledGridStyle item xs={3}>
              <SensorToggle
                title={'Dispenser Lock'}
                toggle={getDispenserLockSensor}
                instance={instance}
                toggleName={'getDispenserLockSensor'}
              />
            </StyledGridStyle>
          </StyledGridContainer>

          {/* Fryer Locks Section */}
          <div>
            <StyledTitleText>Fryer Locks</StyledTitleText>
          </div>
          <StyledGridContainer container>
            {/* Map through the fryer sensors if available */}
            {Object.keys(numFryerSensors).length > 0 && (
              <>
                {numFryerSensors.value !== 'None' &&
                  Object.keys(numFryerSensors).map((sensorKey, index) => (
                    <StyledGridStyle item xs={3} key={index}>
                      <SensorToggle
                        title={snakeCaseToNormal(sensorKey)}
                        toggle={disabledFryerSensorsArray}
                        instance={instance}
                        toggleName={'disabledFryerSensorsArray'}
                      />
                    </StyledGridStyle>
                  ))}
              </>
            )}

            {/* Fryer Relay toggle */}
            <StyledGridStyle item xs={3}>
              <SensorToggle
                title={'Fryer Relay'}
                toggle={fryerLocksRelayState}
                instance={instance}
                toggleName={'relayState'}
              />
            </StyledGridStyle>
          </StyledGridContainer>
        </StyledContainer>
      </Dialog>
    </div>
  );
};

export default RobotSensorControlModal;

import {styled} from '@mui/material/styles';

export const StyledToggleList = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const StyledTopPane = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '0.35rem 0.5rem',
  borderBottom: 'solid 0.03125rem rgba(0, 0, 0, 0.1)',
  boxShadow: '0.05rem 0.05rem 0.1rem 0.025rem rgba(0, 0, 0, 0.1)',
});

export const StyledRight = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '0.25rem',
});

export const StyledTitle = styled('p')({
  fontSize: '0.8rem',
  padding: 0,
  margin: 0,
});

export const StyledIexport = styled('div')({
  fontSize: '1rem',
});

export const StyledBodyPane = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const StyledToggleItem = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '0.2rem 0.6rem 0.2rem 0.75rem',
  borderBottom: 'solid 0.03125rem rgba(0, 0, 0, 0.1)',
  backgroundColor: 'rgba(0, 0, 0, 0.1)',
});

export const StyledItemName = styled('p')({
  fontSize: '0.85rem',
  fontWeight: 'bold',
  paddingRight: '0.3rem',
  margin: 0,
});

export const StyledItemLeft = styled('div')({
  flexGrow: 1,
});

export const StyledItemRight = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

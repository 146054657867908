import React from 'react';
import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Spinner from 'react-spinkit';
import {getInfo} from '../util';

const StyledRoot = styled('div')(() => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}));

const InstancePowerStateScreen = (props) => {
  const {instance, powerState} = props;

  return (
    <StyledRoot>
      <Typography variant="h1" gutterBottom style={{color: 'black'}}>
        {`${powerState} ${instance ? getInfo(instance).name : ''} ...`}
      </Typography>
      <Spinner
        name="circle"
        style={{
          width: '20em',
          height: '20em',
        }}
      />
    </StyledRoot>
  );
};

InstancePowerStateScreen.propTypes = {
  instance: PropTypes.string.isRequired,
  powerState: PropTypes.string.isRequired,
};

export default InstancePowerStateScreen;

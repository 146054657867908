import {styled} from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';
import {Paper} from '@mui/material/';

export const StyledButtonBase = styled(ButtonBase)({
  margin: '0.7%',
  display: 'flex',
  flexDirection: 'grid',
  gridAutoFlow: 'row',
});

export const StyledPaper = styled(Paper)({
  width: '15vw',
  height: '100%',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
});

export const StyledLabel = styled('div')(({theme}) => ({
  padding: theme.spacing(1),
}));

export const StyledLabelContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

export const StyledOffline = styled('div')(({theme}) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.grey[300],
  color: theme.palette.grey[500],
}));

export const StyledLogo = styled('img')({
  width: '5em',
  height: '5em',
  display: 'block',
  marginInline: 'auto',
});

export const StyledLeftIcon = styled('span')({
  top: '0.9375rem',
  left: '0.9375rem',
  fontSize: '1.5rem',
  color: '#212121',
  position: 'absolute',
});

export const StyledBtnRightIcon = styled('button')({
  color: '#212121',
  padding: 0,
  margin: 0,
  border: 'none',
  backgroundColor: 'transparent',
  '&:hover': {
    cursor: 'pointer',
  },
});

export const StyledButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const StyledNumRedAlertsContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  paddingRight: '1rem',
});

export const StyledContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
});

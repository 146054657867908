import {styled} from '@mui/material/styles';
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material/';

export const StyledPaper = styled(Paper)(({inSingleStepModal}) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: '0',
  padding: '1rem',
  backgroundColor: '#E7E9EC',
  borderRadius: '0',

  ...(inSingleStepModal
    ? {
        justifyContent: 'center',
        height: '100%',
        padding: '0 3rem',
        backgroundColor: '#E7E9EC',
      }
    : {}),
}));

export const StyledHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginTop: '1rem',
  marginBottom: '1.5rem',
});

export const StyledBackBtn = styled(Button)({
  height: '2.5rem',
  width: '2.5rem',
  minWidth: 'auto',
  marginRight: '2rem',
});

export const StyledCloseBtn = styled(Button)({
  height: '2.5rem',
  width: '2.5rem',
  minWidth: 'auto',
  marginLeft: 'auto',
});

export const StyledWarning = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '3.5rem',
  marginBottom: '1.5rem',
  fontSize: '1.25rem',
  lineHeight: '1.5rem',
  fontWeight: 700,
  color: '#FC648F',
  border: '0.1875rem solid #FC648F',
  borderRadius: '0.75rem',
  background:
    'linear-gradient(0deg, #FCD2DE, #FCD2DE), ' +
    'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));',
});

export const StyledHeaderTitle = styled(Typography)({
  fontSize: '1.75rem',
  fontWeight: 700,
  lineHeight: '2rem',
});

export const StyledReasonTitle = styled(Typography)(({inSingleStepModal}) => ({
  marginBottom: '1.5rem',
  fontSize: '1.5rem',
  lineHeight: '2rem',
  textAlign: 'center',
  fontWeight: 700,
  color: '#525252',

  ...(inSingleStepModal
    ? {
        marginBottom: '1rem',
        fontSize: '1.25rem',
        lineHeight: '1.5rem',
        fontWeight: 700,
        textAlign: 'center',
        color: '#5C5C5C',
      }
    : {}),
}));

export const StyledList = styled(List)({
  maxHeight: '35vh',
  marginBottom: '1.5625rem',
  padding: '0 1.5rem',
  overflowY: 'auto',
});

export const StyledListItemContainer = styled('div')(({inSingleStepModal}) => ({
  marginBottom: '1rem',
  '&>:hover': {
    backgroundColor: '#91CCFD',
  },

  ...(inSingleStepModal
    ? {
        marginBottom: '0.5rem',
        '&>:hover': {
          backgroundColor: '#91CCFD',
        },
      }
    : {}),
}));

export const StyledListItem = styled(ListItem)(({inSingleStepModal}) => ({
  height: '4rem',
  borderRadius: '0.75rem',
  backgroundColor: 'white',
  boxShadow: '0 0.25rem 0.25rem 0 #00000040',

  ...(inSingleStepModal
    ? {
        height: '3rem',
        borderRadius: '0.75rem',
        backgroundColor: 'white',
        boxShadow: '0 0.25rem 0.25rem 0 #00000040',
      }
    : {}),
}));

export const StyledListItemText = styled(ListItemText)({
  textAlign: 'center',
  fontSize: '1.25rem',
  lineHeight: '1.5rem',
  color: '#525057',
  fontWeight: 500,
});

export const StyledSubmitBtnWrap = styled('div')({});

export const StyledSubmitBtn = styled(Button)({
  height: '4rem',
  width: '100%',
  marginBottom: '1rem',
  backgroundColor: 'rgba(82,80,87,0.6)',
  borderRadius: '0.75rem',
  color: 'white',
  boxShadow: '0.125rem 0.25rem 0.25rem 0 #0000004D',
  '&:hover': {
    backgroundColor: '#525057',
  },
});

import React from 'react';
import {styled} from '@mui/material/styles';
import {Button, Card, Dialog} from '@mui/material/';
import {colors} from '../styles';
import {callService, IconComponent} from '../util';
import {requestHelp} from '../ros_init';
import {DISABLING_LEVEL} from '../enums';

const StyledContainer = styled(Card)({
  padding: '2.5rem',
  textAlign: 'center',
  fontWeight: 'bold',
});

const StyledTitle = styled('h1')({
  fontSize: '1.7rem',
});

const StyledSubtitle = styled('h2')({
  padding: '1.5rem 0 1.3rem 0',
  fontSize: '1.25rem',
});

const StyledSubText = styled('p')({
  fontSize: '1rem',
  paddingBottom: '0.8rem',
});

const StyledTextRed = styled('span')(({theme}) => ({
  color: theme.palette.error.main, // Assuming colors.darkRed maps to a theme color
}));

const StyledTextGreen = styled('span')(({theme}) => ({
  color: theme.palette.success.main, // Assuming colors.green maps to a theme color
}));

const StyledBtnDiv = styled(Button)({
  height: '5vh',
  width: '85%',
  margin: '1rem',
  fontSize: '1.5rem',
  borderRadius: '2rem',
});

const StyledBtnConfirm = styled(Button)(({theme}) => ({
  height: '5vh',
  width: '85%',
  margin: '1rem',
  fontSize: '1.5rem',
  borderRadius: '2rem',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white, // Assuming colors.white maps to a theme color
}));
const TroubleshootPopup = ({open, instance, onClose, needHelp, user}) => {
  const handleTroubleshootMode = () => {
    const request = {
      user: {permissions: DISABLING_LEVEL.SUPPORT, name: user},
      state: 'waiting_confirmation',
    };
    callService(
      requestHelp,
      instance,
      request,
      'Waiting For Customer Feedback'
    );
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <StyledContainer>
        <StyledTitle
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div style={{display: 'flex', alignItems: 'center'}}>
            <IconComponent
              icon2="Barrier"
              width="2vw"
              color={colors.black ?? ''}
            />
            <span style={{marginLeft: '0.5em'}}>Addressed Issue</span>
          </div>
        </StyledTitle>
        {!needHelp ? (
          <>
            <StyledSubtitle>
              Requesting Troubleshooting Mode will ask the user to <br />
              Close the Barrier and Release the E-Stop.
              <br />
              Once criteria are met, the icon will turn green.
            </StyledSubtitle>
            <StyledSubtitle style={{display: 'flex', alignItems: 'center'}}>
              <IconComponent
                icon2="Barrier"
                width="1.5vw"
                color={colors.darkRed}
              />
              <span style={{marginLeft: '0.5em'}}>
                Troubleshooting Mode Requested.
              </span>
              <StyledTextRed style={{marginLeft: '0.5em'}}>
                Barrier is Open and/or E-Stop is Engaged
              </StyledTextRed>
            </StyledSubtitle>
            <StyledSubtitle style={{display: 'flex', alignItems: 'center'}}>
              <IconComponent
                icon2="Barrier"
                width="1.5vw"
                color={colors.green}
              />
              <span style={{marginLeft: '0.5em'}}>
                System is ready for Troubleshooting.{' '}
              </span>
              <StyledTextGreen style={{marginLeft: '0.5em'}}>
                Barrier is Closed and E-Stop is Released
              </StyledTextGreen>
            </StyledSubtitle>
          </>
        ) : null}
        <div style={{display: 'flex', justifyContent: 'space-around'}}>
          <StyledBtnDiv onClick={onClose} variant="outlined">
            CLOSE
          </StyledBtnDiv>
          <StyledBtnConfirm variant="outlined" onClick={handleTroubleshootMode}>
            YES
          </StyledBtnConfirm>
        </div>
      </StyledContainer>
    </Dialog>
  );
};

export default TroubleshootPopup;

import {styled} from '@mui/material/styles';
import JogButton from '../../components/JogButton';

export const StyledConfirmationModal = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '2rem',
  alignItems: 'center',
});

export const StyledCenterPane = styled('div')({
  display: 'flex',
  flex: 1.25,
  alignItems: 'center',
  justifyContent: 'center',
});

export const StyledTopPane = styled('div')({
  display: 'flex',
  flex: 0.8,
  alignItems: 'flex-end',
  justifyContent: 'flex-start',
});

export const StyledTitle = styled('p')({
  fontSize: '1.25rem',
  fontWeight: 400,
  padding: 0,
  margin: 0,
  letterSpacing: 0,
});

export const StyledPositiveButton = styled(JogButton)({
  width: '6.25rem',
  height: 'auto', // Allow the height to adjust based on content
  backgroundColor: '#68535c',
  color: 'white',
  maxHeight: '9em',
  background: 'red',
  fontWeight: '900',
  fontSize: '3rem',
});

export const StyledNegativeButton = styled(JogButton)({
  width: '6.25rem',
  height: 'auto', // Allow the height to adjust based on content
  background: 'red',
  color: 'white',
  maxHeight: '9em',
  background: 'red',
  fontWeight: '900',
  fontSize: '3rem',
});

export const StyledBottomPane = styled('div')({
  display: 'flex',
  flex: 0.8,
  alignItems: 'center',
  justifyContent: 'space-evenly', // This makes buttons spaced evenly
  gap: '0.625rem', // Space between buttons
});

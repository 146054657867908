import IconButton from '@mui/material/IconButton';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {BsExclamationTriangle} from 'react-icons/bs';
import {FaPowerOff, FaStopCircle} from 'react-icons/fa';
import {HiSignal} from 'react-icons/hi2';
import {useNavigate} from 'react-router-dom';
import SwiperCore from 'swiper';
import {Pagination} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import Power from '../assets/helper-icons/Power';
import AlarmMenu from '../components/AlarmMenu';
import Dispenser from '../components/Dispenser';
import Elevator from '../components/Elevator';
import Loading from '../components/Loading';
import PowerMenu from '../components/PowerMenu';
import SlotCard from '../components/SlotCard';

import {
  ALARM_ID,
  DISABLING_LEVEL,
  POWER_STATE,
  ROBOT_ARM_STATE,
  SLOT_TYPES,
  TARGET_STATE,
  URGENCY_LEVEL,
} from '../enums';
import {colors, mediumTextSize} from '../styles';

import packageJson from '../../package.json';
import Timer from '../components/Timer';
import {
  abortBehavior,
  abortTrajectory,
  close_ros,
  setTargetMode,
} from '../ros_init';
import {
  callService,
  convertComponentPathToReadableText,
  getInfo,
  IconComponent,
  snakeCaseToNormal,
  toReadableString,
} from '../util';
import store from '../store/index';
import {RESET_ALL} from '../actions/actionTypes';

import Basket from '../assets/helper-icons/Basket';
import CameraVideo from '../assets/helper-icons/CameraVideo';
import Diagnostics from '../assets/helper-icons/Diagnostics';
import GripHorizontal from '../assets/helper-icons/GripHorizontal';
import Jog from '../assets/helper-icons/Jog';
import OffsetTuning from '../assets/helper-icons/OffsetTuning';
import PoseCalibration from '../assets/helper-icons/PoseCalibration';
import ModeToggle from '../components/ModeToggle';
import RushModeToggle from '../components/RushModeToggle';
import TroubleshootPopup from '../components/TroubleshootPopup';
import {useTranslationAlerts} from '../hooks';
import BasketModal from '../modals/BasketModal';
import DiagnosticsModal from '../modals/DiagnosticsModal';
import ElevatorModal from '../modals/ElevatorModal';
import FryerPoseCalibrationModal from '../modals/FryerPoseCalibrationModal';
import JogConfirmation from '../modals/JogConfirmation';
import ElevatorConfirmation from '../modals/ElevatorConfirmation';
import JogModal from '../modals/JogModal';
import OffsetTuningModal from '../modals/OffsetTuningModal';
import ResolveCollisionModal from '../modals/ResolveCollisionModal';
import RobotSensorControlModal from '../modals/RobotSensorControl';
import {Tooltip} from 'react-tooltip';
import {toggleLoadingPowerState} from '../actions/uiActions';

//install Swiper modules
SwiperCore.use([Pagination]);
// TODO: Refactor to use Material UI

const StyledCommandButtonHolder = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  gap: '0.5rem',
}));
const StyledCommandButtonTop = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));
const StyledCommandButton = styled(IconButton)(({jogging}) => ({
  width: '5rem',
  height: '3rem',
  marginRight: '0.5rem',
  borderRadius: '1.5rem',
  border: '0.125rem solid #808080',
  ...(jogging
    ? {
        border: '0.125rem solid #FC648F',
        backgroundColor: '#FCD2DE',
      }
    : {}),
}));
const StyledCommandButtonAlarm = styled('div')(() => ({
  border: '0.125rem solid #FC648F',
  backgroundColor: '#FCD2DE',
}));
const StyledRightSlide = styled('div')(() => ({
  position: 'relative',
  color: colors.greyishBrown,
  backgroundColor: colors.whiteTwo,
  flex: 1,
  flexGrow: 6,
  overflow: 'scroll',
}));
const StyledTopRow = styled('div')(() => ({
  display: 'flex',
}));
const StyledSearchArea = styled('div')(() => ({
  flex: 1,
  display: 'flex',
  flexWrap: 'nowrap',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));
const StyledButtonList = styled('div')(({theme}) => ({
  display: 'flex',
  flexWrap: 'nowrap',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  paddingLeft: theme.spacing(3),
  marginTop: '2rem',
}));
const StyledFoodRowContainer = styled('div')(({theme}) => ({
  display: 'flex',
  flex: 1,
  width: '100%',
  flexDirection: 'row',
  marginBottom: theme.spacing(3),
  marginRight: '2rem',
}));
const StyledFoodAreaContainer = styled('div')(() => ({
  flexGrow: 1,
}));
const StyledAreaTitle = styled('div')(() => ({
  paddingLeft: '0.75rem',
  fontWeight: 600, // TODO typography
  marginBottom: '0.1rem',
}));
const StyledAreaTitleTypography = styled(Typography)(() => ({
  paddingLeft: '0.75rem',
  fontWeight: 600, // TODO typography
  marginBottom: '0.1rem',
}));
const StyledRackContainer = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  marginTop: '1.25rem',
}));
const StyledObjectsContainer = styled('div')(() => ({
  marginLeft: '0.75rem',
  marginRight: '0.75rem',
  marginTop: '1.5rem',
}));
const StyledSwiperSlide = styled(SwiperSlide)(() => ({
  width: '7.75em',
}));
const StyledToggleDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: '2rem',
}));
const StyledVersionWrap = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
}));
const StyledVersion = styled(Typography)(() => ({
  position: 'sticky',
  left: '100%',
  top: '100%',
  fontSize: '1.5rem',
  background: 'rgb(104, 83, 92)',
  width: '10%',
  textAlign: 'center',
  color: 'white',
}));
const StyledTitle = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  paddingRight: '1rem',
  minHeight: '8vh',
  height: 'auto',
  background: '#68535c',
  marginRight: '0.5rem',
  minWidth: '10vw',
}));
const StyledTimeHolder = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
}));
const StyledLocationHolder = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '.5em',
  justifyContent: 'space-between',
  flex: 1,
}));
const StyledLogo = styled('img')(() => ({
  width: '3em',
  height: '3em',
}));
const StyledLocationColumn = styled('div')(() => ({
  paddingTop: '.5em',
  display: 'flex',
  flexDirection: 'column',
}));
const StyledNotificationContainer = styled('div')(() => ({
  width: '92%',
  border: '0.2rem solid red',
  borderRadius: '0.5rem',
  padding: '0 0.5rem',
  display: 'flex',
  alignItems: 'flex-start',
  backgroundColor: '#F1CBCC',
  flexDirection: 'column',
}));
const StyledNotificationText = styled('ul')(() => ({
  fontWeight: 'bold',
  textTransform: 'capitalize',
  margin: '0.2rem 0',
  listStyleType: 'disc',
  paddingLeft: '1rem',
}));

const ComingnextFoodTypography = styled(Typography)({
  paddingTop: '0.5rem',
  fontSize: '0.9375rem',
  fontWeight: 600,
  textAlign: 'center',
});

const StyledComingNext = styled('div')(() => ({
  backgroundColor: 'white',
  borderColor: 'grey',
  borderWidth: '0.0625rem',
  borderStyle: 'solid',
  textSize: mediumTextSize,
  minHeight: '6vh',
  height: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
}));

const ComingNext = ({content, donenessLabel}) => {
  return (
    <StyledComingNext>
      <div style={{display: 'block'}}>
        <li>
          <ComingnextFoodTypography>{content}</ComingnextFoodTypography>
        </li>
        <li>
          <ComingnextFoodTypography style={{fontSize: '0.75rem'}}>
            {donenessLabel}
          </ComingnextFoodTypography>
        </li>
      </div>
    </StyledComingNext>
  );
};

const renderButton = (
  ariaLabel,
  onClick,
  tooltipId,
  iconComponent,
  tooltipText,
  additionalProps = {}
) => (
  <>
    <StyledCommandButton
      aria-label={ariaLabel}
      onClick={onClick}
      data-tooltip-id={tooltipId}
      {...additionalProps}
    >
      {iconComponent}
    </StyledCommandButton>
    <Tooltip id={tooltipId} place="top" effect="solid">
      {tooltipText}
    </Tooltip>
  </>
);

const InstanceScreen = (props) => {
  const {
    instance,
    instanceRedux,
    jogConfirmation,
    elevatorConfirmation,
    troubleshootConfirmation,
    diagnosticsModal,
    jogModal,
    elevatorModal,
    resolveCollisionModal,
    offsetTuningModal,
    fryerPoseCalibrationModal,
    basketModal,
    robotSensorControlModal,
    openDiagnosticsModal,
    openJogConfirmation,
    openElevatorConfirmation,
    openTroubleshootConfirmation,
    openJogModal,
    openElevatorModal,
    openRobotSensorControl,
    closeDiagnosticsModal,
    closeJogConfirmation,
    closeElevatorConfirmation,
    closeTroubleshootConfirmation,
    closeJogModal,
    closeElevatorModal,
    closeResolveCollisionModal,
    closeOffsetTuningModal,
    closeFryerPoseCalibrationModal,
    closeBasketModal,
    closeRobotSensorControl,
    openOffsetTuningModal,
    openFryerPoseCalibrationModal,
    openBasketModal,
    user,
    instanceInfo,
    initRealTimeAlerts,
  } = props;
  const {
    cookingBaskets,
    shelfBaskets,
    diagnosticsState,
    gripperState,
    getGripperSensor,
    getDispenserLockSensor,
    getElevatorSensor,
    inRack,
    readyNextRack,
    notificationState,
    scannerState,
    robotArmState,
    uiState,
    homeJoints,
    hopperState,
    elevatorState,
    offsetUpdated,
    offsetDataObj,
    versionNumber,
    awsAvailable,
    rosHeaderTimeStamp,
    foodMenu,
    basketList,
    baskets,
    cameraID,
    fryersState,
    disabledFryerSensorsArray,
    numFryerSensors,
    relayState,
    rushModeFlag,
  } = instanceRedux;

  const initialState = {
    initialCardCount: 30,
    cardWidth: 0,
  };

  const [state, setState] = useState(initialState);
  const swiperRef = useRef(null);
  const navigate = useNavigate();
  let computeIPAddress = instanceInfo.ip_address;
  let siteId = instanceInfo.site_id;
  let deviceid = instanceInfo.deviceid;

  useEffect(() => {
    setState(initialState);
  }, [props.instance]);

  const toggleJog = async () => {
    const {instance, user} = props;
    const abort_request = {};
    if (!uiState) {
      window.alert('Jogging not implemented.');
      return;
    }

    const createModeRequest = (mode, reason) => ({
      user: {
        permissions: DISABLING_LEVEL.SUPPORT,
        name: user,
      },
      mode,
      urgency: URGENCY_LEVEL.ASAP,
      pose: '',
      reason,
    });

    const abortRobotBehaviorTrajectory = async () => {
      await callService(
        abortBehavior,
        instance,
        abort_request,
        'Abort Robot Behavior'
      );
      await callService(
        abortTrajectory,
        instance,
        abort_request,
        'Abort Robot Trajectory'
      );
    };

    try {
      if (uiState.current_mode !== TARGET_STATE.JOGGING) {
        // If not in jogging mode, switch to jogging mode and then abort behavior and trajectory
        const jogRequest = createModeRequest(
          TARGET_STATE.JOGGING,
          'support_jogging'
        );
        await callService(
          setTargetMode,
          instance,
          jogRequest,
          'Set Jogging Mode',
          null,
          async () => {
            await abortRobotBehaviorTrajectory();
          }
        );
      } else {
        // If already in jogging mode, abort behavior and trajectory, then switch to cooking mode
        await abortRobotBehaviorTrajectory();
        const targetRequest = createModeRequest(
          TARGET_STATE.COOKING,
          'cooking'
        );
        await callService(
          setTargetMode,
          instance,
          targetRequest,
          'Set Cooking Mode',
          null,
          () => {}
        );
      }
    } catch (error) {
      console.error('Error toggling jog mode:', error);
    }
  };

  const getMatchingBasketsWithRack = (rack) => {
    // Return all the baskets in both the rack and fryer slots.
    const {cookingBaskets} = instanceRedux;
    let matches = [];
    cookingBaskets.forEach((basket) => {
      if (basket.basket_id !== 0) {
        // if (basket.basket_id && rackBasketIDs.has(basket.basketID)) {
        matches.push(basket.basketID);
      }
    });
    return matches;
  };

  const getMatchingBasketsSet = () => {
    // Return a set of baskets in a rack and the fryer slots.
    const {inRack} = instanceRedux;
    let multiLocationBaskets = getMatchingBasketsWithRack(inRack);
    return new Set(multiLocationBaskets);
  };

  const getSlotWidth = (itemCount) => {
    const totalMargins = 1.25 * itemCount;
    return (100 - totalMargins) / itemCount + '%';
  };

  const goToHub = async () => {
    const {instance} = props;
    await close_ros(navigate);
    store.dispatch({
      type: RESET_ALL,
      host: instance,
    });
    initRealTimeAlerts();
  };

  const handleSwiperInit = () => {
    if (swiperRef.current) {
      const slideWidth = swiperRef.current.swiper.slides[0]?.offsetWidth; // Get width of the first slide
      setState((prevState) => ({
        ...prevState,
        cardWidth: slideWidth,
      }));
    }
  };

  // Function to handle loading more cards
  const loadQueue = () => {
    if (state.cardWidth === 0) {
      return;
    }

    setState((prevState) => {
      const newInitialCardCount =
        prevState.initialCardCount + window.innerWidth / prevState.cardWidth;
      return {
        initialCardCount: newInitialCardCount,
        cardWidth: prevState.cardWidth,
      };
    });
  };

  const handleRemoteViewerModal = (computeIPAddress) => {
    if (!computeIPAddress) {
      return;
    }

    const touchscreenIP = computeIPAddress
      .split('.')
      .map((part, i, arr) => (i === arr.length - 1 ? parseInt(part) + 7 : part))
      .join('.');
    const url = 'http://' + touchscreenIP + ':6080/vnc_lite.html';
    window.open(url, '_blank');
  };

  const handleCameraModal = (cameraId) => {
    const url =
      'https://dwspectrum.digital-watchdog.com/systems/1d5ddfb9-9ad9-41d0-b643-e8582dc6bf6d/view/' +
      cameraId;
    window.open(url, '_blank');
  };

  const {getMessage} = useTranslationAlerts();
  const jogging = Boolean(
    uiState && uiState.current_mode === TARGET_STATE.JOGGING
  );
  const needHelp = Boolean(
    uiState && uiState.need_help_state === 'help_requested'
  );
  const last_mode_user =
    uiState && uiState.last_mode_user && uiState.last_mode_user.name;
  const rushMode = Boolean(uiState && uiState.rush_mode);

  const modeDetails = [
    {label: 'Current Mode', value: uiState && uiState.current_mode},
    {
      label: 'Current Mode Reason',
      value: uiState && uiState.current_mode_reason,
    },
    {label: 'Target Mode', value: uiState && uiState.target_mode},
    {label: 'Target Mode Reason', value: uiState && uiState.target_mode_reason},
  ];

  let holdClearMechanismStatus, cycleEStop;
  if (notificationState) {
    holdClearMechanismStatus = Object.keys(notificationState).reduce(function (
      alarm,
      val
    ) {
      if (notificationState[val].id === ALARM_ID.E_STOPPED) {
        alarm[val] = notificationState[val];
      }
      return alarm;
    }, {});
    cycleEStop = Object.keys(holdClearMechanismStatus).length > 0;
  }

  let troubleshootingReady = !cycleEStop;

  let slotContent = [];
  let shelfContent = [];
  const fryerSlotWidth = getSlotWidth(cookingBaskets.length);
  let inRackSlotWidth = '10vw';

  const multiLocationBaskets = getMatchingBasketsSet();
  for (let index = 0; index < cookingBaskets.length; index++) {
    let actionSlot = cookingBaskets[index];
    slotContent.push(
      <SlotCard
        instance={instance}
        key={index}
        index={index}
        slotType={SLOT_TYPES.FRYER}
        fryersState={fryersState}
        slotObj={actionSlot}
        width={fryerSlotWidth}
        multiLocation={
          !actionSlot.empty && multiLocationBaskets.has(actionSlot.basketID)
        }
        foodMenu={foodMenu}
        rosHeaderTimeStamp={rosHeaderTimeStamp}
        user={user}
      />
    );
  }

  for (let index = 0; index < shelfBaskets.length; index++) {
    let actionSlot = shelfBaskets[index];
    shelfContent.push(
      <SlotCard
        instance={instance}
        key={index}
        index={index}
        slotType={SLOT_TYPES.SHELF}
        slotObj={actionSlot}
        width={fryerSlotWidth}
        foodMenu={foodMenu}
        rosHeaderTimeStamp={rosHeaderTimeStamp}
        user={user}
      />
    );
  }

  let inRackContent = [];
  inRack.forEach((rackSlot, index) => {
    if (rackSlot.slot_path.includes('/slot/interface/')) {
      inRackContent.push(
        <SlotCard
          instance={instance}
          key={index}
          slotType={SLOT_TYPES.INTERFACE}
          slotObj={rackSlot}
          width={inRackSlotWidth}
          multiLocation={
            rackSlot.basket_id !== 0 &&
            multiLocationBaskets.has(rackSlot.basketID)
          }
          foodMenu={instanceRedux.foodMenu}
          rosHeader={instanceRedux.rosHeader}
          user={user}
        />
      );
    }
  });

  let readyNextRackSlides = [];

  function formatFoodType(foodType) {
    const words = foodType.split('_');

    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
    const food_type = words.join(' ');
    return food_type;
  }

  readyNextRack.forEach((rackSlot, index) => {
    let words;
    if (rackSlot.food_type !== '') {
      words = rackSlot.food_type.split('_');

      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }
    }

    readyNextRackSlides.push(rackSlot);
  });
  const {name, location, logo} = getInfo(instance);
  const smartChefRunning = instanceRedux && instanceRedux.smartChefRunning;
  const isRosRunning = instanceRedux && instanceRedux.isRosRunning;
  const isMQTTConnected = instanceRedux && instanceRedux.isMQTTConnected;
  const bitState = instanceRedux && instanceRedux.bitState;
  const safetyControllerStatus =
    instanceRedux && instanceRedux.safetyControllerStatus;

  const newSafetyControllerStatus = safetyControllerStatus.filter(
    (alarm) =>
      (alarm.sensor_name === 'fryer_door' ||
        alarm.sensor_name === 'reset_needed' ||
        alarm.sensor_name === 'e_stop') &&
      alarm.value
  );
  const bitInterrupted = newSafetyControllerStatus.some(
    (sensor) => sensor.value
  );

  let notificationList = [];
  if (notificationState.length > 0) {
    notificationState.forEach((notif, key) => {
      const dispenserIssue =
        notif.code.includes('dispenser') && notif.data !== undefined;
      let {title, severity} = getMessage(
        notif.code,
        convertComponentPathToReadableText(notif.component_path, inRack.length),
        dispenserIssue && JSON.parse(notif.data).slot === 'left'
          ? hopperState &&
            hopperState.sides &&
            hopperState.sides[0] &&
            hopperState.sides[0].food_type
            ? snakeCaseToNormal(hopperState.sides[0].food_type)
            : ''
          : dispenserIssue && JSON.parse(notif.data).slot === 'right'
            ? hopperState &&
              hopperState.sides &&
              hopperState.sides[1] &&
              hopperState.sides[1].food_type
              ? snakeCaseToNormal(hopperState.sides[1].food_type)
              : ''
            : dispenserIssue &&
                JSON.parse(notif.data).slot.toLowerCase() === 'all'
              ? hopperState &&
                hopperState.sides &&
                hopperState.sides[0] &&
                hopperState.sides[1]
                ? snakeCaseToNormal(hopperState.sides[0].food_type) +
                  ' and ' +
                  snakeCaseToNormal(hopperState.sides[1].food_type)
                : ''
              : '',
        dispenserIssue && notif.data !== undefined
          ? JSON.parse(notif.data).slot
          : '',
        notif.data
      );
      if (title !== undefined) {
        notificationList.push({
          title: title,
          severity: severity,
        });
      }
    });
  }

  if (bitState) {
    newSafetyControllerStatus &&
      newSafetyControllerStatus.forEach((scanner, key) => {
        notificationList.push({
          title: scanner.value_description,
          severity: 'red',
        });
      });
  }

  scannerState &&
    scannerState.forEach((scanner, key) => {
      notificationList.push({
        title: scanner,
        severity: 'red',
      });
    });

  const robotArmAlert =
    robotArmState === ROBOT_ARM_STATE.DISABLED ||
    robotArmState === ROBOT_ARM_STATE.MANUAL;

  if (robotArmAlert) {
    let robotArmAlertString = `Robot controlled in ${
      robotArmState === ROBOT_ARM_STATE.DISABLED ? ' disabled ' : ' manual '
    } mode.`;
    notificationList.push({
      title: robotArmAlertString,
      severity: 'red',
    });
  }

  if (diagnosticsState['network_dev_monitor']) {
    diagnosticsState['network_dev_monitor'].map((key) => {
      let touchscreenHDMI =
        Object.values(key)[0] ===
        'network_dev_monitor: HDMI Connection: TouchScreenDiagnostics';
      if (touchscreenHDMI) {
        let hdmiDisconnected = Object.values(key)[1].includes('No');
        if (hdmiDisconnected) {
          notificationList.push({
            title: 'Touchscreen HDMI Disconnected',
            severity: 'red',
          });
        }
      }
      let touchscreenUSB =
        Object.values(key)[0] ===
        'network_dev_monitor: USB Connection: TouchScreenDiagnostics';
      if (touchscreenUSB) {
        let usbDisconnected = Object.values(key)[1].includes('No');
        if (usbDisconnected) {
          notificationList.push({
            title: 'Touchscreen USB Disconnected',
            severity: 'red',
          });
        }
      }
    });
  }

  const sortedNotificationList = notificationList.sort((a, b) => {
    const severityOrder = ['red', 'black']; // red first
    return (
      severityOrder.indexOf(a.severity) - severityOrder.indexOf(b.severity)
    );
  });

  return (
    <StyledRightSlide>
      <StyledTopRow>
        <StyledTitle>
          {' '}
          <StyledTimeHolder>
            {' '}
            <Typography variant="subtitle2" style={{color: 'white'}}>
              MSC{' '}
            </Typography>{' '}
            <Timer format="LTS" variant="subtitle1" style={{color: 'white'}} />{' '}
          </StyledTimeHolder>{' '}
          <StyledLocationHolder>
            {' '}
            <StyledLocationColumn style={{color: 'white'}}>
              <Typography style={{color: 'white'}}>{name}</Typography>
              <Typography style={{color: 'white'}}>{location}</Typography>{' '}
              <Typography style={{color: 'white'}}>
                SW: {versionNumber}{' '}
              </Typography>{' '}
            </StyledLocationColumn>
            {logo && <StyledLogo src={logo} alt="logo" />}{' '}
          </StyledLocationHolder>{' '}
        </StyledTitle>
        <StyledSearchArea>
          <StyledToggleDiv>
            {awsAvailable && smartChefRunning && (
              <>
                {rushModeFlag && (
                  <RushModeToggle
                    toggle={rushMode}
                    instance={instance}
                    rushMode={rushMode}
                  />
                )}
                {needHelp && (
                  <ModeToggle
                    name="Request - In Process"
                    user={user}
                    status={needHelp}
                    instance={instance}
                    icon={
                      <IconComponent
                        icon2="Barrier"
                        width="1.5vw"
                        color={colors.black}
                      />
                    }
                    openTroubleshootConfirmation={openTroubleshootConfirmation}
                  />
                )}
                {jogging && (
                  <ModeToggle
                    name="Jogging"
                    status={jogging}
                    instance={instance}
                    icon={
                      <FaStopCircle style={{width: '1.5vw', height: '1.5vw'}} />
                    }
                    openJogConfirmation={openJogConfirmation}
                    last_mode_user={last_mode_user}
                  />
                )}
              </>
            )}
          </StyledToggleDiv>
          <StyledButtonList>
            <StyledCommandButtonHolder>
              <StyledCommandButtonTop>
                {renderButton(
                  'Hub',
                  () => goToHub(),
                  'hub-tooltip',
                  <GripHorizontal />,
                  'Return to HUB Screen'
                )}
                {awsAvailable && (
                  <>
                    {smartChefRunning &&
                      renderButton(
                        'RobotSensorControl',
                        openRobotSensorControl, // Make sure this is a function or call the function correctly
                        'robot-sensor-control-tooltip',
                        <HiSignal color="black" />,
                        'Robot Sensor Control'
                      )}
                  </>
                )}
                {renderButton(
                  'RemoteViewer',
                  () => handleRemoteViewerModal(computeIPAddress),
                  'remoteviewer-tooltip',
                  <IconComponent
                    icon2="RemoteViewer"
                    width="2.1875rem"
                    height="1.875rem"
                    color="#525252"
                  />,
                  'Remote Viewer'
                )}
                {renderButton(
                  'CameraIntegration',
                  () => handleCameraModal(cameraID.left),
                  'camera-tooltip',
                  <CameraVideo />,
                  'Camera Integration'
                )}
                {awsAvailable && (
                  <>
                    {smartChefRunning && (
                      <>
                        {renderButton(
                          'Basket',
                          () => openBasketModal(),
                          'basket-tooltip',
                          <Basket />,
                          'Basket Modal'
                        )}
                      </>
                    )}
                    {(smartChefRunning || bitState) && (
                      <AlarmMenu alarms={sortedNotificationList} />
                    )}
                    {smartChefRunning && (
                      <>
                        {renderButton(
                          'Jog',
                          openJogConfirmation,
                          'jog-tooltip', // Unique tooltip id
                          <Jog jogging={jogging} />, // Pass the Jog component with `jogging` prop
                          jogging ? 'Resume Actions' : 'Jog Robot', // Tooltip text based on jogging state
                          {jogging, color: jogging ? 'secondary' : 'inherit'} // Pass additional props like `jogging` and `color`
                        )}
                        {renderButton(
                          'Diagnostics',
                          () => openDiagnosticsModal(),
                          'diagnostics-tooltip',
                          <Diagnostics />,
                          'Diagnostics Modal'
                        )}
                        {renderButton(
                          'FryerPoseCalibration',
                          () => openFryerPoseCalibrationModal(),
                          'fryer-pose-calibration-tooltip',
                          <PoseCalibration />,
                          'Fryer Pose Calibration Modal'
                        )}
                        {renderButton(
                          'OffsetTuning',
                          () => openOffsetTuningModal(),
                          'offset-tuning-tooltip',
                          <OffsetTuning />,
                          'Offset Tuning Modal'
                        )}
                      </>
                    )}
                  </>
                )}
                {isMQTTConnected && (
                  <PowerMenu
                    instanceStates={instanceRedux}
                    instance={instance}
                    component={
                      <Power
                        color={colors.greyishBrown}
                        width="2.5rem"
                        height="2.5rem"
                      />
                    }
                    relayState={relayState}
                    bitState={bitState}
                  />
                )}
              </StyledCommandButtonTop>
              {smartChefRunning && (
                <StyledNotificationContainer>
                  {modeDetails.map((detail, index) => (
                    <Typography key={index}>
                      <b>{detail.label}:</b> {' ' + detail.value}
                    </Typography>
                  ))}
                </StyledNotificationContainer>
              )}
            </StyledCommandButtonHolder>
          </StyledButtonList>
        </StyledSearchArea>
      </StyledTopRow>
      {awsAvailable && smartChefRunning ? (
        <StyledObjectsContainer>
          <StyledRackContainer>
            <div style={{display: 'contents'}}>
              <StyledFoodAreaContainer>
                <StyledAreaTitle>Autobasket</StyledAreaTitle>
                <StyledFoodRowContainer>{inRackContent}</StyledFoodRowContainer>
              </StyledFoodAreaContainer>
            </div>
            <StyledFoodAreaContainer style={{width: '46.5vw'}}>
              <StyledAreaTitle>Dispenser</StyledAreaTitle>
              {/* This will be our new Dispenser */}
              <div>
                <Dispenser hopperState={hopperState} />
              </div>
            </StyledFoodAreaContainer>
            <StyledFoodAreaContainer
              style={{
                width: '30vw',
              }}
            >
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <StyledAreaTitle>Elevator</StyledAreaTitle>
                {elevatorState &&
                  elevatorState.notifications &&
                  elevatorState.notifications.length > 0 && (
                    <div style={{marginRight: '1rem'}}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <StyledAreaTitleTypography
                          style={{color: '#FC648F', fontWeight: 'bold'}}
                        >
                          {toReadableString(
                            elevatorState.notifications[0].code
                          ) + ' Error'}
                        </StyledAreaTitleTypography>
                        <BsExclamationTriangle
                          style={{
                            marginLeft: '1rem',
                            color: '#FC648F',
                            width: '1.5rem',
                            height: '1.5rem',
                          }}
                        />
                      </div>
                    </div>
                  )}
              </div>
              {/* Elevator component */}
              <div>
                <Elevator
                  elevatorState={elevatorState}
                  openElevatorModal={openElevatorModal}
                  jogging={jogging}
                  openElevatorConfirmation={openElevatorConfirmation}
                />
              </div>
            </StyledFoodAreaContainer>
          </StyledRackContainer>
          <div style={{width: '100%'}}>
            <StyledAreaTitle>Fryer Slot</StyledAreaTitle>
            <StyledFoodRowContainer style={{minHeight: '25vh'}}>
              {slotContent}
            </StyledFoodRowContainer>
          </div>
          <div style={{width: '100%'}}>
            <StyledAreaTitle>Shelf Slot</StyledAreaTitle>
            <StyledFoodRowContainer style={{minHeight: '20vh'}}>
              {shelfContent}
            </StyledFoodRowContainer>
          </div>
          <div style={{display: 'flex'}}>
            <div style={{width: '100%'}}>
              <StyledAreaTitle>Coming Next</StyledAreaTitle>
              <div>
                <Swiper
                  ref={swiperRef} // Assign the Swiper instance to the ref
                  slidesPerView="auto"
                  spaceBetween={20}
                  grabCursor
                  modules={[Pagination]}
                  onInit={handleSwiperInit} // Trigger on initialization
                  onReachEnd={loadQueue} // Trigger when reaching the end of the Swiper
                  className="mySwiper"
                  pagination={{clickable: true}}
                >
                  {readyNextRackSlides.map((data, index) => {
                    return (
                      index < state.initialCardCount && (
                        <StyledSwiperSlide key={index}>
                          <div>
                            {data.food_type !== '' && (
                              <ComingNext
                                content={formatFoodType(data.food_type)}
                                donenessLabel={data.doneness}
                              />
                            )}
                          </div>
                        </StyledSwiperSlide>
                      )
                    );
                  })}
                </Swiper>
              </div>
            </div>
          </div>
        </StyledObjectsContainer>
      ) : bitState ? (
        <div
          style={{
            width: '99%',
            height: '85%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <p style={{textAlign: 'center', fontSize: '2.5rem'}}>
            {bitInterrupted ? 'BIT Test Interrupted' : 'Running BIT Test'}
          </p>
        </div>
      ) : !awsAvailable && !isRosRunning ? (
        <Loading status={!awsAvailable} />
      ) : (
        <>
          {(!isRosRunning || !smartChefRunning) && (
            <div
              style={{
                width: '99%',
                height: '85%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              {!smartChefRunning && isRosRunning ? (
                <p style={{textAlign: 'center', fontSize: '1.5rem'}}>
                  Smart Chef Is Not Running, But ROS Is Running
                </p>
              ) : (
                <p style={{textAlign: 'center', fontSize: '1.5rem'}}>
                  Flippy's Software is Off.
                  <br />
                  Start Up Software through Power Menu{' '}
                  <FaPowerOff color="black" />
                </p>
              )}
            </div>
          )}
        </>
      )}
      {awsAvailable && isRosRunning && (
        <>
          <JogConfirmation
            open={jogConfirmation}
            onClose={closeJogConfirmation}
            instance={instance}
            jogging={jogging}
            openJogModal={openJogModal}
            jogRobot={() => toggleJog()}
            alarms={notificationState}
          />
          <ElevatorConfirmation
            open={elevatorConfirmation}
            onClose={closeElevatorConfirmation}
            jogging={jogging}
            jogRobot={() => toggleJog()}
            openElevatorModal={openElevatorModal}
          />
          <JogModal
            open={jogModal}
            jogging={jogging}
            onClose={closeJogModal}
            instance={instance}
            gripperState={gripperState}
            getGripperSensor={getGripperSensor}
            alarms={notificationState}
            homeJoints={homeJoints}
            notificationState={notificationState}
            last_mode_user={last_mode_user}
            elevatorState={elevatorState}
          />
          <ElevatorModal
            open={elevatorModal}
            jogging={jogging}
            onClose={closeElevatorModal}
            instance={instance}
            elevatorState={elevatorState}
          />
          <ResolveCollisionModal
            open={resolveCollisionModal}
            openJogModal={openJogModal}
            jogging={jogging}
            jogRobot={() => {
              toggleJog();
              closeResolveCollisionModal();
            }}
            onClose={closeResolveCollisionModal}
            notificationState={notificationState}
            elevatorState={elevatorState}
            last_mode_user={last_mode_user}
            troubleshootingReady={troubleshootingReady}
          />
          <DiagnosticsModal
            open={diagnosticsModal}
            onClose={closeDiagnosticsModal}
            diagnosticsState={diagnosticsState}
          />
          <RobotSensorControlModal
            open={robotSensorControlModal}
            onClose={closeRobotSensorControl}
            disabledFryerSensorsArray={disabledFryerSensorsArray}
            numFryerSensors={numFryerSensors}
            getDispenserLockSensor={getDispenserLockSensor}
            getGripperSensor={getGripperSensor}
            getElevatorSensor={getElevatorSensor}
            instance={instance}
            relayState={relayState}
          />
          <OffsetTuningModal
            open={offsetTuningModal}
            onClose={closeOffsetTuningModal}
            fryerNumber={fryersState.length}
            instance={instance}
            offsetUpdated={offsetUpdated}
            offsetDataObj={offsetDataObj}
          />
          <FryerPoseCalibrationModal
            open={fryerPoseCalibrationModal}
            onClose={closeFryerPoseCalibrationModal}
            user={user}
            instance={instance}
            uiState={uiState}
          />
          <BasketModal
            open={basketModal}
            onClose={closeBasketModal}
            instance={instance}
            basketList={basketList}
            baskets={baskets}
            user={user}
            rosHeaderTimeStamp={rosHeaderTimeStamp}
          />
          <TroubleshootPopup
            instance={instance}
            open={troubleshootConfirmation}
            onClose={closeTroubleshootConfirmation}
            needHelp={needHelp}
            user={user}
          />
          <StyledVersion variant="body2">{packageJson.version}</StyledVersion>
        </>
      )}
    </StyledRightSlide>
  );
};

InstanceScreen.propTypes = {
  instance: PropTypes.string.isRequired,
  instanceRedux: PropTypes.object.isRequired,
  jogConfirmation: PropTypes.bool.isRequired,
  diagnosticsModal: PropTypes.bool.isRequired,
  jogModal: PropTypes.bool.isRequired,
  resolveCollisionModal: PropTypes.bool.isRequired,
  openDiagnosticsModal: PropTypes.func.isRequired,
  openJogConfirmation: PropTypes.func.isRequired,
  openJogModal: PropTypes.func.isRequired,
  openResolveCollisionModal: PropTypes.func.isRequired,
  closeDiagnosticsModal: PropTypes.func.isRequired,
  closeJogConfirmation: PropTypes.func.isRequired,
  closeJogModal: PropTypes.func.isRequired,
  closeResolveCollisionModal: PropTypes.func.isRequired,
};

export default InstanceScreen;

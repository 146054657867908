import React from 'react';
import PropTypes from 'prop-types';
import {FaExclamationTriangle} from 'react-icons/fa';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

const CollisionModal = ({collisions, onClose, disableCallback}) => {
  const open = collisions.length > 0;
  if (!open) {
    return null;
  }

  let listContent = [];
  for (let i = 0; i < collisions.length; i += 2) {
    listContent.push(
      <ListItem key={i}>
        <ListItemIcon>
          <FaExclamationTriangle />
        </ListItemIcon>
        <ListItemText primary={collisions[i] + ' ----- ' + collisions[i + 1]} />
      </ListItem>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="collision-dialog-title"
      aria-describedby="collision-dialog-description"
    >
      <DialogTitle id="collision-dialog-title">
        Potential collisions detected
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="collision-dialog-description">
          Performing this action would cause the collisions listed below. Would
          you like to continue by overriding the potential collisions?
        </DialogContentText>
        <List>{listContent}</List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={disableCallback} color="secondary">
          Override and continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CollisionModal.propTypes = {
  collisions: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
  disableCallback: PropTypes.func.isRequired,
};

export default CollisionModal;

import React from 'react';
import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const StyledRoot = styled(DialogTitle)(({theme}) => ({
  position: 'relative',
}));

const StyledCloseButton = styled(IconButton)(({theme}) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
  color: theme.palette.grey[500],
}));

const MisoDialogTitle = (props) => {
  const {children, onClose, disableTypography} = props;
  return (
    <StyledRoot disableTypography>
      {disableTypography ? (
        children
      ) : (
        <Typography variant="h4">{children}</Typography>
      )}
      {onClose ? (
        <StyledCloseButton
          aria-label="Close"
          data-tip="Close Modal"
          onClick={() => {
            onClose();
          }}
        >
          <CloseIcon style={{fontSize: '2.5rem'}} />
        </StyledCloseButton>
      ) : null}
    </StyledRoot>
  );
};

MisoDialogTitle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onClose: PropTypes.func,
  disableTypography: PropTypes.bool,
};

export default MisoDialogTitle;

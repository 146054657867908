import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import {rosToMoment} from '../util';

const Timer = ({
  component: ComponentProp = Typography,
  format,
  startTime,
  endTime,
  ...other
}) => {
  const [display, setDisplay] = useState(null);
  const [animationFrameId, setAnimationFrameId] = useState(null);

  const tick = () => {
    const time = moment();
    let newDisplay = '';

    if (startTime) {
      const diff = rosToMoment(endTime).diff(time); // assuming rosToMoment converts ros timestamp to moment
      if (diff <= 0) {
        cancelAnimationFrame(animationFrameId);
        setAnimationFrameId(null);
        return;
      }
      const timeRemaining = moment(diff);
      newDisplay = timeRemaining.format(format);
    } else {
      newDisplay = time.format(format); // Default case to show the current time
    }

    setDisplay(newDisplay);

    // Request the next frame if the timer is still running
    setAnimationFrameId(requestAnimationFrame(tick));
  };

  useEffect(() => {
    setAnimationFrameId(requestAnimationFrame(tick)); // Start ticking on mount
    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId); // Cleanup on unmount
      }
    };
  }, []); // Empty array ensures this effect runs once on mount

  return <ComponentProp {...other}>{display}</ComponentProp>;
};

Timer.propTypes = {
  component: PropTypes.element,
  format: PropTypes.string.isRequired,
  startTime: PropTypes.object,
  endTime: PropTypes.object,
};

export default Timer;

import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'; // Bullet icon
import Typography from '@mui/material/Typography';
import ExclamationTriangle from '../assets/helper-icons/ExclamationTriangle';

const StyledAlarmHeader = styled(Typography)(() => ({
  flex: 1,
  padding: '0 1rem',
  outline: 'none',
}));
const StyledCommandButtonAlarm = styled(IconButton)(({alarmActive}) => ({
  width: '5rem',
  height: '3rem',
  marginRight: '0.5rem',
  borderRadius: '1.5rem',
  border: `0.125rem solid ${alarmActive ? '#FC648F' : '#525252'}`,
}));

const AlarmMenu = ({alarms}) => {
  const anchorEl = useRef(null);
  const [open, setOpen] = useState(false);
  const alarmActive = alarms.length > 0;

  const openAlarms = () => {
    setOpen(true);
  };

  const closeAlarms = () => {
    setOpen(false);
  };

  const rootClick = (event) => {
    event.stopPropagation();
  };

  const buttonProps = {
    'aria-label': 'Alarms',
    onClick: openAlarms,
    'data-tip': 'See Alarms',
    'aria-haspopup': true,
    'aria-controls': 'alarm-menu',
    disabled: !alarmActive,
    alarmActive: alarmActive,
    color: 'secondary',
  };

  return (
    <div onClick={alarmActive ? rootClick : null}>
      <StyledCommandButtonAlarm ref={anchorEl} {...buttonProps}>
        <ExclamationTriangle color={alarmActive ? '#FC648F' : '#525252'} />
      </StyledCommandButtonAlarm>
      <Menu
        id="alarm-menu"
        anchorEl={anchorEl.current}
        open={open}
        onClose={closeAlarms}
        disableAutoFocusItem
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        transformOrigin={{vertical: 'top', horizontal: 'left'}}
      >
        {alarms.length > 0 && (
          <StyledAlarmHeader
            variant="subtitle1"
            align="center"
            fontWeight="bold"
          >
            Alarms:
          </StyledAlarmHeader>
        )}
        {alarms.map((alarm, index) => (
          <MenuItem key={index} dense>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={alarm.title}
              style={{
                color: alarm.severity,
              }}
            />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

AlarmMenu.propTypes = {
  alarms: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default AlarmMenu;

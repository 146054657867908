import {GOOGLE_AUTH_TOKEN} from '../constants/strings';
import storage from '../storage';
const aws_iot = require('aws-iot-device-sdk-v2');
const auth = aws_iot.auth;
const iot = aws_iot.iot;
const mqtt = aws_iot.mqtt;
const aws_sdk = require('aws-sdk');
const QOS = mqtt.QoS.AtMostOnce;
const API_VERSION = '1.0.0';

class AWSCognitoCredentialsProvider extends auth.CredentialsProvider {
  constructor(region, role_arn) {
    super();
    this.region = region;
    aws_sdk.config.region = region;
    const google_token = storage.get(GOOGLE_AUTH_TOKEN);
    this.source_provider = new aws_sdk.WebIdentityCredentials(
      {
        RoleArn: role_arn,
        WebIdentityToken: google_token, // token from identity service
        RoleSessionName: 'msc', // optional name, defaults to web-identity
      },
      {}
    );
    this.aws_credentials = {
      aws_region: region,
      aws_access_id: this.source_provider.accessKeyId,
      aws_secret_key: this.source_provider.secretAccessKey,
      aws_sts_token: this.source_provider.sessionToken,
    };
  }

  getCredentials() {
    return this.aws_credentials;
  }

  async refreshCredentialAsync() {
    return new Promise((resolve, reject) => {
      this.source_provider.get((err) => {
        if (err) {
          window.alert(`Connection to AWS failed. Refresh and try again.`);
        } else {
          this.aws_credentials.aws_access_id = this.source_provider.accessKeyId;
          this.aws_credentials.aws_secret_key =
            this.source_provider.secretAccessKey;
          this.aws_credentials.aws_sts_token =
            this.source_provider.sessionToken;
          this.aws_credentials.aws_region = this.region;
        }
        resolve();
      });
    });
  }
}

class MQTTAccessPoint {
  constructor(mqtt_connection) {
    this.connection = mqtt_connection;
    this.subscriptions = {};
  }

  // subscribe to a MQTT topic
  async _subscribe_to_mqtt(topic, resp_callback) {
    return new Promise(async (resolve, reject) => {
      console.log(`INFO: Subscribing to ${topic}`);
      if (!(topic in this.subscriptions)) {
        await this.connection
          .subscribe(topic, QOS, resp_callback)
          .then(() => {
            this.subscriptions[topic] = true;
            resolve();
          })
          .catch((error) => {
            console.log(error);
            // TODO (WL): Figure out why it's erroring out
            // reject(` > _subscribe_to_mqtt ${topic}: ` + error);
          });
      } else {
        console.log(`INFO: Already subscribed to ${topic}!`);
        resolve();
      }
    });
  }

  // unsubscribe to a MQTT topic
  async _unsubscribe_to_mqtt(topic) {
    return new Promise(async (resolve, reject) => {
      console.log(`INFO: Unsubscribing ${topic}`);
      await this.connection
        .unsubscribe(topic)
        .then(() => {
          delete this.subscriptions[topic];
          resolve();
        })
        .catch((error) => {
          console.log(error);
          reject(' > _unsubscribe_to_mqtt: ' + error);
        });
    });
  }

  // publisht payload on a MQTT topic
  async _publish_to_mqtt(topic, payload) {
    return new Promise(async (resolve, reject) => {
      console.log(`INFO: Publishing\n${JSON.stringify(payload)}\non ${topic}!`);
      await this.connection
        .publish(topic, payload, QOS)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          console.log(error);
          reject(' > _publish_to_mqtt: ' + error);
        });
    });
  }

  // subscribe to a MQTT topic
  async subscribe_mqtt(topic, resp_callback) {
    await this._subscribe_to_mqtt(topic, resp_callback);
  }

  // unsubscribe to a MQTT topic
  async unsubscribe_mqtt(topic) {
    await this._unsubscribe_to_mqtt(topic);
  }

  // publish to a MQTT topic
  async publish_to_mqtt(topic, payload) {
    await this._publish_to_mqtt(topic, payload);
  }
}
/*
 * Build a direct mqtt connection using mtls
 */
async function build_mqtt_connection(
  aws_account_obj,
  on_interrupt_callback,
  on_resume_callback,
  on_disconnect_callback,
  client_id = 'msc_user_' + Math.floor(Math.random() * 100000000)
) {
  return new Promise(async (resolve, reject) => {
    console.log(`Connecting...`);
    let role_arn = `arn:aws:iam::${aws_account_obj.accountInfo.number}:role/${aws_account_obj.accountInfo.role_name}`;
    console.log(
      `Authentication...${aws_account_obj.accountInfo.region}, ${role_arn}, ${client_id}`
    );
    const provider = new AWSCognitoCredentialsProvider(
      aws_account_obj.accountInfo.region,
      role_arn
    );
    /** Make sure the credential provider fetched before setup the connection */
    await provider.refreshCredentialAsync();
    let config =
      iot.AwsIotMqttConnectionConfigBuilder.new_builder_for_websocket()
        .with_credential_provider(provider)
        .with_clean_session(true)
        .with_client_id(client_id)
        .with_endpoint(aws_account_obj.accountInfo.endpoint)
        .with_use_websockets()
        .build();
    const client = new mqtt.MqttClient();
    const connection = client.new_connection(config);
    connection.on('connect', (session_present) => {
      console.log(`Connected to AWS IoT Core`);
      resolve(connection);
    });
    connection.on('interrupt', on_interrupt_callback);
    connection.on('resume', on_resume_callback);
    connection.on('disconnect', on_disconnect_callback);
    connection.on('error', (error) => {
      reject(error);
    });
    connection.connect();
  });
}

export {MQTTAccessPoint, build_mqtt_connection, API_VERSION, QOS};

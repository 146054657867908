import teal from '@mui/material/colors/teal';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {BrowserRouter, Route, Routes, useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';

import keymap from './keymap';

import packageJson from '../package.json';
import {
  clearAuthhUser,
  getAuthorized,
  setAuthLoading,
} from './actions/appAction';
import ErrorStatuses from './components/ErrorStatuses';
import HubCard from './components/Hubcard';
import {GOOGLE_AUTH_TOKEN} from './constants/strings';
import Notifications from './modals/Notifications';
import RealtimeAlert from './modals/RealtimeAlert';
import InstancePowerStateScreen from './screens/InstancePowerStateScreen';
import InstanceScreen from './screens/InstanceScreen';
import LoadingScreen from './screens/LoadingScreen';
import SignIn from './screens/SignIn';
import {colors, errorDrawerWidth} from './styles';
import storage from './storage';
import {getDictInfo, mergeInstanceWithStatus} from './util';
import {build_mqtt_connection} from './aws_iot/iot_engine';
import {sleep} from './aws_iot/util';
import {AWS_ACCOUNTS} from './aws_iot/accounts';
import {RTAlertMQTTAcessPoint} from './aws_iot/real_time_status';
import {LuArrowUpDown} from 'react-icons/lu';
import {FaSearch} from 'react-icons/fa';
import {Card, Checkbox, InputBase} from '@mui/material/';

let mqtt_connections = {};

const theme = createTheme({
  palette: {
    primary: teal,
    error: {500: colors.coral},
    success: {500: colors.turquoiseGreen},
    background: {
      paper: colors.whiteThree,
    },
    text: {
      primary: 'rgba(0, 0, 0, .70)',
      error: colors.coral,
      secondary: colors.warmGreyThree,
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: 'Montserrat',
  },
});

const StyledRoot = styled('div')(() => ({
  color: '#ffffff',
  fontFamily: 'Montserrat',
  fontWeight: 'normal',
  fontStyle: 'normal',
  letterSpacing: 0,
  backgroundColor: colors.whiteTwo,
  margin: '0',
  border: '0',
  width: '100vw',
  height: '100vh',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'stretch',
  flexDirection: 'row',
}));
const StyledContainer = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  height: '100%',
}));
const StyledHeader = styled('div')(() => ({
  display: 'inline-flex',
  position: 'relative',
  height: '13.4vh',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#68535c',
  color: 'white',
}));
const StyledHeaderTitle = styled('p')(() => ({
  position: 'absolute',
  fontSize: '3rem',
}));
const StyledProfile = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  position: 'absolute',
  right: '1.09375rem',
  gap: '0.3125rem',
}));
const StyledHeaderUserAuth = styled('p')(() => ({
  fontSize: '0.9375rem',
  bottom: 0,
  padding: 0,
  margin: 0,
}));
const StyledLogoutBtn = styled('button')(() => ({
  display: 'flex',
  border: 'none',
  outline: 'none',
  padding: '0.3125rem 0.625rem',
  margin: 0,
  borderRadius: '0.3125rem',
  backgroundColor: '#886d79',
  color: '#fff',
  '&:hover': {
    cursor: 'pointer',
  },
}));
const StyledFooter = styled('div')(() => ({
  display: 'inline-flex',
  background: '#68535c',
  width: '-webkit-fill-available',
  bottom: '0',
  minHeight: '1.25rem',
  fontSize: '1.2rem',
  justifyContent: 'center',
  color: 'white',
  position: 'fixed',
}));
const StyledHubRoot = styled('div')(() => ({
  display: 'flex',
  flex: 1,
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
}));
const StyledHubMain = styled('div')(() => ({
  overflow: 'scroll',
  height: '100vh',
}));
const StyledVersion = styled(Typography)(() => ({
  position: 'absolute',
  zIndex: 5000,
  color: 'white',
}));
const StyledLabTitle = styled('div')(() => ({
  color: colors.black,
  paddingLeft: '1rem',
}));
const StyledSearchDiv = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  verticalAlign: 'middle',
  borderStyle: 'solid',
  borderWidth: '0.125rem',
  height: '2.8em',
  width: '39rem',
  paddingLeft: '1rem',
  borderColor: colors.pinkishGrey,
  backgroundColor: colors.white,
}));

const StyledKey = styled('div')(() => ({
  width: errorDrawerWidth,
}));

const StyledMainContent = styled('div')(() => ({
  width: '100%',
}));

const StyledSortIcon = styled(LuArrowUpDown)(() => ({
  color: colors.black,
  height: '3rem',
  padding: '0 0.25rem',
}));
const StyledSortPopup = styled('div')(() => ({
  position: 'relative',
  zIndex: 1,
}));
const StyledSortCard = styled(Card)(() => ({
  position: 'absolute',
  width: '19rem',
  color: colors.black,
  padding: '0.5rem',
  zIndex: 2,
}));
const StyledSortList = styled('span')(() => ({
  fontSize: '0.8rem',
}));
const StyledSearchIcon = styled(FaSearch)(() => ({
  marginLeft: '0.625rem',
  marginRight: '0.625rem',
  color: colors.pinkishGrey,
}));
const StyledSearchBar = styled(InputBase)(() => ({
  width: '100%',
}));

const App = (props) => {
  const {instances, appState} = props;
  const [state, setState] = useState({
    socket: null,
    realtimeAlertVisible: false,
    realtimeAlertPosition: {x: 0, y: 0},
    instancesStatus: [],
    selectedInstance: {},
    isSorting: false,
    searchLocation: '',
    sortAlphabetically: false,
    sortSeverity: true,
  });

  // todo this module is outdated and should be replaced with alternative.
  // todo For reference: "react-shortcuts": "^2.0.1",
  // const shortcutManager = new ShortcutManager(keymap);

  const {
    realtimeAlertVisible,
    instancesStatus,
    selectedInstance,
    isSorting,
    searchLocation,
    sortAlphabetically,
    sortSeverity,
  } = state;

  useEffect(() => {
    const {dispatch} = props;

    if (storage.get(GOOGLE_AUTH_TOKEN)) {
      dispatch(getAuthorized());
    }

    if (storage.get(GOOGLE_AUTH_TOKEN)) {
      sessionStorage.clear();
      if (props.appState.authenticated) {
        initRealTimeAlerts();
      }
    }
  }, [props.appState.authenticated, storage.get(GOOGLE_AUTH_TOKEN)]);

  const initRealTimeAlerts = async () => {
    let current_appState = appState;

    for (const [account, account_obj] of Object.entries(AWS_ACCOUNTS)) {
      console.log('Building AWS IoT Core connection for', account);

      const on_interrupt_callback = (code) => {
        console.log(
          `AWS IoT Core connection interrupted (${account}) code: ${code}.`
        );
        window.alert(
          `Press OK to refresh the page. Cloud connection is interrupted unexpectedly.`
        );
        window.location.reload();
      };

      const on_resume_callback = (code, session_present) => {
        console.log(
          `AWS IoT Core connection resumed: rc: ${code} existing session: ${session_present}`
        );
      };

      const on_disconnect_callback = () => {
        console.log(`AWS IoT Core connection disconnected.`);
      };

      const client_id = [
        'MSCHub',
        account,
        current_appState.authUser.email,
        Math.floor(Math.random() * 100000000),
      ].join('_');

      try {
        const connection = await build_mqtt_connection(
          account_obj,
          on_interrupt_callback,
          on_resume_callback,
          on_disconnect_callback,
          client_id
        );
        mqtt_connections[account] = connection;
        console.log(`Successfully established ${account}`);
      } catch (error) {
        console.log(`Error while connecting ${account}: ${error}`);
      }
    }

    let lastInstanceStatus = {};
    const instanceStatusCallback = (instancesStatus) => {
      setState((prev) => ({
        ...prev,
        instancesStatus,
      }));
      lastInstanceStatus = instancesStatus;
    };

    for (const [account, account_obj] of Object.entries(AWS_ACCOUNTS)) {
      while (!mqtt_connections[account]) {
        console.log(`Waiting for ${account} mqtt connection`);
        await sleep(5000);
      }

      if (window.location.pathname === '/') {
        const ap = new RTAlertMQTTAcessPoint(mqtt_connections[account]);
        ap.subscribe_diag_min_array(instanceStatusCallback);
      }
    }
  };

  const getChildContext = () => ({
    // todo maybe we need to migrate to alternative.
    // shortcuts: shortcutManager,
  });

  const showRealtimeAlert = (instance) => (e) => {
    const x = e.clientX;
    const y = e.clientY;

    setState((prev) => ({
      ...prev,
      realtimeAlertPosition: {x, y},
      selectedInstance: instance,
      realtimeAlertVisible: true,
    }));
  };

  const closeRealtimeAlert = () => {
    setState((prev) => ({
      ...prev,
      realtimeAlertVisible: false,
    }));
  };

  const handleClickLogout = () => {
    const {dispatch} = props;

    localStorage.removeItem(GOOGLE_AUTH_TOKEN);
    dispatch(setAuthLoading(false));
    dispatch(clearAuthhUser());
    window.location.reload();
  };

  const handleSortPopup = () => {
    setState((prev) => ({
      ...prev,
      isSorting: !prev.isSorting,
    }));
  };

  const handleClosePopup = () => {
    setState((prev) => ({
      ...prev,
      isSorting: false,
    }));
  };

  const handleSearch = (searchVal) => {
    setState((prev) => ({
      ...prev,
      searchLocation: searchVal,
    }));
  };

  const handleSortAlphabetically = () => {
    setState((prev) => ({
      ...prev,
      sortAlphabetically: !prev.sortAlphabetically,
      sortSeverity: !prev.sortSeverity,
    }));
  };

  const handleSortSeverity = () => {
    setState((prev) => ({
      ...prev,
      sortSeverity: !prev.sortSeverity,
    }));
  };

  const countRedAlerts = (instance) => {
    let count = 0;
    for (let key in instance) {
      if (instance[key].status === 2 && key !== 'safety_sensor') {
        count++;
      }
    }
    return count;
  };

  const is_authenticated =
    appState.authenticated && !!storage.get(GOOGLE_AUTH_TOKEN);
  if (!is_authenticated) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<SignIn />} />
        </Routes>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <div>
        <ThemeProvider theme={theme}>
          <StyledRoot>
            <Routes>
              <Route
                path="/"
                element={
                  <StyledContainer>
                    <StyledKey>
                      {window.location.pathname === '/' && (
                        <ErrorStatuses
                          instance={null}
                          instanceStates={instances}
                        />
                      )}
                    </StyledKey>
                    <StyledMainContent>
                      <StyledHeader>
                        <StyledHeaderTitle>
                          Miso Support Center
                        </StyledHeaderTitle>
                        <StyledProfile>
                          <StyledHeaderUserAuth>
                            {appState.authUser.email}
                          </StyledHeaderUserAuth>
                          <StyledLogoutBtn onClick={handleClickLogout}>
                            logout
                          </StyledLogoutBtn>
                        </StyledProfile>
                      </StyledHeader>
                      <StyledSearchDiv
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          padding: '0.5rem',
                          border: 'none',
                        }}
                      >
                        <StyledSearchDiv>
                          <StyledSearchIcon />
                          <StyledSearchBar
                            placeholder="Search Location ID"
                            onChange={(val) => handleSearch(val.target.value)}
                          />
                        </StyledSearchDiv>
                        <StyledSortPopup>
                          <StyledSortIcon onClick={handleSortPopup} size={23} />
                          {isSorting && (
                            <StyledSortCard>
                              <p>Sort by:</p>
                              <Checkbox
                                defaultChecked={sortAlphabetically}
                                color="default"
                                onChange={() => {
                                  handleSortAlphabetically();
                                  handleClosePopup();
                                }}
                              />
                              <StyledSortList>Alphabetically</StyledSortList>
                            </StyledSortCard>
                          )}
                        </StyledSortPopup>
                      </StyledSearchDiv>
                      <StyledHubMain>
                        {/* Instances that are not lab units */}
                        <StyledHubRoot>
                          {mergeInstanceWithStatus(
                            instancesStatus,
                            searchLocation,
                            sortAlphabetically,
                            sortSeverity
                          ).map((instance, i) => {
                            const instanceSerial =
                              instance.serial.toLowerCase();
                            if (!instanceSerial.includes('test')) {
                              const numRedAlerts = countRedAlerts(
                                instance.status
                              );
                              return (
                                <HubCard
                                  key={i}
                                  siteId={instance.site_id}
                                  instance={instance.host}
                                  email={appState.authUser.email}
                                  serial={instance.serial}
                                  deviceid={instance.deviceid}
                                  info={instance.status}
                                  onClickStatus={showRealtimeAlert(instance)}
                                  numRedAlerts={numRedAlerts}
                                />
                              );
                            }
                          })}
                        </StyledHubRoot>

                        {/* Instances that are lab units */}
                        <StyledLabTitle>
                          <h4 style={{color: colors.black}}>Lab Units</h4>
                        </StyledLabTitle>
                        <StyledHubRoot>
                          {mergeInstanceWithStatus(
                            instancesStatus,
                            searchLocation,
                            sortAlphabetically,
                            sortSeverity
                          ).map((instance, i) => {
                            const instanceSerial =
                              instance.serial.toLowerCase();
                            if (instanceSerial.includes('test')) {
                              const numRedAlerts = countRedAlerts(
                                instance.status
                              );
                              return (
                                <HubCard
                                  key={i}
                                  siteId={instance.site_id}
                                  instance={instance.host}
                                  email={appState.authUser.email}
                                  serial={instance.serial}
                                  deviceid={instance.deviceid}
                                  info={instance.status}
                                  onClickStatus={showRealtimeAlert(instance)}
                                  numRedAlerts={numRedAlerts}
                                />
                              );
                            }
                          })}
                        </StyledHubRoot>
                      </StyledHubMain>
                    </StyledMainContent>
                    <StyledFooter>Miso Robotics, Inc.</StyledFooter>
                  </StyledContainer>
                }
              />
              <Route
                path="/:instance"
                element={
                  <InstanceRoute initRealTimeAlerts={initRealTimeAlerts} />
                }
              />
            </Routes>
            <RealtimeAlert
              instancesStatus={instancesStatus}
              selectedInstance={selectedInstance}
              position={{...state.realtimeAlertPosition}}
              open={realtimeAlertVisible}
              onClose={closeRealtimeAlert}
            />
            <StyledVersion
              variant="body2"
              sx={{
                left: theme.spacing(1),
                bottom: theme.spacing(1),
              }}
            >
              {packageJson.version}
            </StyledVersion>
          </StyledRoot>
        </ThemeProvider>
      </div>
    </BrowserRouter>
  );
};

// The InstanceRoute component
const InstanceRoute = (props) => {
  const {initRealTimeAlerts} = props;
  const {instance} = useParams(); // Retrieve the 'instance' parameter from the URL
  const {instances, ui, appState} = useSelector((state) => state);
  const [state, setState] = useState({
    jogConfirmation: false,
    elevatorConfirmation: false,
    troubleshootConfirmation: false,
    jogModal: false,
    elevatorModal: false,
    resolveCollisionModal: false,
    diagnosticsModal: false,
    offsetTuningModal: false,
    fryerPoseCalibrationModal: false,
    basketModal: false,
    robotSensorControlModal: false,
  });

  const {
    jogConfirmation,
    elevatorConfirmation,
    troubleshootConfirmation,
    jogModal,
    elevatorModal,
    resolveCollisionModal,
    diagnosticsModal,
    offsetTuningModal,
    fryerPoseCalibrationModal,
    basketModal,
    robotSensorControlModal,
  } = state;

  // Retrieve instance data from Redux and other relevant data
  const instanceRedux = instances[instance];
  const fleetInfo = getDictInfo();
  const instanceInfo = fleetInfo.find((obj) => obj.host === instance);

  const openJogConfirmation = () => {
    setState((prevState) => ({...prevState, jogConfirmation: true}));
  };

  const closeJogConfirmation = () => {
    setState((prevState) => ({...prevState, jogConfirmation: false}));
  };

  const openElevatorConfirmation = () => {
    setState((prevState) => ({...prevState, elevatorConfirmation: true}));
  };

  const closeElevatorConfirmation = () => {
    setState((prevState) => ({...prevState, elevatorConfirmation: false}));
  };

  const openJogModal = () => {
    setState((prevState) => ({...prevState, jogModal: true}));
  };

  const closeJogModal = () => {
    setState((prevState) => ({...prevState, jogModal: false}));
  };

  const openElevatorModal = () => {
    setState((prevState) => ({...prevState, elevatorModal: true}));
  };
  const closeElevatorModal = () => {
    setState((prevState) => ({...prevState, elevatorModal: false}));
  };
  const openResolveCollisionModal = () => {
    setState((prevState) => ({...prevState, resolveCollisionModal: true}));
  };
  const closeResolveCollisionModal = () => {
    setState((prevState) => ({...prevState, resolveCollisionModal: false}));
  };
  const openDiagnosticsModal = () => {
    setState((prev) => ({
      ...prev,
      diagnosticsModal: true,
    }));
  };
  const closeDiagnosticsModal = () => {
    setState((prev) => ({
      ...prev,
      diagnosticsModal: false,
    }));
  };
  const openOffsetTuningModal = () => {
    setState((prev) => ({
      ...prev,
      offsetTuningModal: true,
    }));
  };
  const closeOffsetTuningModal = () => {
    setState((prev) => ({
      ...prev,
      offsetTuningModal: false,
    }));
  };
  const openFryerPoseCalibrationModal = () => {
    setState((prev) => ({
      ...prev,
      fryerPoseCalibrationModal: true,
    }));
  };

  const closeFryerPoseCalibrationModal = () => {
    setState((prev) => ({
      ...prev,
      fryerPoseCalibrationModal: false,
    }));
  };

  const openTroubleshootConfirmation = () => {
    setState((prev) => ({
      ...prev,
      troubleshootConfirmation: true,
    }));
  };

  const closeTroubleshootConfirmation = () => {
    setState((prev) => ({
      ...prev,
      troubleshootConfirmation: false,
    }));
  };

  const openRobotSensorControl = () => {
    setState((prev) => ({
      ...prev,
      robotSensorControlModal: true,
    }));
  };

  const closeRobotSensorControl = () => {
    setState((prev) => ({
      ...prev,
      robotSensorControlModal: false,
    }));
  };

  const openBasketModal = () => {
    setState((prev) => ({
      ...prev,
      basketModal: true,
    }));
  };

  const closeBasketModal = () => {
    setState((prev) => ({
      ...prev,
      basketModal: false,
    }));
  };

  if (instanceRedux && !ui.loadingPowerState?.status) {
    return (
      <>
        <InstanceScreen
          instance={instance}
          instanceRedux={instanceRedux}
          jogConfirmation={jogConfirmation}
          elevatorConfirmation={elevatorConfirmation}
          troubleshootConfirmation={troubleshootConfirmation}
          diagnosticsModal={diagnosticsModal}
          jogModal={jogModal}
          elevatorModal={elevatorModal}
          resolveCollisionModal={resolveCollisionModal}
          offsetTuningModal={offsetTuningModal}
          fryerPoseCalibrationModal={fryerPoseCalibrationModal}
          basketModal={basketModal}
          robotSensorControlModal={robotSensorControlModal}
          openDiagnosticsModal={openDiagnosticsModal}
          openJogConfirmation={openJogConfirmation}
          openElevatorConfirmation={openElevatorConfirmation}
          openTroubleshootConfirmation={openTroubleshootConfirmation}
          openJogModal={openJogModal}
          openElevatorModal={openElevatorModal}
          openResolveCollisionModal={openResolveCollisionModal}
          openOffsetTuningModal={openOffsetTuningModal}
          openFryerPoseCalibrationModal={openFryerPoseCalibrationModal}
          openBasketModal={openBasketModal}
          openRobotSensorControl={openRobotSensorControl}
          closeDiagnosticsModal={closeDiagnosticsModal}
          closeJogConfirmation={closeJogConfirmation}
          closeElevatorConfirmation={closeElevatorConfirmation}
          closeTroubleshootConfirmation={closeTroubleshootConfirmation}
          closeJogModal={closeJogModal}
          closeElevatorModal={closeElevatorModal}
          closeResolveCollisionModal={closeResolveCollisionModal}
          closeOffsetTuningModal={closeOffsetTuningModal}
          closeFryerPoseCalibrationModal={closeFryerPoseCalibrationModal}
          closeBasketModal={closeBasketModal}
          closeRobotSensorControl={closeRobotSensorControl}
          user={appState.authUser.email}
          instanceInfo={instanceInfo}
          initRealTimeAlerts={initRealTimeAlerts}
        />
        <Notifications
          openJogModal={openJogModal}
          openResolveCollisionModal={openResolveCollisionModal}
          openElevatorModal={openElevatorModal}
          instance={instance}
          instances={instances}
          jogModal={jogModal}
          elevatorModal={elevatorModal}
          user={appState.authUser.email}
        />
      </>
    );
  } else if (ui.loadingPowerState?.status) {
    return (
      <InstancePowerStateScreen
        powerState={ui.loadingPowerState?.text}
        instance={instance}
      />
    );
  } else {
    return (
      <LoadingScreen
        instance={instance}
        siteId={instanceInfo.site_id}
        email={appState.authUser.email}
        deviceid={instanceInfo.deviceid}
      />
    );
  }
};

App.propTypes = {
  instances: PropTypes.object.isRequired,
};

const mapStateToProps = ({appState, instances, ui}) => ({
  appState,
  instances,
  ui: ui,
});
const mapDispatch = (dispatch) => ({
  dispatch: (action) => dispatch(action),
});

export default connect(mapStateToProps, mapDispatch)(App);

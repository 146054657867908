import {createTheme, styled, ThemeProvider} from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import 'react-dropdown/style.css';

import Typography from '@mui/material/Typography';

import Switch from '@mui/material/Switch';
import OccupiedSlot from '../components/OccupiedSlot';
import {DISABLING_LEVEL, SLOT_TYPES} from '../enums';
import SlotStatusReason from '../modals/SlotStatusReason';
import {disableSlot, mqtt_ap, updateTicket} from '../ros_init';
import {convertTimestamp} from '../util';
import {borderButtonPadding, colors, mediumTextSize} from '../styles';
import {Dialog} from '@mui/material/';

const darkTheme = (theme) =>
  createTheme({
    ...theme,
    palette: {
      type: 'dark',
    },
  });

const StyledTopButtons = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  zIndex: 3,
  position: 'relative',
});

const StyledSlotOuter = styled('div')(
  ({theme, occupied, disabled, slotType}) => ({
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    minHeight: '10vh',
    marginLeft: '1%',
    borderRadius: '3pt',
    borderColor: colors.pinkishGrey,
    borderWidth: '0.125rem',
    borderStyle: 'solid',
    ...(occupied
      ? {
          overflow: 'hidden',
          backgroundColor: theme.palette.grey[50],
        }
      : {backgroundColor: theme.palette.grey[300]}),
    ...(disabled ? {backgroundColor: theme.palette.grey[800]} : {}),
    ...(slotType === SLOT_TYPES.INTERFACE ? {minHeight: '9.375rem'} : {}),
  })
);

const StyledBasketIdContainer = styled('div')({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

const StyledActualBasket = styled('div')({
  float: 'right',
  fontWeight: 900,
  visibility: 'visible',
  padding: '0 0.5rem',
});

const StyledFeedback = styled('div')({
  float: 'left',
  fontWeight: 900,
  visibility: 'visible',
});

const StyledTopLeftButton = styled('div')({
  alignItems: 'flex-start',
  paddingLeft: borderButtonPadding,
  paddingTop: borderButtonPadding,
});

const StyledTopRight = styled('div')(({theme}) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  color: theme.palette.common.white,
}));

const StyledDisabledSlotDiv = styled('div')({
  backgroundColor: 'grey',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  paddingTop: '1rem',
  textAlign: 'center',
  zIndex: 999,
  position: 'absolute',
  width: '210.25%',
  height: '201.7%',
  opacity: 0.95,
});

const StyledDisabledSlotText = styled(Typography)(({theme}) => ({
  display: 'flex',
  justifyContent: 'center',
  fontSize: '2.5rem',
  color: theme.palette.common.white,
  textAlign: 'center',
}));

const BasketIdTypography = styled(Typography)({
  paddingLeft: '0.2rem',
  paddingTop: '0.3rem',
  fontSize: '0.8125rem',
  fontWeight: 600,
  textAlign: 'center',
});

const EmptySlot = styled(({className}) => {
  return (
    <div className={className}>
      <Typography
        variant="h6"
        color="textPrimary"
        style={{textAlign: 'center'}}
      >
        Empty
      </Typography>
    </div>
  );
})(() => ({
  emptySlot: {
    textSize: mediumTextSize,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
}));

// Reasons for disabling / enabling slots
const autobasketDisableReasons = [
  'Hardware Damage',
  'Collision',
  'Instructed by T2 / Dev Team to Disable',
  'Cleaning',
  'Misgrab',
  'Vision Failure',
  'Planning Failure',
  'Other',
];

const autobasketEnableReasons = [
  'Requested by customer after cleaning',
  'Broken Autobasket slot fixed',
  'Instructed to Tier 2 / T3 to enable',
  'Other',
];

// Reasons for disabling / enabling slots
const shelfDisableReasons = [
  'Shelf Missing / Not There',
  'Localization Hardware Damage',
  'Misgrabs: Flippy is not picking up the Basket',
  'Vision Failure',
  'Localization Hardware Out of Place',
  'Collision',
  'Instructed by T2 / T3 to Disable',
  'Slot Tracking Shelf / Fryer Mismatch',
  'Tuning Slot',
  'Clean Marker',
  'Other',
];

const shelfEnableReasons = [
  'Localization Resolved',
  'Marker visibility restored',
  'Slot tuned',
  'Localization Hardware adjusted',
  'Instructed by Tier 2 / T3 to enable',
  'Shelf cleaned',
  'Other',
];

// Reasons for disabling / enabling slots
const fryerDisableReasons = [
  'Localization Hardware Damage',
  'Misgrabs: Flippy is not picking up the Basket',
  'Vision Failure',
  'Localization Hardware Out of Place',
  'Collision',
  'Instructed by T2 / T3 to Disable',
  'Slot Matching Failure',
  'Tuning Slot',
  'Clean Marker',
  'Other',
];

const fryerEnableReasons = [
  'Localization Resolved',
  'Marker visibility restored',
  'Slot tuned',
  'Localization Hardware adjusted',
  'Instructed by Tier 2 / T3 to enable',
  'Fryer cleaned',
  'Other',
];

const SlotCard = ({
  slotType,
  slotObj,
  width,
  multiLocation,
  foodMenu,
  rosHeaderTimeStamp,
  instance,
  index,
  fryersState,
  user,
}) => {
  const [basketHistoryOpen, setBasketHistoryOpen] = useState(false);
  const [isSlotReasonModalOpen, setIsSlotReasonModalOpen] = useState(false);
  const [selectedReason, setSelectedReason] = useState('');

  const onSelectFood = async (event, instance, slotUuid) => {
    const foodName = event.target.value;
    updateTicket[instance].setData = {
      ticket_id: {uuid: slotUuid},
      update_food_type: true,
      food_type: foodName,
    };
    await mqtt_ap.submit_request(updateTicket[instance]).catch((error) => {
      console.log('Submit_Request error: ' + error);
    });
  };

  const handleToggle = () => {
    setBasketHistoryOpen((prev) => !prev);
  };

  const handleClose = (event) => {
    if (this.anchorEl && this.anchorEl.contains(event.target)) {
      return;
    }
    setBasketHistoryOpen(false);
  };

  const handleToggleReasonSelection = (isOpen) => {
    setIsSlotReasonModalOpen((prev) => (isOpen !== true ? !prev : isOpen));
  };

  const getCurrentFoodOption = () => {
    const {ticket} = slotObj;
    return {
      value: ticket[0].food_type,
    };
  };

  const modifySlotHelper = async (
    service,
    verb,
    is_disabled,
    slotPath,
    reason
  ) => {
    service[instance].setData = {
      user: {permissions: DISABLING_LEVEL.SUPPORT, name: user},
      component_path: slotPath,
      disabled: is_disabled,
      reason: reason,
    };
    await mqtt_ap.submit_request(service[instance]).catch((error) => {
      console.log('Submit_Request error: ' + error);
    });
  };

  const getEnableSlotFunc = (reason) => {
    const slotPath = slotObj.slot_path;
    return modifySlotHelper(disableSlot, 'Enabled', false, slotPath, reason);
  };

  const getDisableSlotFunc = (reason) => {
    const slotPath = slotObj.slot_path;
    return modifySlotHelper(disableSlot, 'Disabled', true, slotPath, reason);
  };

  const handleSlotToggle = (reason) => {
    const {disabled} = slotObj;
    if (disabled) {
      getEnableSlotFunc(reason);
    } else {
      getDisableSlotFunc(reason);
    }
  };

  const onClearSlot = async (instance, slotUuid) => {
    updateTicket[instance].setData = {
      ticket_id: {uuid: slotUuid},
      cancel_ticket: true,
    };
    await mqtt_ap
      .submit_request(updateTicket[instance], undefined, true)
      .catch((error) => {
        console.log('Submit_Request error: ' + error);
      });
  };

  const {basket_id, disabled, ticket} = slotObj;

  const outerStyle = {
    width,
    minWidth: width,
    maxWidth: width,
    borderColor: multiLocation ? colors.azure : undefined,
  };

  let content = null;
  if (ticket.length !== 0) {
    content = (
      <OccupiedSlot
        basketID={basket_id}
        onFreeSlotClick={() => onClearSlot(instance, ticket[0].uuid.uuid)}
        onSelectFood={(event) =>
          onSelectFood(event, instance, ticket[0].uuid.uuid)
        }
        currentFoodOption={getCurrentFoodOption()}
        slotType={slotType}
        foodMenu={foodMenu}
        rosHeaderTimeStamp={rosHeaderTimeStamp}
        ticket={ticket[0]}
        instance={instance}
      />
    );
  } else {
    content = <EmptySlot />;
  }

  if (disabled) {
    content = <ThemeProvider theme={darkTheme}>{content}</ThemeProvider>;
  }

  let fryerState = {};
  if (index % 2 === 0 && fryersState) {
    fryerState = fryersState[Math.ceil(Number((index + 1) / 2)) - 1];
  }

  return (
    <StyledSlotOuter
      style={outerStyle}
      occupied={ticket.length !== 0}
      disabled={disabled}
      slotType={slotType}
    >
      {index % 2 === 0 &&
        slotType === SLOT_TYPES.FRYER &&
        fryerState &&
        fryerState.disabled &&
        fryerState.disable_reason === 'cleaning' && (
          <StyledDisabledSlotDiv>
            <StyledDisabledSlotText>
              Disabled for cleaning
            </StyledDisabledSlotText>
          </StyledDisabledSlotDiv>
        )}
      <StyledTopButtons>
        <StyledTopRight>
          {disabled && (
            <Typography
              variant="caption"
              color="inherit"
              style={{
                paddingLeft: '0.5rem',
                fontSize: '0.75rem',
                fontWeight: 900,
              }}
            >
              Disabled
              <span className="bold">
                {slotObj.disable_permissions === DISABLING_LEVEL.SUPPORT
                  ? ' By Support'
                  : slotObj.disable_permissions === DISABLING_LEVEL.UI_USER
                    ? ' By Customer'
                    : slotObj.disable_permissions === DISABLING_LEVEL.FLIPPY
                      ? ' By Flippy'
                      : ''}
              </span>
            </Typography>
          )}
          <Switch
            checked={!slotObj.disabled}
            color="primary"
            data-tip="Enable/Disable Slot"
            onClick={
              slotObj.disabled !== undefined
                ? handleToggleReasonSelection
                : handleSlotToggle
            }
          />
        </StyledTopRight>
      </StyledTopButtons>
      {slotObj.length !== 0 && (
        <StyledBasketIdContainer>
          {slotObj.disable_reason && (
            <StyledFeedback>
              <BasketIdTypography
                style={{color: slotObj.disabled ? 'white' : undefined}}
              >
                Feedback Timestamp:{' '}
                {' ' + convertTimestamp(slotObj.disable_time)}
              </BasketIdTypography>
              <BasketIdTypography style={{color: slotObj.disabled && 'white'}}>
                Feedback:{' ' + slotObj.disable_reason}
              </BasketIdTypography>
              <BasketIdTypography style={{color: slotObj.disabled && 'white'}}>
                User:{' ' + slotObj.disable_user}
              </BasketIdTypography>
            </StyledFeedback>
          )}
        </StyledBasketIdContainer>
      )}
      {basket_id !== 0 && (
        <StyledActualBasket>
          <BasketIdTypography style={{color: slotObj.disabled && 'white'}}>
            Actual Basket Id: {basket_id}
          </BasketIdTypography>
        </StyledActualBasket>
      )}
      {content}
      <Dialog
        open={isSlotReasonModalOpen}
        onClose={() => handleToggleReasonSelection(false)}
        maxWidth="lg"
        PaperProps={{
          style: {
            width: '100%',
          },
        }}
      >
        <SlotStatusReason
          key={`statusReasonModal-${basket_id}`}
          slotObj={slotObj}
          handleModalVisibility={handleToggleReasonSelection}
          handleSlotToggle={handleSlotToggle}
          enableReasons={
            slotType === SLOT_TYPES.INTERFACE
              ? autobasketEnableReasons
              : slotType === SLOT_TYPES.SHELF
                ? shelfEnableReasons
                : fryerEnableReasons
          }
          disableReasons={
            slotType === SLOT_TYPES.INTERFACE
              ? autobasketDisableReasons
              : slotType === SLOT_TYPES.SHELF
                ? shelfDisableReasons
                : fryerDisableReasons
          }
          inSingleStepModal
          type={'Slot'}
        />
      </Dialog>
    </StyledSlotOuter>
  );
};

SlotCard.propTypes = {
  instance: PropTypes.string.isRequired,
  slotType: PropTypes.string.isRequired,
  slotObj: PropTypes.shape({
    basket_id: PropTypes.number.isRequired,
    disabled: PropTypes.bool,
    foodType: PropTypes.string.isRequired,
    slot_path: PropTypes.string.isRequired,
    ticket: PropTypes.array.isRequired,
  }).isRequired,
  multiLocation: PropTypes.bool,
  width: PropTypes.string,
  foodMenu: PropTypes.arrayOf(PropTypes.object),
};

export default SlotCard;

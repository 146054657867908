import {Card, Typography} from '@mui/material/';
import {styled} from '@mui/material/styles';
import React from 'react';
import {DISPENSER_STATUS, EXISTING_FOOD} from '../enums';
import {IconComponent, toPascalCase} from '../util';

const TextTypography = styled(Typography)({
  paddingTop: '0.3rem',
  fontSize: '1.5rem',
  fontWeight: 600,
  textAlign: 'center',
});

const StyledMainBody = styled('div')({
  position: 'relative',
  maxHeight: '9.375rem',
  maxWidth: '95%',
  margin: '0 auto',
});

const StyledHopperPortionCard = styled(Card)({
  height: '20%',
  position: 'relative',
});

const StyledHopperBtns = styled('div')({
  display: 'inline-block',
  marginRight: '2rem',
  textAlign: 'center',
});

const StyledHopperBtnsHasIssue = styled('div')({
  position: 'absolute',
  top: '0.9375rem',
});

const StyledIconContainer = styled('div')({
  position: 'relative',
  display: 'inline-block',
});

const StyledCornerIcon = styled('div')({
  position: 'absolute',
  left: '64%',
  bottom: '-44%',
  height: 'auto',
  zIndex: 1,
});

const Dispenser = ({hopperState}) => {
  const isOffline = false;
  const isLowTemp = false;

  const dispenserState = (state) => {
    if (state === DISPENSER_STATUS.ERROR) return 'Broken';
    if (state === DISPENSER_STATUS.EMPTY) return 'Empty';
    return '';
  };

  const isNotFull =
    hopperState &&
    hopperState.sides &&
    hopperState.sides[0].status !== DISPENSER_STATUS.FULL &&
    hopperState.sides[1].status !== DISPENSER_STATUS.FULL;

  return (
    <StyledMainBody>
      <StyledHopperPortionCard
        style={{
          border: isNotFull ? '0.25rem solid red' : '',
        }}
      >
        {hopperState && Object.entries(hopperState).length !== 0 && (
          <div
            style={{
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {['Left', 'Right'].map((side, index) => (
              <StyledHopperBtns
                key={side}
                style={{opacity: isOffline || isLowTemp ? '0.5' : 1}}
              >
                <TextTypography>Hopper {side}</TextTypography>
                <StyledIconContainer>
                  <IconComponent
                    alt={`${side.toLowerCase()}-food`}
                    icon2={EXISTING_FOOD[hopperState.sides[index].food_type]}
                    width="3vw"
                    color="#000000"
                  />
                  {hopperState.sides[index].status !==
                    DISPENSER_STATUS.FULL && (
                    <StyledCornerIcon>
                      <IconComponent
                        alt="corner-icon"
                        icon2={dispenserState(hopperState.sides[index].status)}
                        width="2.5vw"
                        color="#000000"
                      />
                    </StyledCornerIcon>
                  )}
                </StyledIconContainer>
                <TextTypography>
                  {toPascalCase(hopperState.sides[index].food_type)}
                </TextTypography>
              </StyledHopperBtns>
            ))}
            {(isOffline || isLowTemp) && (
              <StyledHopperBtnsHasIssue>
                <IconComponent
                  icon2={isOffline ? 'Broken' : 'TemperatureWarning'}
                  width={isOffline ? '8vw' : '5vw'}
                  color="#ef9c8e"
                />
              </StyledHopperBtnsHasIssue>
            )}
          </div>
        )}
      </StyledHopperPortionCard>
    </StyledMainBody>
  );
};

export default Dispenser;

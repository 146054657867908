import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';

import Easing from 'animated/lib/Easing';
import Animated from 'animated/lib/targets/react-dom';

import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import {FaSignOutAlt} from 'react-icons/fa';

import {SLOT_TYPES} from '../enums';
import {borderButtonPadding} from '../styles';

import 'react-dropdown/style.css';
import {rosToMoment} from '../util';
import InProgressTimer from './InProgressTimer';

const StyledSlotInner = styled('div')({
  paddingTop: '0.3125rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  zIndex: 1,
});

const StyledSlotInnerInfo = styled('div')({
  alignItems: 'flex-start',
  paddingLeft: '1rem',
  paddingRight: '1rem',
  zIndex: 3,
});

const StyledProgressBar = styled(Animated.div)({
  zIndex: 2,
  bottom: 0,
  position: 'absolute',
  width: '100%',
  backgroundColor: 'rgba(158, 161, 164, .2)',
  maxHeight: '100%',
});

const StyledRootSelect = styled('div')({
  display: 'flex',
  width: '100%',
});

const StyledFoodSelect = styled(Select)({
  width: '100%',
});

const StyledBottomCard = styled('div')({
  alignItems: 'flex-end',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  width: '100%',
  zIndex: 3,
});

const StyledBottomCenter = styled('div')(({theme}) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  paddingBottom: borderButtonPadding,
}));

const StyledCrossedEye = styled('div')(({theme}) => ({
  color: theme.palette.error.main,
}));

const StyledBottomLeft = styled(IconButton)({
  position: 'absolute',
  left: 0,
  bottom: 0,
  transform: 'rotate(270deg)',
});

const StyledBottomRight = styled('div')(({theme}) => ({
  position: 'absolute',
  right: 0,
  bottom: 0,
  color: theme.palette.error.main,
}));

const TextTypography = styled(Typography)(({theme}) => ({
  paddingTop: '0.625rem',
  fontSize: '1.25rem',
  fontWeight: 600,
  textAlign: 'center',
}));

const OccupiedSlot = ({
  onSelectFood,
  currentFoodOption,
  onFreeSlotClick,
  slotType,
  rosHeaderTimeStamp,
  ticket,
  foodMenu,
  donenessLabel,
}) => {
  const [progressHeight] = useState(new Animated.Value(0));

  useEffect(() => {
    if (ticket.submerge_time && ticket.unsubmerge_time) {
      animateProgress(
        ticket.submerge_time,
        ticket.unsubmerge_time,
        rosHeaderTimeStamp
      );
    }
  }, [ticket, rosHeaderTimeStamp]);

  const animateProgress = (startTimeRos, endTimeRos, rosHeaderTimeStamp) => {
    const start_time = rosToMoment(startTimeRos);
    const end_time = rosToMoment(endTimeRos);
    const current_time = rosToMoment(rosHeaderTimeStamp);

    let time_diff = end_time.diff(current_time);
    let time_length = end_time.diff(start_time);

    time_diff = time_diff / 1000;
    time_length = time_length / 1000;

    const height = Math.floor(100 - (time_diff / time_length) * 100);

    Animated.timing(progressHeight, {
      toValue: height,
      easing: Easing.linear,
      duration: 200, // You may want to specify a duration for the animation
    }).start();
  };

  const foodList = Object.fromEntries(
    Object.entries(foodMenu).map(([key, value]) => [key, key.replace('_', ' ')])
  );
  const newFoodMenu = foodList ? foodList : [currentFoodOption];

  return (
    <StyledSlotInner>
      <StyledProgressBar
        style={{
          height: progressHeight.interpolate({
            inputRange: [0, 100],
            outputRange: ['0%', '100%'],
          }),
        }}
      />
      {Object.entries(ticket).length > 0 && slotType === SLOT_TYPES.FRYER && (
        <StyledSlotInnerInfo>
          <InProgressTimer
            endTime={ticket.unsubmerge_time}
            rosHeaderTimeStamp={rosHeaderTimeStamp}
          />
        </StyledSlotInnerInfo>
      )}
      <StyledSlotInnerInfo style={{height: '5vh'}}>
        <FormControl variant="outlined" fullWidth={true}>
          <StyledFoodSelect
            value={currentFoodOption.value}
            onChange={onSelectFood}
            input={
              <OutlinedInput
                fullWidth={true}
                labelWidth={0}
                name="foodInput"
                id="selected-food"
              />
            }
          >
            {Object.entries(newFoodMenu).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            ))}
          </StyledFoodSelect>
        </FormControl>
        <TextTypography style={{fontSize: '0.9375rem'}}>
          {donenessLabel}
        </TextTypography>
      </StyledSlotInnerInfo>
      <StyledBottomCard>
        <StyledBottomLeft data-tip="Free Slot" onClick={onFreeSlotClick}>
          <FaSignOutAlt />
        </StyledBottomLeft>
      </StyledBottomCard>
    </StyledSlotInner>
  );
};

OccupiedSlot.propTypes = {
  onSelectFood: PropTypes.func.isRequired,
  currentFoodOption: PropTypes.object.isRequired,
  onFreeSlotClick: PropTypes.func.isRequired,
  slotType: PropTypes.number,
  ticket: PropTypes.object,
  foodMenu: PropTypes.arrayOf(PropTypes.object),
};

export default OccupiedSlot;

import axios from 'axios';
import {GOOGLE_CLIENT_ID} from '../../constants/env';
import {GOOGLE_AUTH_TOKEN} from '../../constants/strings';
import storage from '../../storage';
import googleService from './services';

const googleServer = axios.create({
  baseURL: 'https://oauth2.googleapis.com',
});

// Get Google API instance with a Promise-based approach
const getGoogleInstance = () => {
  return new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      if (window.google && window.google.accounts) {
        clearInterval(interval);
        resolve(window.google.accounts);
      }
    }, 100);

    setTimeout(() => {
      clearInterval(interval);
      reject(new Error('Google accounts API not found'));
    }, 5000); // Timeout after 5 seconds if not found
  });
};

const googleSignIn = async (googleCallback) => {
  try {
    const accounts = await getGoogleInstance();
    const handleGoogleCallback = (res) => {
      const idToken = res.credential;
      storage.set(GOOGLE_AUTH_TOKEN, idToken);
      googleCallback();
    };

    accounts.id.initialize({
      client_id: GOOGLE_CLIENT_ID,
      callback: handleGoogleCallback,
    });

    accounts.id.renderButton(document.getElementById('google-btn'), {
      theme: 'outline',
      size: 'large',
    });
  } catch (error) {
    console.error('Error initializing Google Sign-In:', error);
  }
};

export {googleService, googleSignIn};
export default googleServer;

import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Spinner from 'react-spinkit';
import {getInfo} from '../util';
import {init_ros} from '../ros_init';

const StyledRoot = styled('div')(() => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}));

const LoadingScreen = ({instance, email, siteId, deviceid}) => {
  let thing_name = siteId + '-' + deviceid;

  if (deviceid === '') {
    thing_name = siteId;
  }

  useEffect(() => {
    if (instance) {
      void init_ros(thing_name, instance, email);
    }
  }, [instance]);

  return (
    <StyledRoot>
      <Typography variant="h1" color="black" gutterBottom>
        Connecting to {getInfo(instance).name}...
      </Typography>
      <Spinner
        name="circle"
        style={{
          width: '20em',
          height: '20em',
        }}
      />
    </StyledRoot>
  );
};

LoadingScreen.propTypes = {
  instance: PropTypes.string.isRequired,
};

export default LoadingScreen;

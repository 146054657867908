import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import {rosToMoment} from '../util';

const TimerTypography = styled(Typography)({
  fontSize: '2rem',
  fontWeight: 900,
  textAlign: 'center',
});

const OverCookingTextTypography = styled(Typography)({
  color: '#C41E3A',
  fontSize: '2rem',
  fontWeight: 900,
  textAlign: 'center',
});

const InProgressTimer = ({endTime, rosHeaderTimeStamp}) => {
  const end_time = rosToMoment(endTime);
  const current_time = rosToMoment(rosHeaderTimeStamp);

  const currentCalculatedTime = Math.abs(current_time.diff(end_time));

  const convertCalculatedTime = new Date(
    Date.UTC(0, 0, 0, 0, 0, 0, currentCalculatedTime)
  );

  const sec = convertCalculatedTime.getUTCSeconds();
  const min = convertCalculatedTime.getUTCMinutes();
  const hrs = convertCalculatedTime.getUTCHours();
  const days = convertCalculatedTime.getUTCDay();

  const Digit = Intl.NumberFormat('en-IN', {
    minimumIntegerDigits: 2,
  });

  return (
    <>
      {days >= 0 || hrs >= 0 || min >= 0 || sec >= 0 ? (
        <TimerTypography>
          {days > 0 && `${Digit.format(days)}:`}
          {hrs > 0 && `${Digit.format(hrs)}:`}
          {Digit.format(min)}:{Digit.format(sec)}
        </TimerTypography>
      ) : (
        <OverCookingTextTypography>
          {days < 0 && `${Digit.format(days)}:`}
          {hrs < 0 && `${Digit.format(hrs)}:`}
          {Digit.format(min)}:{Digit.format(sec)}
        </OverCookingTextTypography>
      )}
    </>
  );
};

InProgressTimer.propTypes = {
  endTime: PropTypes.object,
  rosHeaderTimeStamp: PropTypes.object,
};

export default InProgressTimer;

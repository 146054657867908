import {Switch} from '@mui/material/';
import {styled} from '@mui/material/styles';
import React, {useCallback} from 'react';
import {connect} from 'react-redux';
import {
  disableFryerSensor,
  getDisabledFryerSensors,
  getDispenserLockSensorParam,
  getElevatorSensorParam,
  getGripperSensorParam,
  mqtt_ap,
  setRelayController,
  USER_ID,
} from '../../ros_init';
import {callService, transformToSnakeCase} from '../../util';
import {MQTTRequest, MQTTRequestType} from '../../aws_iot/thing_mqtt_request';
import store from '../../store/index';
import {
  setDisabledFryerSensorsArray,
  setGetDispenserLockSensor,
  setGetElevatorSensor,
  setGetGripperSensor,
} from '../../actions/index';

const StyledContainer = styled('div')({
  width: '18rem',
  margin: '0.5rem',
});

const StyledSwitchDiv = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const StyledTextOn = styled('span')({
  color: '#009688',
});

const StyledTextTitle = styled('p')({
  fontWeight: 'bold',
});

const SensorToggle = ({title, toggle, instance}) => {
  const handleSwitch = useCallback(
    async (checked, title) => {
      const lowerCaseTitle = title.toLowerCase();

      if (lowerCaseTitle.includes('fryer lock')) {
        await toggleFryerLockSensor(title, checked);
      } else if (lowerCaseTitle.includes('relay')) {
        await toggleFryerRelay(checked);
      } else if (lowerCaseTitle.includes('gripper')) {
        await toggleGripperSensor(checked);
      } else if (lowerCaseTitle.includes('dispenser')) {
        await toggleDispenserLockSensor(checked);
      } else if (lowerCaseTitle.includes('elevator')) {
        await toggleElevatorSensor(checked);
      }
    },
    [instance]
  );

  const toggleFryerLockSensor = async (title, toggle) => {
    let request = {name: transformToSnakeCase(title), data: toggle};
    try {
      await callService(
        disableFryerSensor,
        instance,
        request,
        'Toggle Fryer Sensor'
      );

      const disabledFryerSensorsCallback = (disabledFryerSensorsArray) => {
        store.dispatch(
          setDisabledFryerSensorsArray(instance, disabledFryerSensorsArray)
        );
      };

      await mqtt_ap.submit_request(
        getDisabledFryerSensors,
        disabledFryerSensorsCallback,
        true
      );
    } catch (error) {
      console.log('Submit_Request error: ' + error);
    }
  };

  const toggleFryerRelay = async (toggle) => {
    let request = {name: 'fryer_locks', command: toggle ? 'on' : 'off'};
    try {
      await callService(
        setRelayController,
        instance,
        request,
        'Toggle Fryer Relay'
      );
    } catch (error) {
      console.log('Submit_Request error: ' + error);
    }
  };

  const toggleGripperSensor = async (toggle) => {
    const setGripperSensorParam = new MQTTRequest({
      request_type: MQTTRequestType.ROS_PARAM_SET,
      ros_topic: '/install/behaviors/enable_gripper_sensor',
      type: '',
      data: {value: toggle},
      user: USER_ID,
    });

    try {
      await mqtt_ap.submit_request(setGripperSensorParam, undefined, true);

      const getGripperSensorParamCallback = (gripperSensor) => {
        store.dispatch(setGetGripperSensor(instance, gripperSensor.value));
      };

      await mqtt_ap.submit_request(
        getGripperSensorParam,
        getGripperSensorParamCallback,
        true
      );
    } catch (error) {
      console.log('Submit_Request error: ' + error);
    }
  };

  const toggleDispenserLockSensor = async (toggle) => {
    const setDispenserLockSensorParam = new MQTTRequest({
      request_type: MQTTRequestType.ROS_PARAM_SET,
      ros_topic: '/smart_chef/enable_dispenser_lock',
      type: '',
      data: {value: toggle},
      user: USER_ID,
    });

    try {
      await mqtt_ap.submit_request(
        setDispenserLockSensorParam,
        undefined,
        true
      );

      const getDispenserLockSensorParamCallback = (dispenserLockSensor) => {
        store.dispatch(
          setGetDispenserLockSensor(instance, dispenserLockSensor.value)
        );
      };

      await mqtt_ap.submit_request(
        getDispenserLockSensorParam,
        getDispenserLockSensorParamCallback,
        true
      );
    } catch (error) {
      console.log('Submit_Request error: ' + error);
    }
  };

  const toggleElevatorSensor = async (toggle) => {
    const setElevatorSensorParam = new MQTTRequest({
      request_type: MQTTRequestType.ROS_PARAM_SET,
      ros_topic: '/elevator/enable_sensors',
      type: '',
      data: {value: toggle},
      user: USER_ID,
    });

    try {
      await mqtt_ap.submit_request(setElevatorSensorParam, undefined, true);

      const getElevatorSensorParamCallback = (elevatorSensor) => {
        store.dispatch(setGetElevatorSensor(instance, elevatorSensor.value));
      };

      await mqtt_ap.submit_request(
        getElevatorSensorParam,
        getElevatorSensorParamCallback,
        true
      );
    } catch (error) {
      console.log('Submit_Request error: ' + error);
    }
  };

  let isChecked;

  if (title.toLowerCase().includes('fryer lock')) {
    if (toggle && toggle.value && Object.values(toggle).length > 0) {
      isChecked = !Object.values(toggle.value).includes(
        transformToSnakeCase(title)
      );
    } else {
      isChecked = true;
    }
  } else {
    isChecked = Boolean(toggle);
  }

  return (
    <StyledContainer>
      <StyledTextTitle>{title}</StyledTextTitle>
      <StyledSwitchDiv>
        <span>Off</span>
        <Switch
          checked={isChecked}
          onClick={(e) => handleSwitch(e.target.checked, title)}
          color="primary"
        />
        <StyledTextOn>On</StyledTextOn>
      </StyledSwitchDiv>
    </StyledContainer>
  );
};

const mapStateToProps = (state, ownProps) => {
  const instanceToggle =
    state.instances[ownProps.instance][ownProps.toggleName];
  let toggleValue = false;

  if (Array.isArray(instanceToggle)) {
    const fryerLock = instanceToggle.find(
      (item) => item.name === 'fryer_locks'
    );
    toggleValue = fryerLock ? fryerLock.is_closed : false;
  } else {
    toggleValue = instanceToggle || false;
  }

  return {
    toggle: toggleValue,
  };
};

export default connect(mapStateToProps)(SensorToggle);

import {IconButton} from '@mui/material/';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {toggleLoadingPowerState} from '../actions/uiActions';
import {POWER_STATE} from '../enums';
import {
  clear_ros,
  flippy_power_ap,
  reset_ros,
  setRelayController,
} from '../ros_init';
import PowerSettingsModal from '../modals/PowerSettingsModal';
import store from '../store';
import {callService} from '../util';
import {RESET_ALL} from '../actions/actionTypes';
import {FaAngleLeft} from 'react-icons/fa';

let power_button_in_use = false;

const StyledRoot = styled('div')({
  display: 'flex',
});

const StyledToggleMenuButton = styled(IconButton)({
  width: '5rem',
  height: '3rem',
  borderRadius: '1.5rem',
  border: '0.125rem solid #808080',
});

const StyledContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '34.375rem',
  padding: '1rem 1rem 0.5rem',
  alignItems: 'center',
});

const StyledFSConfirmationModalTitle = styled('div')({
  fontWeight: 900,
  marginBottom: '0.5rem',
  textTransform: 'capitalize',
});

const StyledFSConfirmationModalSubTitle = styled('div')({
  fontWeight: 900,
  textTransform: 'uppercase',
  marginBottom: '-0.5rem',
});

const StyledButton = styled('div')({
  backgroundColor: '#629388',
  minWidth: '12.5rem',
  borderRadius: '1.5625rem',
  color: '#FFF',
  fontWeight: 900,
  '&:hover': {
    backgroundColor: '#629388',
  },
});

const StyledBottomPane = styled('div')({
  gap: '3.125rem',
  display: 'flex',
  justifyContent: 'space-between',
});

const StyledMenuTitleText = styled('div')({
  padding: '0.5rem 0',
  fontWeight: 'bold',
  color: 'black',
  textAlign: 'center',
  width: '100%',
});

const StyledMenuContainer = styled(MenuList)({
  border: '0.0625rem solid black',
});

const StyledMenuList = styled('div')({
  padding: '1rem',
  borderTop: '0.0625rem solid black',
  backgroundColor: '#E2E2E2',
  cursor: 'pointer',
});

const StyledPowerOn = styled('div')({
  fontWeight: 'bold',
});

const StyledPowerModalTitle = styled('div')({
  fontWeight: 900,
  marginBottom: '0.5rem',
  color: '#6B6B6B',
  textAlign: 'center',
});

const StyledQuickStartText = styled('div')({
  textAlign: 'start',
  color: '#838383',
  fontSize: '1.1rem',
});

const StyledMenuListTitle = styled(MenuItem)({
  '&:hover, &:focus, &:active, &.Mui-selected': {
    backgroundColor: 'inherit',
  },
});

const MenuListComposition = ({
  component,
  instanceStates,
  relayState,
  loadingPowerState,
  instance,
  bitState,
}) => {
  const [open, setOpen] = useState(false);
  const [isForceStopConfirmationOpen, setForceStopConfirmationOpen] =
    useState(false);
  const [isDispenser, setDispenser] = useState(false);
  const [isSoftware, setSoftware] = useState(false);
  const [isElectronics, setElectronics] = useState(false);
  const [isPLC, setPLC] = useState(false);
  const [isController, setController] = useState(false);
  const [isComputer, setComputer] = useState(false);
  const [powerOn, setPowerOn] = useState(false);
  const [flippyRestart, setFlippyRestart] = useState(false);
  const [confirmRestart, setConfirmRestart] = useState(false);
  const [quickStart, setQuickStart] = useState(false);

  const anchorEl = useRef(null);

  useEffect(() => {
    if (
      instanceStates &&
      instanceStates.uiState &&
      loadingPowerState.text === 'On'
    ) {
      resetPowerLoadingState();
    }
  }, [instanceStates, loadingPowerState.text]);

  const resetPowerLoadingState = () => {
    store.dispatch(toggleLoadingPowerState({status: false, text: ''}));
  };

  const handleMenuOpenToggle = () => setOpen((prevOpen) => !prevOpen);

  const handleClose = (event) => {
    if (anchorEl.current.contains(event?.target)) {
      return;
    }
    setOpen(false);
    setElectronics(false);
    setSoftware(false);
  };

  const handleFlippyPowerToggle = async (
    event,
    powerRequest,
    errorMsg,
    successMsg
  ) => {
    if (!power_button_in_use) {
      power_button_in_use = true;
      handleClose(event);
      store.dispatch(
        toggleLoadingPowerState({
          status: true,
          text:
            powerRequest === 'start_flippy_without_tests'
              ? POWER_STATE.POWERING_ON
              : powerRequest === 'start_flippy_with_tests'
                ? POWER_STATE.RUNNING_BIT_FOR
                : POWER_STATE.POWERING_OFF,
        })
      );
      clear_ros();
      try {
        await flippy_power_ap.requestPowerFlippy(powerRequest);
        console.log(successMsg);
      } catch (error) {
        console.log(`${errorMsg} Error: ${error}`);
        setTimeout(() => {
          let failure_hint;
          if (error.includes('command')) {
            failure_hint =
              'You might want to wait or coordinate with other RSAs.';
          } else {
            if (
              powerRequest === 'start_flippy_without_tests' ||
              powerRequest === 'start_flippy_with_tests'
            ) {
              if (
                error.includes('timeout') ||
                error.includes('ROS Master') ||
                error.includes('timed out')
              ) {
                failure_hint =
                  'You might want to "force shutdown" first before powering on.';
              }
            }
          }
          window.alert(
            `Request to ${powerRequest} failed. Error: ${error}\nhint: ${failure_hint || 'Try again or contact Admin.'}`
          );
        }, 1);
      }
      reset_ros(instance);
      resetPowerLoadingState();
      power_button_in_use = false;
    } else {
      handleClose(event);
    }
    if (powerRequest === 'kill_flippy' || powerRequest === 'stop_flippy') {
      store.dispatch({type: RESET_ALL, host: instance});
      if (powerRequest === 'kill_flippy') {
        handleForceStopConfirmation();
      }
    }
  };

  const handleStart = (event) => {
    setPowerOn(false);
    handleFlippyPowerToggle(
      event,
      'start_flippy_without_tests',
      'Power on request failed.',
      'Machine powered on.'
    );
  };
  const handleStartWithBIT = (event) =>
    handleFlippyPowerToggle(
      event,
      'start_flippy_with_tests',
      'Power on with BIT request failed.',
      'Machine powered on.'
    );
  const handleStop = (event) =>
    handleFlippyPowerToggle(
      event,
      'stop_flippy',
      'Shutdown request failed.',
      'Machine powered off.'
    );

  const handleForceStopConfirmation = () =>
    setForceStopConfirmationOpen((prev) => !prev);

  const handleForceStop = async (event) => {
    setForceStopConfirmationOpen(false);
    await handleFlippyPowerToggle(
      event,
      'kill_flippy',
      'Force shutdown request failed',
      'Machine powered off'
    );
  };

  const handleSettings = () => {
    setDispenser((prev) => !prev);
    setOpen(false);
  };

  const handleFlippySoftware = () => setSoftware((prev) => !prev);
  const handleFlippyElectronics = () => setElectronics((prev) => !prev);

  const toggleRelay = async (relayName, toggle, restart) => {
    let request = {name: relayName, command: toggle};
    let requestRestart = {name: relayName, command: 'on'};

    await callService(
      setRelayController,
      instance,
      request,
      `Toggle ${relayName} Relay`
    );

    if (restart) {
      setTimeout(async () => {
        await callService(
          setRelayController,
          instance,
          requestRestart,
          `Restart ${relayName} Relay`
        );
      }, 15000);
    }
  };

  const handleRelayPowerOff = (relayName, relayState) => {
    toggleRelay(relayName, relayState ? 'off' : 'on', false);
    setDispenser((prev) => (relayName === 'dispenser' ? !prev : prev));
    setPLC((prev) => (relayName === 'plc' ? !prev : prev));
    setController((prev) => (relayName === 'yaskawa' ? !prev : prev));
    setOpen(false);
  };

  const handleConfirmRestartFlippy = () => setConfirmRestart(true);

  const handleRestartRelay = (relayName) => {
    toggleRelay(relayName, 'off', true);
    setDispenser((prev) => (relayName === 'dispenser' ? false : prev));
    setPLC((prev) => (relayName === 'plc' ? false : prev));
    setController((prev) => (relayName === 'yaskawa' ? false : prev));
    setElectronics((prev) =>
      relayName === 'plc' || relayName === 'yaskawa' ? false : prev
    );
    setConfirmRestart(false);
  };

  const handleRestartComputer = (relayName) => {
    toggleRelay(relayName, 'trigger', false);
    setComputer((prev) => (relayName === 'compute_node' ? false : prev));
    setFlippyRestart((prev) => (relayName === 'machine_reset' ? false : prev));
    setElectronics((prev) =>
      relayName === 'compute_node' || relayName === 'machine_reset'
        ? false
        : prev
    );
    setConfirmRestart(false);
    setOpen(false);
    store.dispatch(
      toggleLoadingPowerState({
        status: true,
        text: 'Restarting Entire Flippy System',
      })
    );
  };

  const handlePowerOn = () => setPowerOn((prev) => !prev);

  const handlePLC = () => {
    setPLC((prev) => !prev);
    setOpen(false);
  };

  const handleController = () => {
    setController((prev) => !prev);
    setOpen(false);
  };

  const handleComputer = () => {
    setComputer((prev) => !prev);
    setOpen(false);
  };

  const handleFlippyRestart = () => {
    setFlippyRestart((prev) => !prev);
    setOpen(false);
  };

  const isRosRunning = instanceStates && instanceStates.isRosRunning;
  let dispenserRelayState, plcRelayState, controllerRelayState;

  if (isRosRunning && relayState && relayState.length > 0) {
    dispenserRelayState =
      relayState.find((item) => item.name === 'dispenser').is_closed || false;
    plcRelayState =
      relayState.find((item) => item.name === 'plc').is_closed || false;
    controllerRelayState =
      relayState.find((item) => item.name === 'yaskawa').is_closed || false;
  }

  return (
    <StyledRoot>
      <div>
        <StyledToggleMenuButton
          ref={anchorEl}
          aria-owns={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleMenuOpenToggle}
        >
          {component}
        </StyledToggleMenuButton>
        <Popper
          open={open}
          anchorEl={anchorEl.current}
          transition
          disablePortal
          style={{zIndex: '9999'}}
          placement="bottom-end"
        >
          {({TransitionProps, placement}) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper style={{width: '18rem'}}>
                <ClickAwayListener onClickAway={handleClose}>
                  <StyledMenuContainer disablePadding={true}>
                    {isSoftware ? (
                      <div>
                        <StyledMenuListTitle disableRipple>
                          <IconButton
                            aria-label="AngleLeft"
                            onClick={handleFlippySoftware}
                            data-tip="Angle Left"
                          >
                            <FaAngleLeft color="black" />
                          </IconButton>
                          <StyledMenuTitleText>
                            {'Software'}
                          </StyledMenuTitleText>
                        </StyledMenuListTitle>
                        <StyledMenuList onClick={handleStop}>
                          {'Safe Shutdown'}
                        </StyledMenuList>
                        <StyledMenuList onClick={handleForceStopConfirmation}>
                          {'Force Shutdown'}
                        </StyledMenuList>
                      </div>
                    ) : isElectronics ? (
                      <div>
                        <StyledMenuListTitle>
                          <IconButton
                            aria-label="AngleLeft"
                            onClick={handleFlippyElectronics}
                            data-tip="Angle Left"
                          >
                            <FaAngleLeft color="black" />
                          </IconButton>
                          <StyledMenuTitleText>
                            {'Electronics'}
                          </StyledMenuTitleText>
                        </StyledMenuListTitle>
                        <StyledMenuList onClick={handlePLC}>
                          {'PLC'}
                        </StyledMenuList>
                        <StyledMenuList onClick={handleController}>
                          {'Controller'}
                        </StyledMenuList>
                        <StyledMenuList onClick={handleComputer}>
                          {'Computer'}
                        </StyledMenuList>
                      </div>
                    ) : (
                      <div>
                        <StyledMenuListTitle disableRipple>
                          <StyledMenuTitleText>
                            {'Power Menu'}
                          </StyledMenuTitleText>
                        </StyledMenuListTitle>
                        {isRosRunning && (
                          <StyledMenuList onClick={handleFlippyElectronics}>
                            {'Electronics'}
                          </StyledMenuList>
                        )}
                        {!bitState && (
                          <StyledMenuList
                            onClick={
                              isRosRunning
                                ? handleFlippySoftware
                                : handlePowerOn
                            }
                          >
                            {isRosRunning ? (
                              'Software'
                            ) : (
                              <StyledPowerOn>Power On Software</StyledPowerOn>
                            )}
                          </StyledMenuList>
                        )}
                        {!isRosRunning && (
                          <>
                            <StyledMenuList
                              onClick={handleForceStopConfirmation}
                            >
                              {'Force Shutdown'}
                            </StyledMenuList>
                          </>
                        )}
                        {isRosRunning && (
                          <>
                            <StyledMenuList onClick={handleSettings}>
                              {'Dispenser'}
                            </StyledMenuList>
                            <StyledMenuList
                              onClick={handleFlippyRestart}
                              style={{background: 'red', fontWeight: 'bold'}}
                            >
                              {'Full System Reset'}
                            </StyledMenuList>
                          </>
                        )}
                      </div>
                    )}
                  </StyledMenuContainer>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
      {isDispenser && (
        <div>
          <PowerSettingsModal
            open={isDispenser}
            title={
              confirmRestart
                ? "Are you sure you want to RESTART Flippy's Dispenser?"
                : "Flippy's Dispenser"
            }
            textLeft={confirmRestart ? 'Continue' : 'Restart'}
            textRight={
              confirmRestart
                ? ''
                : dispenserRelayState
                  ? 'Power Off'
                  : 'Power On'
            }
            onClickLeft={() =>
              confirmRestart
                ? handleRestartRelay('dispenser')
                : handleConfirmRestartFlippy()
            }
            onClickRight={() =>
              handleRelayPowerOff('dispenser', dispenserRelayState)
            }
            onClose={() => {
              setDispenser(false);
              setConfirmRestart(false);
            }}
          />
        </div>
      )}
      {isForceStopConfirmationOpen && (
        <div>
          <PowerSettingsModal
            open={isForceStopConfirmationOpen}
            title={'Are you sure you want to Force Shutdown Software?'}
            content={
              'Flippy will stop cooking and all food in the queue will be removed.'
            }
            textLeft={'Continue'}
            onClickLeft={handleForceStop}
            onClose={() => setForceStopConfirmationOpen(false)}
          />
        </div>
      )}
      {!isRosRunning && powerOn && (
        <div>
          <PowerSettingsModal
            open={powerOn}
            title={'Startup Software'}
            content={
              <StyledQuickStartText>
                Quick Start?{' '}
                <i>
                  (This will skip Built in Test on Startup and expidite the
                  startup process, only select if short on time)
                </i>
              </StyledQuickStartText>
            }
            textLeft={'Continue'}
            onQuickStart={() => setQuickStart((prev) => !prev)}
            onClickLeft={() =>
              quickStart ? handleStart() : handleStartWithBIT()
            }
            onClose={() => setPowerOn(false)}
          />
        </div>
      )}
      {isPLC && (
        <div>
          <PowerSettingsModal
            open={isPLC}
            title={
              confirmRestart
                ? "Are you sure you want to RESTART Flippy's PLC?"
                : "Flippy's PLC"
            }
            content={
              confirmRestart
                ? 'Flippy will stop cooking and all food in the queue will be removed.'
                : ''
            }
            textLeft={confirmRestart ? 'Continue' : 'Restart'}
            textRight={
              confirmRestart ? '' : plcRelayState ? 'Power Off' : 'Power On'
            }
            onClickLeft={() =>
              confirmRestart
                ? handleRestartRelay('plc')
                : handleConfirmRestartFlippy()
            }
            onClickRight={() => handleRelayPowerOff('plc', plcRelayState)}
            onClose={() => {
              setPLC(false);
              setConfirmRestart(false);
            }}
          />
        </div>
      )}
      {isController && (
        <div>
          <PowerSettingsModal
            open={isController}
            title={
              confirmRestart
                ? "Are you sure you want to RESTART Flippy's Robot Controller?"
                : "Flippy's Robot Controller"
            }
            content={
              confirmRestart
                ? 'Flippy will stop cooking and all food in the queue will be removed.'
                : ''
            }
            textLeft={confirmRestart ? 'Continue' : 'Restart'}
            textRight={
              confirmRestart
                ? ''
                : controllerRelayState
                  ? 'Power Off'
                  : 'Power On'
            }
            onClickLeft={() =>
              confirmRestart
                ? handleRestartRelay('yaskawa')
                : handleConfirmRestartFlippy()
            }
            onClickRight={() =>
              handleRelayPowerOff('yaskawa', controllerRelayState)
            }
            onClose={() => {
              setController(false);
              setConfirmRestart(false);
              setElectronics(false);
            }}
          />
        </div>
      )}
      {isComputer && (
        <div>
          <PowerSettingsModal
            open={isComputer}
            title={
              confirmRestart
                ? "Are you sure you want to RESTART Flippy's Computer?"
                : "Flippy's Computer"
            }
            content={
              confirmRestart
                ? 'Flippy will stop cooking and all food in the queue will be removed.'
                : ''
            }
            textLeft={confirmRestart ? 'Continue' : 'Restart'}
            onClickLeft={() =>
              confirmRestart
                ? handleRestartComputer('compute_node')
                : handleConfirmRestartFlippy()
            }
            onClose={() => {
              setComputer(false);
              setConfirmRestart(false);
              setElectronics(false);
            }}
          />
        </div>
      )}
      {flippyRestart && (
        <div>
          <PowerSettingsModal
            open={flippyRestart}
            title={'Are you sure you want to RESTART the ENTIRE Flippy system?'}
            content={
              'Flippy will stop cooking and all food in the queue will be removed.'
            }
            textLeft={'Continue'}
            onClickLeft={() => handleRestartComputer('machine_reset')}
            onClose={() => {
              setFlippyRestart(false);
              setElectronics(false);
            }}
            icon={'Warning'}
          />
        </div>
      )}
    </StyledRoot>
  );
};

MenuListComposition.propTypes = {
  instanceStates: PropTypes.object.isRequired,
  component: PropTypes.element,
  instance: PropTypes.string,
  loadingPowerState: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    loadingPowerState: state.ui.loadingPowerState,
  };
};

export default connect(mapStateToProps)(MenuListComposition);

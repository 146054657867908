import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import misoLogo from '../assets/Onion_Purple.svg';
import {colors, errorDrawerWidth} from '../styles';
import {getInfo} from '../util';
import LegendTable from './LegendTable';
import Timer from './Timer';

const StyledContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: errorDrawerWidth,
  backgroundColor: colors.white,
});

const StyledDrawer = styled(Drawer)({
  width: errorDrawerWidth,
  flexShrink: 0,
});

const StyledTitle = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  minHeight: '8vh',
  height: 'auto',
  background: '#68535c',
}));

const StyledTimeHolder = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
});

const StyledLocationHolder = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '.5em',
  justifyContent: 'space-between',
  flex: 1,
});

const StyledLogo = styled('img')({
  width: '3em',
  height: '3em',
});

const StyledLocationColumn = styled('div')({
  paddingTop: '.5em',
  display: 'flex',
  flexDirection: 'column',
  color: 'white',
});

const StyledHubContent = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-around',
  flex: 1,
});

const StyledDrawerContainer = styled('div')({
  display: 'flex',
  flex: 1,
  overflowY: 'auto',
});

const ErrorStatuses = ({instance, instanceStates}) => {
  const {name, location, logo} = getInfo(instance);
  const {versionNumber} = instanceStates[instance] || '';

  return (
    <StyledContainer>
      <StyledDrawer
        variant="permanent"
        sx={{
          '& .MuiDrawer-paper': {
            width: errorDrawerWidth,
            display: 'flex',
            flex: 1,
            overflow: 'hidden',
            border: 'none',
          },
        }}
      >
        <StyledTitle>
          <StyledTimeHolder>
            <Typography variant="subtitle2" style={{color: 'white'}}>
              MSC
            </Typography>
            <Timer format="LTS" variant="subtitle1" style={{color: 'white'}} />
          </StyledTimeHolder>
          {instance ? (
            <StyledLocationHolder>
              <StyledLocationColumn>
                <Typography style={{color: 'white'}}>{name}</Typography>
                <Typography style={{color: 'white'}}>{location}</Typography>
                <Typography style={{color: 'white'}}>
                  SW: {versionNumber}
                </Typography>
              </StyledLocationColumn>
              {logo && <StyledLogo src={logo} alt="logo" />}
            </StyledLocationHolder>
          ) : (
            <StyledHubContent>
              <Typography variant="h6" style={{color: 'white'}}>
                Hub Page
              </Typography>
              <StyledLogo src={misoLogo} alt="logo" />
            </StyledHubContent>
          )}
        </StyledTitle>
        <Divider />
        <StyledDrawerContainer>
          <LegendTable />
        </StyledDrawerContainer>
        <div style={{background: '#68535c', height: '4vh'}}></div>
      </StyledDrawer>
    </StyledContainer>
  );
};

ErrorStatuses.propTypes = {
  instance: PropTypes.string,
  instanceStates: PropTypes.object.isRequired,
};

export default ErrorStatuses;

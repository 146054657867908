import React, {useState} from 'react';
import {Card, CardContent, Dialog, Switch, Typography} from '@mui/material/';
import MisoDialogTitle from '../components/MisoDialogTitle';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';

import {disableSlot, mqtt_ap} from '../ros_init';
import {DISABLING_LEVEL} from '../enums';

import SlotStatusReason from '../modals/SlotStatusReason';
import moment from 'moment';

const StyledDialogTitleWrap = styled('div')({
  padding: '2rem',
  marginBottom: '0.25rem',
});

const StyledDialogTitle = styled(MisoDialogTitle)({
  padding: 0,
  fontSize: '1.75rem',
  lineHeight: '2rem',
  fontWeight: 700,
});

const StyledBasketsListWrap = styled('div')({
  padding: '0 2rem',
});

const StyledBasketsList = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  overflow: 'auto',
  padding: '1rem',
  backgroundColor: '#E7E9EC',
  borderRadius: '1.5rem',
});

const StyledCard = styled(Card)({
  width: '16rem',
  height: '6rem',
  marginBottom: '0.75rem',
  borderRadius: '0.75rem',
});

const StyledCardContent = styled(CardContent)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: 0,
});

const StyledCardTitleWrap = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const StyledCardTitle = styled(Typography)({
  fontSize: '1.25rem',
  fontWeight: 700,
  lineHeight: '1.5rem',
  color: '#5C5C5C',
});

const StyledSwitch = styled(Switch)({});

const StyledDisableReason = styled(Typography)({
  fontSize: '0.75rem',
  fontWeight: 700,
  lineHeight: '1.125rem',
  textAlign: 'center',
  color: '#D9D9D9',
});

const StyledDisableTime = styled(Typography)({
  fontSize: '0.75rem',
  fontWeight: 700,
  lineHeight: '1.125rem',
  textAlign: 'center',
  color: '#D9D9D9',
});
// Reasons for disabling/enabling slots
const disableReasons = ['Broken', 'Other'];
const enableReasons = ['Repaired', 'Replaced', 'Other'];

const BasketModal = ({open, onClose, basketList, baskets, user, instance}) => {
  const [isSlotReasonModalOpen, setSlotReasonModalOpen] = useState(false);
  const [selectedBasketNo, setSelectedBasketNo] = useState(null);
  const [selectedReason, setSelectedReason] = useState('');
  const [selectedBasket, setSelectedBasket] = useState({});

  const handleToggleReasonSelection = (value, desiredBasket) => {
    setSelectedBasketNo(value);
    setSelectedReason('');
    setSelectedBasket(desiredBasket);
    setSlotReasonModalOpen(true);
  };

  const modifySlotHelper = async (
    service,
    verb,
    is_disabled,
    slotPath,
    reason
  ) => {
    service[instance].setData = {
      user: {permissions: DISABLING_LEVEL.SUPPORT, name: user},
      component_path: slotPath,
      disabled: is_disabled,
      reason: reason,
    };
    try {
      await mqtt_ap.submit_request(service[instance]);
    } catch (error) {
      console.log('Submit_Request error: ' + error);
    }
  };

  const getEnableSlotFunc = (reason) => {
    const slotPath = selectedBasket.slot_path;
    return modifySlotHelper(disableSlot, 'Enabled', false, slotPath, reason);
  };

  const getDisableSlotFunc = (reason) => {
    const slotPath = selectedBasket.slot_path;
    return modifySlotHelper(disableSlot, 'Disabled', true, slotPath, reason);
  };

  const handleSlotToggle = (reason) => {
    if (selectedBasket.disabled) {
      getEnableSlotFunc(reason);
    } else {
      getDisableSlotFunc(reason);
    }
    onClose();
    resetInitialState();
  };

  const resetInitialState = () => {
    setSelectedBasketNo(null);
    setSelectedReason('');
    setSelectedBasket({});
    setSlotReasonModalOpen(false);
  };

  const newBasketList = Object.values(basketList).flat();

  return (
    <div>
      {baskets.length > 0 && (
        <Dialog
          open={open}
          onClose={() => {
            onClose();
            setSelectedBasketNo(null);
            setSelectedReason('');
            setSelectedBasket({});
            setSlotReasonModalOpen(false);
          }}
          maxWidth="lg"
          PaperProps={{
            style: {
              width: '100%',
            },
          }}
        >
          <StyledDialogTitleWrap>
            <StyledDialogTitle onClose={onClose} disableTypography>
              Baskets
            </StyledDialogTitle>
          </StyledDialogTitleWrap>
          {!selectedBasket || !selectedBasket.basket_id ? (
            <StyledBasketsListWrap>
              <StyledBasketsList>
                {newBasketList.map((basketId, index) => {
                  const desiredBasket = baskets.find(
                    (basket) => basket.basket_id === basketId
                  );
                  const timestamp =
                    desiredBasket.disable_time.secs * 1000 +
                    desiredBasket.disable_time.nsecs / 1000000;
                  return (
                    <StyledCard key={basketId}>
                      <StyledCardContent>
                        <StyledCardTitleWrap>
                          <StyledCardTitle>Basket {basketId}</StyledCardTitle>
                          <StyledSwitch
                            checked={!desiredBasket.disabled}
                            color="primary"
                            size="small"
                            onClick={() =>
                              handleToggleReasonSelection(
                                basketId,
                                desiredBasket
                              )
                            }
                          />
                        </StyledCardTitleWrap>
                        {desiredBasket.disabled && (
                          <>
                            <StyledDisableReason>
                              Disabled - {desiredBasket.disable_reason}
                            </StyledDisableReason>
                            <StyledDisableTime>
                              {moment(timestamp).format('M/D/YY HH:mm')}
                            </StyledDisableTime>
                          </>
                        )}
                      </StyledCardContent>
                    </StyledCard>
                  );
                })}
              </StyledBasketsList>
            </StyledBasketsListWrap>
          ) : (
            <SlotStatusReason
              slotObj={selectedBasket}
              handleModalVisibility={setSlotReasonModalOpen}
              handleSlotToggle={handleSlotToggle}
              enableReasons={enableReasons}
              disableReasons={disableReasons}
              inSingleStepModal={false}
              resetInitialState={resetInitialState}
              type={'Basket'}
            />
          )}
        </Dialog>
      )}
    </div>
  );
};

BasketModal.propTypes = {
  toggle: PropTypes.object.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default BasketModal;

import React, {useCallback, useState} from 'react';
import {IoMdArrowDropdown, IoMdArrowDropup} from 'react-icons/io';

import {getStatus, toReadableString} from '../../../util';
import {
  StyledBodyPane,
  StyledItemLeft,
  StyledItemName,
  StyledItemRight,
  StyledRight,
  StyledTitle,
  StyledToggleItem,
  StyledToggleList,
  StyledTopPane,
  StyledIexport,
} from './styles';

const ToggleList = ({title, statuses}) => {
  const [toggle, setToggle] = useState(false);

  const formatLastUpdated = useCallback((lastUpdated) => {
    const utcDate = new Date(lastUpdated);
    const localDate = new Date(
      utcDate.getTime() - utcDate.getTimezoneOffset() * 60000
    );

    const time = localDate.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
    const extraSpaces = '\u00A0\u00A0\u00A0';
    const formattedDate =
      time +
      extraSpaces +
      utcDate
        .toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
        })
        .replace(/\//g, '-');

    return formattedDate;
  }, []);

  const renderToggleIcon = () => {
    return toggle ? (
      <StyledIexport>
        <IoMdArrowDropup />
      </StyledIexport>
    ) : (
      <StyledIexport>
        <IoMdArrowDropdown />
      </StyledIexport>
    );
  };

  const keys = statuses.stats ? Object.keys(statuses.stats) : null;

  return (
    <div>
      {statuses.stats && (
        <StyledToggleList>
          <StyledTopPane onClick={() => setToggle((prev) => !prev)}>
            <StyledTitle>{title}</StyledTitle>
            <StyledRight>
              {getStatus(statuses.status, [])}
              {renderToggleIcon()}
            </StyledRight>
          </StyledTopPane>
          {toggle && keys && (
            <StyledBodyPane>
              {keys.map((key, index) => (
                <StyledToggleItem key={index}>
                  <StyledItemLeft>
                    <StyledItemName>{toReadableString(key)}</StyledItemName>
                  </StyledItemLeft>
                  <StyledItemRight>
                    <StyledItemName>
                      {statuses.last_updated[key] !== undefined &&
                      statuses.last_updated[key] !== null &&
                      statuses.stats[key] !== 0
                        ? formatLastUpdated(statuses.last_updated[key])
                        : ''}
                    </StyledItemName>
                    <StyledItemName>
                      {getStatus(
                        Boolean(statuses.stats[key]),
                        Object.keys(statuses.stats),
                        {}
                      )}
                    </StyledItemName>
                  </StyledItemRight>
                </StyledToggleItem>
              ))}
            </StyledBodyPane>
          )}
        </StyledToggleList>
      )}
    </div>
  );
};

export default ToggleList;

import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import DraggableDialog from '../components/DraggableDialog.jsx';
import MisoDialogTitle from '../components/MisoDialogTitle.jsx';

const StyledButtonContainer = styled('div')(() => ({
  float: 'center',
  display: 'flex',
  justifyContent: 'space-evenly',
}));

const ElevatorConfirmation = ({
  open,
  onClose,
  jogging,
  jogRobot,
  openElevatorModal,
}) => {
  const location = useLocation();

  const windowTitle = jogging ? 'Unjog Robot' : 'Jog Robot';
  const windowQuery = jogging
    ? 'Are you sure you want to unjog the robot?'
    : 'Are you sure you want to abort action and jog the robot?';

  return (
    <div>
      <DraggableDialog
        open={open}
        onClose={onClose}
        aria-labelledby="jog-confirmation-dialog"
        fullWidth
      >
        <MisoDialogTitle id="jog-confirmation-dialog" onClose={onClose}>
          {windowTitle}
        </MisoDialogTitle>
        <p
          style={{
            color: 'red',
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '1.5rem',
          }}
        >
          {windowQuery}
        </p>
        <StyledButtonContainer>
          <Button
            onClick={() => {
              jogRobot();
              onClose();
              openElevatorModal();
            }}
          >
            <Typography variant="h4" color="000000b3">
              Yes
            </Typography>
          </Button>
          <Button
            onClick={() => {
              jogging ? openElevatorModal() : onClose();
            }}
            style={{maxWidth: '60%'}}
          >
            <Typography variant="h4" color="000000b3">
              {jogging ? 'No - Continue to Elevator Modal' : 'No'}
            </Typography>
          </Button>
        </StyledButtonContainer>
      </DraggableDialog>
    </div>
  );
};

ElevatorConfirmation.propTypes = {
  jogging: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  jogRobot: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

export default ElevatorConfirmation;

export const colors = {
  black33: 'rgba(0, 0, 0, .33)',
  black: 'rgb(0, 0, 0)',
  lightGray: 'rgba(220, 220, 220)',
  white: 'rgb(255, 255, 255)',
  whiteTwo: 'rgb(239, 239, 239)',
  whiteThree: 'rgb(252, 252, 252)',
  paleGrey: 'rgb(236, 239, 241)',
  pinkishGrey: 'rgb(206, 206, 206)',
  greyishBrown: 'rgb(74, 74, 74)',
  warmGreyTwo: 'rgb(119, 119, 119)',
  warmGreyThree: 'rgb(155, 155, 155)',
  warmGreyFour: 'rgb(151, 151, 151)',
  turquoiseGreen: 'rgb(0, 230, 118)',
  coral: 'rgb(239, 83, 80)',
  greyish: 'rgb(182, 182, 182)',
  coolGreyTwo: 'rgb(176, 189, 197)',
  tealish: 'rgb(37, 166, 154)',
  azure: 'rgb(41, 182, 246)',
  lightRed: 'rgba(239, 83, 80, 0.63)',
  darkRed: '#a70014',
  grayish: 'rgb(244,242,247)',
  green: 'rgb(0, 128, 0)',
};

export const mediumTextSize = '16pt';
export const errorDrawerWidth = '15rem';

export const borderButtonPadding = '0.5rem';

export const shadow = {
  shadowColor: colors.black33,
  shadowOffset: {
    width: 0,
    height: 1,
  },
  shadowRadius: 3,
  shadowOpacity: 1,
};

export const border = (theme) => ({
  borderStyle: 'solid',
  borderWidth: 1,
  borderColor: theme.palette.text.disabled,
});

export const fontBase = {
  fontFamily: 'Montserrat',
  fontWeight: 'normal',
  fontStyle: 'normal',
  letterSpacing: 0,
  color: colors.greyishBrown,
};

export const center = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const greenCheckStyle = {
  fontSize: '1rem',
  color: 'green',
  padding: '0rem',
  margin: '0rem',
};

export const redErrorStyle = {
  fontSize: '1rem',
  color: 'red',
  padding: '0rem',
  margin: '0rem',
};

export const greyErrorStyle = {
  fontSize: '1rem',
  color: 'grey',
  padding: '0rem',
  margin: '0rem',
};

export const greyQuestionStyle = {
  ...greyErrorStyle,
};

export const yellowCautionStyle = {
  fontSize: '1rem',
  color: 'yellow',
  background: 'linear-gradient(black,black) center bottom/20% 84% no-repeat',
  filter: 'drop-shadow(0.1875rem 0.3125rem 0.125rem rgb(0 0 0 / 0.4))',
  stroke: 'black',
  strokeWidth: '1rem',
  padding: '0rem',
  margin: '0rem',
};

import React from 'react';
import ReactDOM from 'react-dom/client'; // Import from react-dom/client
import {Provider} from 'react-redux';
import App from './App';
import './index.css';
import registerServiceWorker from './registerServiceWorker';
import store from './store';
import {apiConfig} from './api';

// Initialize axios config
apiConfig(store);

// Get the root element
const rootElement = document.getElementById('root');

// Create a root and render the app
const root = ReactDOM.createRoot(rootElement);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// Register the service worker
registerServiceWorker();

import {styled} from '@mui/material/styles';

export const StyledContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '41.25rem',
  minHeight: '12.5rem',
  padding: '0 1.25rem',
});

export const StyledTitleContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: '2.2rem',
});

export const StyledTitle = styled('div')({
  fontSize: '1.65rem',
  fontWeight: 'bold',
  color: '#838383',
  padding: 0,
  margin: 0,
  textAlign: 'center',
});

export const StyledContent = styled('div')({
  display: 'flex',
  flex: 1.25,
  alignItems: 'center',
  justifyContent: 'center',
});

export const StyledContentText = styled('div')({
  fontSize: '1.3rem',
  color: '#B74C3E',
  fontWeight: 400,
  padding: 0,
  margin: 0,
  letterSpacing: 0,
  textAlign: 'center',
});

export const StyledSubtext = styled('div')({
  fontWeight: 'bold',
  fontSize: '1.1rem',
  fontStyle: 'italic',
  textAlign: 'center',
  color: '#838383',
  padding: '0.5rem',
  margin: 0,
});

export const StyledBottomPane = styled('div')({
  display: 'flex',
  flex: 0.8,
  alignItems: 'center',
  justifyContent: 'center',
  gap: '0.625rem',
  paddingTop: '0.75rem',
  paddingBottom: '2rem',
});

export const StyledLeftRightBtn = styled('div')({
  width: '10rem',
  height: '50%',
  border: '0.0625rem solid #7E7D7E',
  display: 'flex', // Enables flexbox layout
  justifyContent: 'center', // Horizontally centers the text
  alignItems: 'center', // Vertically centers the text
  padding: '0.5rem 1rem', // Optional: adds horizontal padding to ensure the text is not flush to the edge
  fontSize: '0.95rem', // Adjust text size as needed
  cursor: 'pointer', // Makes the div behave like a button
  textAlign: 'center', // Ensures text is centered
  borderRadius: '0.25rem',
  textTransform: 'uppercase',
});

import React from 'react';
import {connect} from 'react-redux';
import {Dialog} from '@mui/material/';

import {
  StyledBottomPane,
  StyledCenterPane,
  StyledConfirmationModal,
  StyledPositiveButton,
  StyledNegativeButton,
  StyledTitle,
  StyledTopPane,
} from './styles';

const ConfirmationModal = ({
  open,
  title,
  content,
  onClose,
  textPositive,
  onClickPositive,
  textNegative,
  onClickNegative,
  rebooting,
}) => {
  return (
    <Dialog maxWidth="lg" open={open} onClose={onClose}>
      <StyledConfirmationModal>
        <StyledTopPane>
          <StyledTitle>{title}</StyledTitle>
        </StyledTopPane>
        <StyledCenterPane>{content}</StyledCenterPane>
        <StyledBottomPane>
          {onClickPositive && (
            <StyledPositiveButton
              label={textPositive || 'Yes'}
              onClick={onClickPositive}
              disabled={rebooting}
            />
          )}
          {onClickNegative && (
            <StyledNegativeButton
              label={textNegative || 'No'}
              onClick={onClickNegative}
              disabled={rebooting}
              color={'error'}
            />
          )}
        </StyledBottomPane>
      </StyledConfirmationModal>
    </Dialog>
  );
};

const mapState = ({instances}) => ({
  instances,
});

const mapDispatch = (dispatch) => ({
  dispatch: (action) => dispatch(action),
});

export default connect(mapState, mapDispatch)(ConfirmationModal);

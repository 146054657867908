import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import Typography from '@mui/material/Typography';

import {getAlertDesign, getInfo} from '../../util';
import {
  StyledBtnRightIcon,
  StyledButtonBase,
  StyledButtonContainer,
  StyledContainer,
  StyledLabel,
  StyledLabelContainer,
  StyledLogo,
  StyledNumRedAlertsContainer,
  StyledPaper,
} from './styles';
import {styled} from '@mui/material/styles';

const HubCard = ({
  instance,
  info,
  numRedAlerts,
  onClickStatus,
  email,
  siteId,
  deviceid,
}) => {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    let thing_name = siteId + '-' + deviceid;
    if (deviceid === '') {
      thing_name = siteId;
    }
    navigate(`/${instance}`);
  }, [navigate, instance, siteId, deviceid]);

  const handleStatusClick = useCallback(
    (disabled) => (e) => {
      e.stopPropagation();
      if (!disabled) {
        onClickStatus(e);
      }
    },
    [onClickStatus]
  );

  const {company, name, location, logo} = getInfo(instance);
  const latestTimestamp =
    info && info.timestamp
      ? new Date(Date.parse(info.timestamp + 'UTC')).toLocaleString()
      : '00/00/00 00:00';

  const {Icon, alertStyle} = getAlertDesign(info);
  const StyledRightIcon = styled(Icon)({
    fontSize: '1.5rem',
  });
  const isDisabled = alertStyle.borderColor === '#aaafba';

  return (
    <StyledButtonBase onClick={handleClick} focusRipple component="div">
      <StyledPaper style={alertStyle}>
        <StyledLabel>
          <StyledContainer>
            {numRedAlerts > 0 && (
              <StyledNumRedAlertsContainer>
                <Typography
                  variant="subtitle1"
                  align="center"
                  style={{fontWeight: 'bold'}}
                >
                  {numRedAlerts} Active Errors
                </Typography>
              </StyledNumRedAlertsContainer>
            )}
            <StyledButtonContainer>
              <StyledBtnRightIcon onClick={handleStatusClick(isDisabled)}>
                <StyledRightIcon />
              </StyledBtnRightIcon>
            </StyledButtonContainer>
          </StyledContainer>
          {logo && <StyledLogo src={logo} alt="logo" />}
          <Typography variant="h6" align="left">
            {company}
          </Typography>
          <Typography variant="h6" align="left">
            {name}
          </Typography>
          <StyledLabelContainer>
            <Typography variant="subtitle2">{location}</Typography>
            <Typography variant="subtitle2">{latestTimestamp}</Typography>
          </StyledLabelContainer>
        </StyledLabel>
      </StyledPaper>
    </StyledButtonBase>
  );
};

HubCard.propTypes = {
  instance: PropTypes.string.isRequired,
  info: PropTypes.object,
  numRedAlerts: PropTypes.number,
  onClickStatus: PropTypes.func,
  email: PropTypes.string,
  siteId: PropTypes.string,
  deviceid: PropTypes.string,
};

export default HubCard;

import React, {memo} from 'react';
import {connect} from 'react-redux';
import Spinner from 'react-spinkit';
import {smartCompare} from '../../util';
import {StyledLoadingPage} from './styles';

const Loading = ({status}) => {
  if (!status) return null;

  return (
    <StyledLoadingPage>
      <Spinner
        data-tip="Loading"
        name="circle"
        fadeIn="none"
        style={{
          width: '7.5em',
          height: '7.5em',
          marginTop: '5em',
        }}
      />
    </StyledLoadingPage>
  );
};

const mapState = ({instances}) => ({
  instances,
});

const mapDispatch = (dispatch) => ({
  dispatch: (action) => dispatch(action),
});

const Controller = memo(Loading, smartCompare(['status']));
export default connect(mapState, mapDispatch)(Controller);

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React, {Component, useState} from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';

import DraggableDialog from '../components/DraggableDialog';

import {ALARM_ID, DISABLING_LEVEL, TARGET_STATE, URGENCY_LEVEL} from '../enums';
import {abortTrajectory, abortBehavior, setTargetMode} from '../ros_init';
import {callService, getInfo} from '../util';

const AlertDialog = (props) => {
  const {
    name,
    title,
    body,
    instance,
    onClose,
    onSame,
    sameText,
    jogModal,
    jogging,
    user,
  } = props;
  const {company, location, serial} = getInfo(instance);

  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const toggleJog = (instance, user) => {
    const abort_request = {};
    const jog_request = {
      user: {
        permissions: DISABLING_LEVEL.SUPPORT,
        name: user,
      },
      mode: TARGET_STATE.JOGGING,
      urgency: URGENCY_LEVEL.ASAP,
      pose: '',
      reason: 'support_jogging',
    };
    callService(
      setTargetMode,
      instance,
      jog_request,
      'Set Manual Mode',
      null,
      async () => {
        await callService(
          abortBehavior,
          instance,
          abort_request,
          'Abort Robot Behavior',
          '',
          '',
          async () => {
            await callService(
              abortTrajectory,
              instance,
              abort_request,
              'Abort Robot Trajectory'
            );
          }
        );
      }
    );
  };
  return (
    <DraggableDialog
      open={open}
      aria-labelledby={name + '-dialog-title'}
      aria-describedby={name + '-dialog-description'}
    >
      <DialogTitle id={name + '-dialog-title'}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText
          id="notification-dialog-description"
          className="nodrag"
        >
          {body + ' at the following location:'}
          <br /> <br />
          <b>
            {instance}
            <br />
            {company}
            <br />
            {location}
            <br />
            {serial}
          </b>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={
            jogModal
              ? () => {
                  toggleJog(instance, user);
                  handleClose();
                }
              : () => {
                  handleClose();
                }
          }
          style={{color: 'black'}}
        >
          {jogModal ? 'OK' : 'Close'}
        </Button>
        {!jogModal && (
          <>
            <Button
              onClick={() => {
                if (title === 'Elevator Alarm Detected' && !jogging) {
                  toggleJog(instance, user);
                }
                handleClose();
                onSame();
              }}
              color="primary"
              variant="contained"
            >
              {sameText}
            </Button>
          </>
        )}
      </DialogActions>
    </DraggableDialog>
  );
};
AlertDialog.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  instance: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSame: PropTypes.func,
  sameText: PropTypes.string,
  jogModal: PropTypes.bool,
  jogging: PropTypes.bool,
};

const Notifications = ({
  instance,
  instances,
  openResolveCollisionModal,
  openJogModal,
  openElevatorModal,
  jogModal,
  elevatorModal,
  user,
}) => {
  const [alarmSeen, setAlarmSeen] = useState({});
  const [isActive, setIsActive] = useState({});

  const navigate = useNavigate();

  const closeAlarmModal = (instance) => {
    setAlarmSeen((prev) => ({...prev, [instance]: true}));
    setIsActive((prev) => ({...prev, [instance]: true}));

    setTimeout(() => {
      setIsActive((prev) => ({...prev, [instance]: false}));
    }, 60000);
  };

  const onInstanceChange = (instance) => {
    navigate(`/${instance}`);
  };

  const getDialogDetails = (alarmType) => {
    const productTitle = 'Flippy';
    return {
      title: `${alarmType} Alarm Detected`,
      body: `${productTitle} encountered an ${alarmType} Alarm`,
    };
  };

  const createAlertDialog = (
    alertType,
    instance,
    onSame,
    sameText,
    jogModal,
    jogging,
    user
  ) => {
    const keyname = `fanuc-${instance}-${alertType}`;
    const {title, body} = getDialogDetails(alertType);
    return (
      <AlertDialog
        key={keyname}
        name={keyname}
        title={title}
        body={body}
        instance={instance}
        onClose={() => closeAlarmModal(instance)}
        onSame={onSame}
        sameText={sameText}
        jogModal={jogModal}
        jogging={jogging}
        user={user}
      />
    );
  };

  const dialogs = () => {
    let dialogsArray = [];
    const instanceRedux = instances[instance];
    if (instanceRedux) {
      const robotAlarms = instanceRedux.notificationState.filter(
        (alarm) =>
          alarm.code === 'collision' &&
          alarm.component_path === '/agent/robot_arm'
      );

      const elevatorAlarms =
        instanceRedux.elevatorState?.notifications?.filter(
          (alarm) => alarm.code === 'collision'
        ) || [];

      const motionHoldRobotAlarms = Object.keys(
        instanceRedux.notificationState
      ).reduce((alarm, val) => {
        if (instanceRedux.notificationState[val].id === ALARM_ID.MOTION_HOLD) {
          alarm[val] = instanceRedux.notificationState[val];
        }
        return alarm;
      }, {});

      const jogging =
        instanceRedux.uiState?.current_mode === TARGET_STATE.JOGGING;

      const hasRobotAlarm = Object.keys(robotAlarms).length > 0;
      const hasElevatorAlarm = elevatorAlarms.length > 0;
      const hasMotionHoldRobotAlarm =
        Object.keys(motionHoldRobotAlarms).length > 0;

      if (hasRobotAlarm) {
        const newDialog = createAlertDialog(
          'Robot',
          instance,
          !jogging ? openResolveCollisionModal : openJogModal,
          'Open Resolve Collision Screen',
          jogModal,
          jogging,
          user
        );
        dialogsArray.push(newDialog);
      }

      if (hasElevatorAlarm && !hasRobotAlarm && !jogModal) {
        const newDialog = createAlertDialog(
          'Elevator',
          instance,
          openElevatorModal,
          'Open Elevator Jogging Screen',
          elevatorModal,
          jogging,
          user
        );
        dialogsArray.push(newDialog);
      }

      if (hasMotionHoldRobotAlarm) {
        const newDialog = createAlertDialog(
          'Mis-release or Soft Collision',
          instance,
          jogging ? openJogModal : openResolveCollisionModal,
          'Open Resolve Collision Screen',
          jogModal,
          jogging,
          user
        );
        dialogsArray.push(newDialog);
      }
    }
    return dialogsArray;
  };

  return <div>{dialogs()}</div>;
};

Notifications.propTypes = {
  instances: PropTypes.object.isRequired,
  openResolveCollisionModal: PropTypes.func.isRequired,
  openJogModal: PropTypes.func.isRequired,
  jogModal: PropTypes.bool,
};

export default Notifications;

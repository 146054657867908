import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, {useState} from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Typography,
} from '@mui/material/';

import {FaExclamationTriangle} from 'react-icons/fa';

import {MQTTRequest, MQTTRequestType} from '../../aws_iot/thing_mqtt_request';
import DraggableDialog from '../../components/DraggableDialog';
import MisoDialogTitle from '../../components/MisoDialogTitle';
import {
  controlGripperActionConfig,
  disableSlot,
  elevatorManual,
  gogoRoboEff,
  gogoRoboJoints,
  goToNamedJoints,
  jogElevator,
  mqtt_ap,
  resetElevator,
  robotReboot,
  robotReset,
  swapTool,
  USER_ID,
} from '../../ros_init';

import CollisionModal from '../CollisionModal';
import ConfirmationModal from '../ConfirmationModal';

import backward from '../../assets/backward.svg';
import forward from '../../assets/forward.svg';
import {
  ALARM_ID,
  COMMANDS,
  DISABLING_LEVEL,
  ELEVATOR_COMMANDS,
  GOGO_ROBO_ERROR,
} from '../../enums';
import {callService, subResult} from '../../util';

import {setGripperState} from '../../actions/index';
import store from '../../store/index';

import {
  StyledButtonContainer,
  StyledColumnContainer,
  StyledContainer,
  StyledFaLongArrowAltLeft,
  StyledFaLongArrowAltRight,
  StyledFaLongArrowAltUpIcon,
  StyledFaRedo,
  StyledFaUndo,
  StyledHomeMediumIcon,
  StyledRedMessage,
  StyledRightContainer,
  StyledRoot,
  StyledSmallButton,
  StyledSmallButtonContainer,
  StyledSmallIconExclamation,
  StyledSmallImgIcon,
} from './styles';
import {StyledSmallButtonWide} from '../ElevatorModal/styles';

const ToggleLabel = (props) => {
  const {offText, onText, enabled} = props;

  const StyledRoot = styled(Paper)({
    height: '3rem',
    width: '11rem',
    display: 'flex',
  });
  const StyledLabel = styled(Paper)({
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '.7rem',
  });
  const StyledSelectedLabel = styled(StyledLabel)(({theme}) => ({
    backgroundColor: '#00FFCC',
    color: theme?.palette?.common?.black ?? '',
  }));

  return (
    <StyledRoot>
      {!enabled ? (
        <StyledSelectedLabel>{offText}</StyledSelectedLabel>
      ) : (
        <StyledLabel>{offText}</StyledLabel>
      )}
      {enabled ? (
        <StyledSelectedLabel>{onText}</StyledSelectedLabel>
      ) : (
        <StyledLabel>{onText}</StyledLabel>
      )}
    </StyledRoot>
  );
};

const JogModal = (props) => {
  const {
    open,
    onClose,
    gripperState,
    jogging,
    notificationState,
    last_mode_user,
    elevatorState,
  } = props;
  const initialState = {
    gripperSuccess: false,
    newCollisions: [],
    moveParams: {},
    resetting: false,
    retracting: false,
    resetSuccess: false,
    resetFailed: null,
    elevatorResetSuccess: false,
    elevatorResetFailed: null,
    retractSuccess: false,
    rebooting: false,
    rebootSuccess: false,
    moveArm: false,
    rebootDialog: null,
    confirmModalVisible: false,
  };
  const [state, setState] = useState(initialState);
  const {
    newCollisions,
    resetting,
    resetSuccess,
    resetFailed,
    elevatorResetSuccess,
    elevatorResetFailed,
    rebooting,
    rebootSuccess,
    moveArm,
    rebootDialog,
    confirmModalVisible,
  } = state;

  const closeCollision = () => {
    const {callback} = moveParams;
    if (callback) {
      callback();
    }
    setState(initialState);
  };
  const disableCollision = () => {
    const {newCollisions, moveParams} = state;
    const {service, callback} = moveParams;

    setState(initialState);
    let {request} = moveParams;
    if (request.allowed_collisions) {
      request.allowed_collisions.push(...newCollisions);
    } else {
      request.allowed_collisions = newCollisions;
    }

    move(service, request, callback);
  };

  const makeJointStates = () => ({
    name: [
      'slider_1',
      'joint_1',
      'joint_2',
      'joint_3',
      'joint_4',
      'joint_5',
      'joint_6',
    ],
    position: [0, 0, 0, 0, 0, 0, 0],
    velocity: [0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2],
  });

  const handleClick = (event, moveArm) => (cb) => {
    if (moveArm) {
      setState((prevState) => ({...prevState, moveArm: true}));
    }
    clearRobotAlarm(() => {
      event(cb);
    });
  };

  const makeMoveRequest = (pos) => ({
    position: pos,
  });

  const move = async (service, request, callback, pos) => {
    const {instance} = props;
    service[instance].setData = request;

    const serviceCallback = (payload) => {
      const result = payload.data;
      console.log('payload: ', payload);
      if (payload.success) {
        const result_error = payload.data.error;
        const {code, description, metadata} = result_error;

        if (code === GOGO_ROBO_ERROR.IN_COLLISION) {
          const metaObj = JSON.parse(metadata);
          setState((prevState) => ({
            ...prevState,
            newCollisions: metaObj.collision_pairs,
            moveParams: {service, request, callback},
          }));
          return;
        } else if (code) {
          window.alert(`Move returned failure! (${instance})\n` + description);
        } else {
          console.log(`Move service result (${instance})`, result);
        }

        if (callback) {
          callback();
        }
        setState((prevState) => ({...prevState, moveArm: false}));
      } else {
        if (callback) {
          callback();
        }
        setState((prevState) => ({...prevState, moveArm: false}));
      }
    };

    await mqtt_ap
      .submit_request(service[instance], serviceCallback, true)
      .catch((error) => {
        console.log('Submit_Request error: ' + error);
      });
  };
  const moveUp = (callback, args) => {
    const pos = args || {z: 0.01252};
    const request = makeMoveRequest(pos);

    move(gogoRoboEff, request, callback, pos);
  };

  const moveLeft = (callback) => {
    const pos = {y: 0.025};
    const request = makeMoveRequest(pos);

    move(gogoRoboEff, request, callback, pos);
  };

  const moveRight = (callback) => {
    const pos = {y: -0.025};
    const request = makeMoveRequest(pos);

    move(gogoRoboEff, request, callback, pos);
  };

  const rollLeft = (callback) => {
    const joints = makeJointStates();
    joints.position[3] = -0.1;

    const request = {
      relative: true,
      joint_states: joints,
    };

    move(gogoRoboJoints, request, callback);
  };

  const rollRight = (callback) => {
    const joints = makeJointStates();
    joints.position[3] = 0.1;

    const request = {
      relative: true,
      joint_states: joints,
    };

    move(gogoRoboJoints, request, callback);
  };

  const moveForward = (callback) => {
    const pos = {x: 0.025};
    const request = makeMoveRequest(pos);

    move(gogoRoboEff, request, callback, pos);
  };

  const moveBackward = (callback) => {
    const pos = {x: -0.025};
    const request = makeMoveRequest(pos);

    move(gogoRoboEff, request, callback, pos);
  };

  const goHome = (callback) => {
    const joints = makeJointStates();
    joints.position = props.homeJoints;

    const request = {
      relative: false,
      joint_states: joints,
    };

    move(gogoRoboJoints, request, callback);
  };

  // FIXME(JRA): GET BACK HERE.
  const goHomeFromAnywhere = (callback) => {
    const request = {
      use_initial_state: false,
      named_joints: COMMANDS.HOME,
      keep_tool: true,
    };

    move(goToNamedJoints, request, callback);
  };
  // FIXME(JRA): GET BACK HERE.
  const goToCleanPosition = (callback) => {
    const request = {
      use_initial_state: false,
      named_joints: COMMANDS.CLEAN1,
      keep_tool: true,
    };

    move(goToNamedJoints, request, callback);
  };

  // NOTE(JS): Remove This After all the roshosts are updated to use Generic
  // Robot Interface.
  const controlGripper = async (callback) => {
    const {instance, gripperState} = props;

    setState((prevState) => ({...prevState, toggleGripper: true}));

    const successCallback = () => {
      store.dispatch(setGripperState(instance, !gripperState));
      const request = {
        tool_name: '',
      };
      callService(swapTool, instance, request, 'Swap Tool');
    };

    await subResult(
      instance,
      controlGripperActionConfig[instance],
      'Toggle Gripper',
      callback,
      successCallback
    );

    console.log('Publishing Goal');
    const goal_request = new MQTTRequest({
      request_type: MQTTRequestType.ROS_PUBLISH,
      ros_topic:
        controlGripperActionConfig[instance].action_topic_prefix + '/goal',
      type: controlGripperActionConfig[instance].action_name_prefix + 'Goal',
      data: {goal: {command: gripperState ? 'close' : 'open'}},
      user: USER_ID,
    });

    try {
      await mqtt_ap.submit_request(goal_request);
      handleOnCloseClick();
    } catch (error) {
      console.log('Submit_Request error: ' + error);
    }
  };

  const rebootClick = (callback) => {
    const onClose = () => {
      setState((prevState) => ({
        ...prevState,
        rebootDialog: null,
      }));
      callback();
    };

    const onReboot = () => {
      setState((prevState) => ({
        ...prevState,
        rebootDialog: null,
      }));
      reboot(callback);
    };

    const component = (
      <Dialog open onClose={onClose}>
        <DialogTitle>
          <FaExclamationTriangle />
          {'\tReboot the System'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure that you want to REBOOT the robot?
          </DialogContentText>
          <br />
          <DialogContentText>
            Rebooting the robot will take 2-3 minutes.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>No, close window</Button>
          <Button onClick={onReboot} color="primary" variant="contained">
            Yes, reboot
          </Button>
        </DialogActions>
      </Dialog>
    );

    setState((prevState) => ({
      ...prevState,
      rebootDialog: component,
    }));
  };

  const reboot = (callback) => {
    const request = {};
    setState((prevState) => ({
      ...prevState,
      rebooting: true,
    }));

    const allCallback = () => {
      setState((prevState) => ({
        ...prevState,
        rebooting: false,
      }));
      callback();
    };

    const successCallback = () => {
      setState((prevState) => ({
        ...prevState,
        rebootSuccess: true,
      }));
      setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          rebootSuccess: false,
        }));
      }, 1500);
    };

    callService(
      robotReboot,
      props.instance,
      request,
      'Reboot Fanuc',
      allCallback,
      successCallback
    );
  };

  const clearRobotAlarm = (callback) => {
    const request = {};
    setState((prevState) => ({
      ...prevState,
      resetting: true,
      resetFailed: false,
    }));

    const allCallback = () => {
      setState((prevState) => ({
        ...prevState,
        resetting: false,
      }));
      callback();
    };

    const successCallback = () => {
      setState((prevState) => ({
        ...prevState,
        resetSuccess: true,
      }));
      setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          resetSuccess: false,
        }));
      }, 1500);
    };

    const errorCallback = () => {
      setState((prevState) => ({
        ...prevState,
        resetFailed: true,
      }));
      setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          resetFailed: null,
        }));
      }, 1500);
    };

    callService(
      robotReset,
      props.instance,
      request,
      'Reset Alarms',
      allCallback,
      successCallback,
      errorCallback
    );
  };

  const handleOnOpenClick = () => {
    setState((prevState) => ({
      ...prevState,
      confirmModalVisible: true,
    }));
  };

  const handleOnCloseClick = () => {
    setState((prevState) => ({
      ...prevState,
      confirmModalVisible: false,
    }));
  };

  const moveElevator = async (position) => {
    const {instance} = props;
    elevatorManual[instance].setData = {};
    try {
      await mqtt_ap.submit_request(elevatorManual[instance]);
    } catch (error) {
      console.log('Submit_Request error: ' + error);
    }
    jogElevator[instance].setData = {
      task: position,
    };
    try {
      await mqtt_ap.submit_request(jogElevator[instance]);
    } catch (error) {
      console.log('Submit_Request error: ' + error);
    }
  };

  const clearElevatorAlarm = () => {
    const request = {};
    setState((prevState) => ({
      ...prevState,
      resetting: true,
      elevatorResetFailed: false,
    }));

    const allCallback = () => {
      setState((prevState) => ({...prevState, resetting: false}));
    };

    const successCallback = () => {
      setState((prevState) => ({...prevState, elevatorResetSuccess: true}));
      setTimeout(() => {
        setState((prevState) => ({...prevState, elevatorResetSuccess: false}));
      }, 1500);
    };

    const errorCallback = () => {
      setState((prevState) => ({...prevState, elevatorResetFailed: true}));
      setTimeout(() => {
        setState((prevState) => ({...prevState, elevatorResetFailed: null}));
      }, 1500);
    };

    callService(
      resetElevator,
      props.instance,
      request,
      'Clear Elevator Alarms',
      allCallback,
      successCallback,
      errorCallback
    );
  };

  const disableElevatorAlarm = (disabled) => {
    const request = {
      user: {
        permissions: DISABLING_LEVEL.UI_USER,
        name: DISABLING_LEVEL.UI_USER,
      },
      component_path: '/agent/elevator_dispenser',
      disabled,
      reason: 'Toggle Disable Elevator',
    };

    callService(
      disableSlot,
      props.instance,
      request,
      'Toggle Disable Elevator'
    );
  };

  const robotAlarms = notificationState.filter(
    (alarm) =>
      alarm.code === 'collision' && alarm.component_path === '/agent/robot_arm'
  );
  const alarmActive = robotAlarms.length > 0;

  const elevatorAlarms = notificationState.filter(
    (alarm) =>
      alarm.code === 'collision' && alarm.component_path === '/agent/elevator'
  );
  const elevatorAlarmActive = elevatorAlarms.length > 0;

  let holdClearMechanismStatus, cycleEStop;
  if (notificationState) {
    holdClearMechanismStatus = Object.keys(notificationState).reduce(
      (alarm, val) => {
        if (notificationState[val].id === ALARM_ID.E_STOPPED)
          alarm[val] = notificationState[val];
        return alarm;
      },
      {}
    );
    cycleEStop = Object.keys(holdClearMechanismStatus).length > 0;
  }

  return (
    <div>
      {jogging && (
        <DraggableDialog
          open={open}
          onClose={onClose}
          paperStyle={{height: '90%', overflow: 'hidden'}}
          aria-labelledby="jog-dialog"
          fullWidth
          maxWidth="lg"
        >
          <MisoDialogTitle id="jog-dialog" onClose={onClose}>
            {'Jog Robot'}
          </MisoDialogTitle>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            {last_mode_user && (
              <StyledRedMessage>{last_mode_user} is jogging!</StyledRedMessage>
            )}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <StyledSmallIconExclamation
                style={{
                  color: 'red',
                  verticalAlign: 'middle',
                  marginRight: '0.5rem',
                }}
              />
              <Typography
                variant="subtitle1"
                style={{fontWeight: 'bold', fontSize: '1.5rem'}}
              >
                WARNING: FLIPPY IS GOING TO MOVE
              </Typography>
            </div>
            <Typography variant="body1" style={{fontSize: '1.5rem'}}>
              Check cameras and verify that the area is clear and safe for
              system movement before proceeding
            </Typography>
          </div>
          <StyledRedMessage>
            Beware! Collision checking between baskets and the end of the arm is
            disabled during UP, LEFT, and RIGHT jogging.
          </StyledRedMessage>
          <StyledRoot>
            <div>
              <Typography
                variant="h4"
                style={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  paddingBottom: '1rem',
                }}
              >
                Robot Arm
              </Typography>
              <div style={{display: 'flex'}}>
                <StyledColumnContainer>
                  <StyledContainer>
                    <StyledButtonContainer>
                      <StyledSmallButtonWide
                        label="Move to Home Position"
                        onClick={handleClick(goHomeFromAnywhere, true)}
                        disabled={alarmActive || moveArm}
                      >
                        <StyledHomeMediumIcon />
                      </StyledSmallButtonWide>
                    </StyledButtonContainer>
                    <StyledButtonContainer>
                      <StyledSmallButtonWide
                        label="Clear Robot Alarms"
                        onClick={handleClick(clearRobotAlarm)}
                        disabled={
                          rebooting || rebootSuccess || robotAlarms.length > 0
                            ? false
                            : true
                        }
                        color={robotAlarms.length > 0 ? 'error' : 'default'}
                        success={resetSuccess}
                        failed={resetFailed}
                      >
                        <StyledSmallIconExclamation />
                      </StyledSmallButtonWide>
                    </StyledButtonContainer>
                    <StyledButtonContainer>
                      <StyledSmallButtonWide
                        label="Gripper"
                        onClick={() => handleOnOpenClick()}
                        disabled={rebooting}
                        disableInProgressProcess
                      >
                        <ToggleLabel
                          offText="closed"
                          onText="opened"
                          enabled={gripperState}
                        />
                      </StyledSmallButtonWide>
                    </StyledButtonContainer>
                  </StyledContainer>
                  <StyledContainer>
                    <div style={{position: 'relative'}}>
                      <StyledButtonContainer>
                        <StyledSmallButtonWide
                          label='Move up 0.5"'
                          onClick={handleClick(moveUp, true)}
                          disabled={alarmActive || moveArm}
                        >
                          <StyledFaLongArrowAltUpIcon />
                        </StyledSmallButtonWide>
                      </StyledButtonContainer>
                      <StyledContainer>
                        <StyledSmallButtonContainer>
                          <StyledSmallButton
                            label="Backward"
                            onClick={handleClick(moveBackward, true)}
                            disabled={alarmActive || moveArm}
                          >
                            <StyledSmallImgIcon src={backward} alt="backward" />
                          </StyledSmallButton>
                          <StyledSmallButton
                            label="Forward"
                            onClick={handleClick(moveForward, true)}
                            disabled={alarmActive || moveArm}
                          >
                            <StyledSmallImgIcon src={forward} alt="forward" />
                          </StyledSmallButton>
                        </StyledSmallButtonContainer>
                        <StyledSmallButtonContainer>
                          <StyledSmallButton
                            label="Left"
                            onClick={handleClick(moveLeft, true)}
                            disabled={alarmActive || moveArm}
                          >
                            <StyledFaLongArrowAltLeft />
                          </StyledSmallButton>
                          <StyledSmallButton
                            label="Right"
                            onClick={handleClick(moveRight, true)}
                            disabled={alarmActive || moveArm}
                          >
                            <StyledFaLongArrowAltRight />
                          </StyledSmallButton>
                        </StyledSmallButtonContainer>
                        <StyledSmallButtonContainer>
                          <StyledSmallButton
                            label="Roll Left"
                            onClick={handleClick(rollLeft, true)}
                            disabled={alarmActive || moveArm}
                          >
                            <StyledFaUndo />
                          </StyledSmallButton>
                          <StyledSmallButton
                            label="Roll Right"
                            onClick={handleClick(rollRight, true)}
                            disabled={alarmActive || moveArm}
                          >
                            <StyledFaRedo />
                          </StyledSmallButton>
                        </StyledSmallButtonContainer>
                      </StyledContainer>
                    </div>
                  </StyledContainer>
                </StyledColumnContainer>
              </div>
            </div>
            <StyledRightContainer>
              <div>
                <Typography
                  variant="h4"
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    paddingBottom: '1rem',
                  }}
                >
                  Elevator
                </Typography>
                <div style={{position: 'relative'}}>
                  <StyledButtonContainer>
                    <StyledSmallButtonWide
                      label="Send to Dump Position"
                      onClick={() => moveElevator(ELEVATOR_COMMANDS.DUMP)}
                      disabled={alarmActive || elevatorState.disabled}
                    ></StyledSmallButtonWide>
                  </StyledButtonContainer>
                  <StyledButtonContainer>
                    <StyledSmallButtonWide
                      label="Send to Home Position"
                      onClick={() => moveElevator(ELEVATOR_COMMANDS.HOME)}
                      disabled={alarmActive || elevatorState.disabled}
                    ></StyledSmallButtonWide>
                  </StyledButtonContainer>
                  <StyledButtonContainer>
                    <StyledSmallButtonWide
                      label="Send to Receive Food Position"
                      onClick={() =>
                        moveElevator(ELEVATOR_COMMANDS.RECEIVE_FOOD)
                      }
                      disabled={alarmActive || elevatorState.disabled}
                    ></StyledSmallButtonWide>
                  </StyledButtonContainer>
                  <StyledButtonContainer>
                    <StyledSmallButtonWide
                      label="Clear Elevator Alarms"
                      onClick={handleClick(clearElevatorAlarm)}
                      disabled={
                        elevatorAlarmActive ||
                        state.rebooting ||
                        state.rebootSuccess
                          ? false
                          : true
                      }
                      color={elevatorAlarmActive ? 'error' : 'default'}
                      success={elevatorResetSuccess}
                      failed={elevatorResetFailed}
                    >
                      <StyledSmallIconExclamation />
                    </StyledSmallButtonWide>
                  </StyledButtonContainer>
                  <StyledButtonContainer>
                    <StyledSmallButtonWide
                      label={
                        elevatorState.disabled
                          ? 'Enable Elevator'
                          : 'Disable Elevator'
                      }
                      onClick={() =>
                        disableElevatorAlarm(!elevatorState.disabled)
                      }
                    />
                  </StyledButtonContainer>
                </div>
              </div>
            </StyledRightContainer>
          </StyledRoot>
        </DraggableDialog>
      )}
      <CollisionModal
        collisions={newCollisions}
        onClose={closeCollision}
        disableCallback={disableCollision}
      />
      {confirmModalVisible && (
        <ConfirmationModal
          open={confirmModalVisible}
          content={
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <StyledSmallIconExclamation style={{color: 'red'}} />
                  <Typography variant="h5" style={{fontWeight: 'bold'}}>
                    WARNING: FLIPPY MAY DROP BASKET
                  </Typography>
                </div>
                <Typography variant="subtitle1">
                  Opening the Gripper may cause the basket to fall onto the
                  floor, or into the hot fryer.
                </Typography>
                <Typography variant="subtitle1">
                  Check cameras and verify that the area is safe and clear, and
                  the fryer barrier is closed before proceeding.
                </Typography>
              </div>
            </>
          }
          onClickNegative={() => controlGripper()}
          rebooting={rebooting}
          onClickPositive={() => handleOnCloseClick()}
          textNegative={
            <>
              <div>
                <StyledSmallIconExclamation
                  style={{
                    color: 'white',
                    verticalAlign: 'middle',
                    marginRight: '0.5rem',
                  }}
                />
                <Typography
                  variant="body1"
                  style={{fontWeight: 'bold', color: 'white'}}
                >
                  CONTINUE TO {gripperState ? 'Close' : 'Open'} Gripper
                </Typography>
              </div>
            </>
          }
          textPositive={
            <>
              <div>
                <StyledFaLongArrowAltLeft
                  style={{
                    color: 'black',
                    verticalAlign: 'middle',
                    marginRight: '0.5rem',
                  }}
                />
                <Typography
                  variant="body1"
                  style={{color: 'black', fontWeight: 'bold'}}
                >
                  GO BACK
                </Typography>
              </div>
            </>
          }
        />
      )}
      {rebootDialog}
    </div>
  );
};

JogModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  instance: PropTypes.string.isRequired,
  gripperState: PropTypes.bool.isRequired,
  jogging: PropTypes.bool.isRequired,
  homeJoints: PropTypes.arrayOf(PropTypes.number).isRequired,
  location: PropTypes.object,
};

export default JogModal;

import {Card, Button} from '@mui/material/';
import React from 'react';
import {styled} from '@mui/material/styles';

const StyledMainDiv = styled(Card)({
  width: '20rem',
  display: 'flex',
  alignItems: 'center',
  padding: '1.2rem',
  justifyContent: 'space-between',
  marginLeft: '0.5rem',
  border: 'solid',
  borderWidth: '0.1875rem',
  borderColor: '#f46c8f',
  backgroundColor: '#f9d4de',
});

const StyledBtnDisable = styled('button')({
  paddingLeft: '1rem',
  backgroundColor: '#f9d4de',
  borderRadius: '0.5rem',
  border: '1px solid rgba(0, 0, 0, 0.23)',
});

const StyledMainText = styled('p')({
  fontSize: '0.85rem',
});

const ModeToggle = ({
  name,
  icon,
  status,
  last_mode_user,
  openJogConfirmation,
  openTroubleshootConfirmation,
}) => (
  <StyledMainDiv>
    <StyledMainText style={{display: 'flex', alignItems: 'center'}}>
      {icon} {/* Assuming the icon is JSX */}
      <span style={{marginLeft: '0.5rem'}}>
        {name} {name === 'Jogging' ? 'Mode: ' : ''}{' '}
        <b>{status && name === 'Jogging' && 'Enabled'}</b>
        <br />
        {last_mode_user && <b>by {last_mode_user}</b>}
      </span>
    </StyledMainText>
    <StyledBtnDisable>
      <Button
        onClick={() => {
          if (name === 'Jogging') {
            openJogConfirmation();
          } else {
            openTroubleshootConfirmation();
          }
        }}
        style={{color: 'black'}}
      >
        {name === 'Jogging' ? 'Disable' : 'Verify With Customer'}
      </Button>
    </StyledBtnDisable>
  </StyledMainDiv>
);

export default ModeToggle;

import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {
  FaCircle,
  FaExclamationTriangle,
  FaEye,
  FaQuestion,
  FaRegCheckCircle,
  FaRegTimesCircle,
} from 'react-icons/fa';

import Spinner from 'react-spinkit';

import * as Icons from '../src/assets/index';
import {setOffsetData, setOffsetUpdated, setGripperState} from './actions';
import {hideLoader, showLoader} from './actions/uiActions';
import cbLogo from './assets/caliburger_logo.svg';
import jbLogo from './assets/jib_logo.svg';
import misoLogo from './assets/miso_logo.svg';
import wcLogo from './assets/white_castle_logo.svg';
import {INSTANCE_PROPERTY} from './constants/arrays';
import {
  ALARM_ID,
  BEHAVIOR_NAME,
  DEFAULT_NUMBER,
  DIAGNOSTICS_STATUS,
  SERIALS,
  TASK_STATUSES,
} from './enums';

import {mqtt_ap, USER_ID} from './ros_init';

import store from './store/index';

import {
  GRAY_ALERT,
  GREEN_ALERT,
  RED_ALERT,
  YELLOW_ALERT,
} from './constants/arrays';
import {AWS_ACCOUNTS} from './aws_iot/accounts';

const {MQTTRequest, MQTTRequestType} = require('./aws_iot/thing_mqtt_request');

export const formatHTMLText = (texts) => {
  // Takes a list of texts, and separates them by the
  // text 'Message (I)'
  let textList = [];
  for (var i = 0; i < texts.length; i++) {
    if (texts[i].length) {
      textList.push('<b>Message ' + (i + 1) + '</b><br/>' + texts[i]);
    }
  }
  return textList.join('<br/><br/>');
};

export function statusToIcon(status, className) {
  switch (status) {
    case TASK_STATUSES.SCHEDULED:
      return (
        <Spinner
          data-tip="Scheduled"
          name="folding-cube"
          fadeIn="none"
          className={className}
        />
      );
    case TASK_STATUSES.PLANNING:
      return (
        <Spinner
          data-tip="Planning"
          name="chasing-dots"
          fadeIn="none"
          className={className}
        />
      );
    case TASK_STATUSES.PLANNED:
      return <FaCircle data-tip="Planned" className={className} />;
    case TASK_STATUSES.EXECUTING:
      return (
        <Spinner
          data-tip="Executing"
          name="pulse"
          fadeIn="none"
          className={className}
        />
      );
    case TASK_STATUSES.VALIDATING:
      return <FaEye data-tip="Validating" className={className} />;
    default:
      // NOTE: If there's no action, there will be soon. Show Scheduled.
      return (
        <Spinner
          data-tip="Scheduled"
          name="folding-cube"
          fadeIn="none"
          className={className}
        />
      );
  }
}

// TODO(NK): Load from SALT or other fleet system
// TODO(NK): Validate format for these lists
// Dictionary for various installation info.
const installDict = {
  jb93: {
    company: 'Jack In The Box',
    name: 'jb93',
    site_id: 'jb93',
    location: 'Chula Vista, CA',
    serial: 'Flippy3-3',
    deviceid: '00190f48c535',
    ip_address: '10.143.1.50',
    logo: jbLogo,
    slotCount: 8,
    autobasketCount: 3,
  },
  wcstls65: {
    company: 'White Castle',
    name: 'wcstls65',
    site_id: 'wcstls65',
    location: 'Belleville, IL',
    serial: 'Flippy3-1',
    deviceid: '00190f48c622',
    ip_address: '10.143.1.18',
    logo: wcLogo,
    slotCount: 6,
    autobasketCount: 2,
  },
  wcchic74: {
    company: 'White Castle',
    name: 'wcchic74',
    site_id: 'wcchic74',
    location: 'Mokena, IL',
    serial: 'Flippy3-2',
    deviceid: '00190f48c6e6',
    ip_address: '10.143.1.34',
    logo: wcLogo,
    slotCount: 6,
    autobasketCount: 2,
  },
  wcstls44: {
    company: 'White Castle',
    name: 'wcstls44',
    site_id: 'wcstls44',
    location: 'St. Louis, MO',
    serial: 'Flippy3-4',
    deviceid: '00190f48c6b5',
    ip_address: '10.143.1.130',
    logo: wcLogo,
    slotCount: 6,
    autobasketCount: 2,
  },
  mgalpha3: {
    company: 'Miso Robotics',
    name: 'mgalpha3',
    site_id: 'mgalpha3',
    location: 'Pasadena, CA',
    serial: 'F3-Test-3',
    deviceid: '00190f48c614',
    ip_address: '10.142.2.50',
    logo: misoLogo,
    slotCount: 6,
    autobasketCount: 2,
  },
  mgalpha4: {
    company: 'Miso Robotics',
    name: 'mgalpha4',
    site_id: 'mgalpha4',
    location: 'Pasadena, CA',
    serial: 'F3-Test-4',
    deviceid: '00190f48c5e6',
    ip_address: '10.142.2.66',
    logo: misoLogo,
    slotCount: 8,
    autobasketCount: 3,
  },
};
export const hosts = Object.keys(installDict);
export const hostsVal = Object.values(installDict);

export const getDictInfo = () => {
  let dict_info = [];
  for (const instance in installDict) {
    dict_info.push({
      host: instance,
      ...installDict[instance],
    });
  }
  return dict_info;
};

export const filterSearch = (statuses, searchValue) => {
  if (searchValue !== null) {
    return statuses.filter(({site_id}) => {
      return site_id.toLowerCase().includes(searchValue.toLowerCase());
    });
  } else return statuses;
};

export const sortList = (locationId) => {
  const sortedList = locationId.sort((a, b) =>
    a.site_id.localeCompare(b.site_id)
  );
  return sortedList;
};

export const mergeInstanceWithStatus = (
  instanceStatuses = [],
  search,
  sortAlphabetically,
  sortSeverity
) => {
  const dict_information = getDictInfo();
  let statuses = [];
  for (const dict_info of dict_information) {
    let _info = cleanObject(dict_info, undefined, INSTANCE_PROPERTY);
    let _device_id = _info.site_id + '-' + _info.deviceid;
    for (const instance of instanceStatuses) {
      if (instance.device_id === _device_id) {
        _info = {..._info, status: instance.info};
        break;
      }
    }
    statuses.push(_info);
  }

  let statusList = statuses;
  if (sortAlphabetically) {
    statusList = sortList(statuses, sortAlphabetically);
  }
  if (sortSeverity) {
    statusList.forEach((newStatus) => {
      const {severityStatus} = getAlertDesign(newStatus.status);
      newStatus.severityStatus = severityStatus;
    });
    statusList.sort((a, b) => b.severityStatus - a.severityStatus);
  }
  if (search !== '') {
    statusList = filterSearch(statuses, search);
  }

  return statusList;
};

/**
 * @param {Array<{site_id: string, info: object}>} instancesStatus
 * @param {object} selectedInstance
 */
export const getInstanceStatus = (instancesStatus = [], selectedInstance) => {
  let selected_instance = {site_id: '', info: ''};
  let _selectedInstance =
    selectedInstance.site_id + '-' + selectedInstance.deviceid;
  for (const instanceStatus of instancesStatus) {
    if (instanceStatus.device_id === _selectedInstance) {
      selected_instance = instanceStatus;
    }
  }
  return selected_instance;
};

export function getInfo(instance) {
  return {
    company: 'Unknown',
    name: 'Unknown',
    location: 'Unknown',
    site_id: 'Unknown',
    serial: 0,
    deviceid: 'Unknown',
    logo: null,
    mapping: {
      errors: [],
      mapping: {},
    },
    ...installDict[instance],
  };
}

export const rosToMoment = (time) => {
  if (!time) {
    return moment.invalid();
  }

  const secs = time.secs + time.nsecs * 10e-10;
  if (secs) {
    return moment.unix(secs);
  } else {
    return moment.invalid();
  }
};

export const callService = async (
  service,
  instance,
  request,
  serviceName,
  callback = () => {},
  successCallback = () => {},
  errorCallback = () => {}
) => {
  store.dispatch(showLoader());
  service[instance].setData = request;
  console.log(
    `Call service:\n- ${JSON.stringify(
      service
    )}\n- ${instance}\n- ${JSON.stringify(request)}\n- ${serviceName}`
  );
  const serviceCallback = (payload) => {
    store.dispatch(hideLoader());
    const result = payload.data;
    if (payload.success) {
      const result_error = payload.data.error;
      if (
        serviceName === 'Update Offset' ||
        serviceName === 'Run Behavior Test' ||
        serviceName === 'Run Auto Offset Tuning Test'
      ) {
        if (result.success) {
          window.alert(
            `${serviceName} success! (${instance})\n` +
              (result.message ? result.message : result.description)
          );
        }
        store.dispatch(
          setOffsetUpdated(instance, {
            success: result.success ? result.success : false,
            description: result.message ? result.message : result.description,
            from: serviceName,
          })
        );
      }
      if (
        serviceName === 'Revert Offset Tuning' ||
        serviceName === 'Revert Offset Test' ||
        serviceName === 'Abort Test'
      ) {
        store.dispatch(
          setOffsetUpdated(instance, {
            success: result.success,
            description: result.message ? result.message : result.description,
            from: serviceName,
          })
        );
        if (
          serviceName === 'Revert Offset Tuning' ||
          serviceName === 'Revert Offset Test'
        ) {
          store.dispatch(
            setOffsetData(instance, {
              waypoint: {
                name: 'Source/Target',
                value: '',
              },
              fryerNumber: {
                name: 'Fryer Number',
                value: '',
              },
              slotInfo: {
                name: 'Left/Right Slot',
                value: '',
              },
              behaviorType: {
                name: 'Frying/Hanging Slot',
                value: '',
              },
              username: ' ',
              issue: ' ',
              xyz: {
                X: 0.0,
                Y: 0.0,
                Z: 0.0,
              },
            })
          );
        }
        // window.alert(`${serviceName} Successful!`);
      }
      if (serviceName === 'Get Gripper State') {
        store.dispatch(
          setGripperState(instance, result.state === 'open' ? true : false)
        );
      }
      if (result_error && result_error.code) {
        window.alert(
          `${serviceName} returned failure! (${instance})\n` +
            result_error.description
        );
        errorCallback();
      } else if (result.success === false) {
        window.alert(
          `${serviceName} was unsuccessful! (${instance})\n` +
            (result.message !== undefined ? result.message : result.description)
        );
        errorCallback();
      } else {
        if (successCallback) {
          successCallback();
        }
      }
      if (callback) {
        callback();
      }
    } else {
      const payload_error = payload.error;
      if (
        !(
          payload_error.includes('basket_id') &&
          serviceName.includes('Update Offset')
        ) &&
        !payload_error.includes('/robot_arm/abort_behavior')
      ) {
        window.alert(`${serviceName} failed! (${instance})\n` + payload_error);
      } else if (serviceName.includes('Update Offset')) {
        store.dispatch(
          setOffsetUpdated(instance, {
            success: false,
            description: payload_error,
            from: serviceName,
          })
        );
      }
      errorCallback();
      if (callback) {
        callback();
      }
    }
  };
  await mqtt_ap
    .submit_request(service[instance], serviceCallback, true)
    .catch((error) => {
      console.log('Submit_Request error: ' + error);
    });
};

export const subResult = async (
  instance,
  action_config,
  serviceName,
  callback,
  successCallback
) => {
  let success = false;
  // subscribe result with successCallback
  const resultRequest = new MQTTRequest({
    request_type: MQTTRequestType.ROS_SUBSCRIBE,
    ros_topic: action_config.action_topic_prefix + '/result',
    type: action_config.action_name_prefix + 'Result',
    data: {},
    user: USER_ID,
  });
  const resultCallback = function (result) {
    if (result.result.success) {
      success = true;
      if (successCallback) {
        successCallback();
      }
    } else {
      window.alert(`${serviceName} was unsuccessful! (${instance})\n`);
    }
    if (callback) {
      callback();
    }
  };
  await mqtt_ap
    .submit_request(resultRequest, resultCallback, true)
    .catch((error) => {
      console.log('Submit_Request error: ' + error);
    });
  // NOTE(ST): We can subscribe to status but it does not seem necessary
  // as the result already returns a flag about the status of action.

  // const statusRequest = new MQTTRequest({
  //   request_type: MQTTRequestType.ROS_SUBSCRIBE,
  //   ros_topic: action_config.action_topic_prefix + '/status',
  //   type: 'actionlib_msgs/GoalStatusArray',
  //   data: {},
  //   user: USER_ID,
  // });
  // const statusCallback = function (status_array) {
  //   status = status_array.status_list;
  // };
  // await mqtt_ap
  //   .submit_request(statusRequest, statusCallback, true)
  //   .catch((error) => {
  //     console.log('Submit_Request error: ' + error);
  //   });

  setTimeout(checkReceivedGoal, 15000);

  function checkReceivedGoal() {
    if (!success) {
      window.alert(`${serviceName} was never executed! (${instance})\n`);
      if (callback) {
        callback();
      }
    }
  }
};

/**
 * Convert a Miso Variant msg into a JS Object.
 *
 */
export const transformVariant = function (variant) {
  const obj = {};
  variant.bools.forEach((e) => {
    obj[e.name] = e.value;
  });
  variant.ints.forEach((e) => {
    obj[e.name] = e.value;
  });
  variant.strs.forEach((e) => {
    obj[e.name] = e.value;
  });
  variant.doubles.forEach((e) => {
    obj[e.name] = e.value;
  });
  variant.uuids.forEach((e) => {
    obj[e.name] = e.value;
  });
  return obj;
};

/**
 * Return ID array as base64 string.
 * This is used to convert a list of Numbers into a CBOR-style base64
 * encoding.
 *
 * @param {Array} uint8Array: Array of Numbers between 0 and 255.
 * @return {String} Base64 version of the id.
 */
export const uint8ToBase64 = function (uint8Array) {
  // NOTE(JRA): It is CRUCIAL we use String.fromCharCode, otherwise
  // this will be converted to a UTF-16 string, which will not work
  // with btoa, and will give the wrong CBOR representation.
  //
  // We cannot use any TextDecode/TextEncode like most recommended
  // solutions.
  const rawStringChars = String.fromCharCode(...uint8Array);
  return btoa(rawStringChars);
};

/**
 * Convert a basket's base64 representation to the basket ID.
 * This is the same thing as the first byte (as a Number)
 *
 * @param {String} base64String: Base64 String representing the Obj UUID of
 *  basket.
 * @return {Number} Basket ID
 */
export const basketIDFromUUID = function (base64String) {
  return new Buffer(base64String, 'base64')[0];
};

var component = Icons;

export const IconComponent = (props) => {
  var SpecificIcon = component[props.icon2] || component['CauliflowerBites'];

  return (
    <SpecificIcon
      color={props.color}
      width={props.width}
      height={props.height}
    />
  );
};

IconComponent.propTypes = {
  icon2: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export const transformToSnakeCase = (camelCase) => {
  return camelCase
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((x) => x.toLowerCase())
    .join('_');
};

export const parseString = (string) => {
  // Split the string by "/"
  let parts = string.split('/');

  // Get the middle string (second to last element)
  let interfaceName = parts[parts.length - 2];

  // Get the number after the last "/"
  let lastIndex = string.lastIndexOf('/');
  let slotNo = string.substring(lastIndex + 1);

  return [interfaceName, slotNo];
};

export const convertComponentPathToReadableText = (
  componentPath,
  autobinNumber
) => {
  const isSingleSlot = componentPath.includes('/slot/');
  const isFryerSlotPair = componentPath.includes('fryer_slots');
  const isHangerSlotPair = componentPath.includes('hanger_slots');
  const isWholeFryer = componentPath.includes('/fryer/');
  if (isSingleSlot) {
    let newComponentPath = componentPath.replace('/slot/', '');
    let [interfaceName, slotNo] = parseString(newComponentPath);
    let name =
      interfaceName === 'interface'
        ? 'autobasket drawer'
        : interfaceName === 'hanger'
          ? 'on fryer shelf'
          : interfaceName === 'fryer'
            ? 'in slot'
            : interfaceName === 'gripper'
              ? 'in gripper'
              : interfaceName;
    let slot;
    if (interfaceName === 'interface') {
      slot = getAutobasketText(slotNo, autobinNumber);
    } else {
      slot = slotNo;
    }
    return interfaceName === 'interface'
      ? 'in' + ' ' + slot.toLowerCase() + ' ' + name
      : interfaceName === 'gripper'
        ? name
        : name + ' ' + slot;
  } else if (isHangerSlotPair) {
    let newComponentPath = componentPath
      .replace('/hanger_slots', '')
      .replace('/', '');
    let [interfaceName, slotNo] = parseString(newComponentPath);
    return 'Shelf' + ' ' + slotNo;
  } else if (isFryerSlotPair) {
    let newComponentPath = componentPath
      .replace('/fryer_slots', '')
      .replace('/', '');
    let [interfaceName, slotNo] = parseString(newComponentPath);
    return interfaceName + ' ' + slotNo;
  } else if (isWholeFryer) {
    let newComponentPath = componentPath.replace('/', '');
    let [interfaceName, slotNo] = parseString(newComponentPath);
    return interfaceName + ' ' + slotNo;
  } else {
    return '';
  }
};

const getAutobasketText = (slotNo, autobinNumber) => {
  let slotText;
  if (slotNo === '1') {
    slotText = 'left';
  } else if (slotNo === '2' && autobinNumber === 2) {
    slotText = 'right';
  } else if (slotNo === '2' && autobinNumber === 3) {
    slotText = 'middle';
  } else if (slotNo === '3') {
    slotText = 'right';
  } else {
    slotText = slotNo; // Fallback if slotNo isn't 1, 2, or 3
  }
  return slotText;
};

// Condition to offset form submission
export const offsetFormChecker = (payload) => {
  let isAbleToSubmitForm = payload.map((load) => {
    let bool;
    if (Array.isArray(load)) {
      if (load.length > 0) bool = true;
      else bool = false;
    } else if (typeof load === 'object' && !Array.isArray(load)) {
      if (load.value !== '') {
        bool = true;
      } else if (load.value === '') {
        bool = false;
      }
    } else if (typeof load === 'string') {
      if (load !== '') bool = true;
      else bool = false;
    }
    return bool;
  });
  return isAbleToSubmitForm;
};

// Custom static checker for offset direction
export const isOffsetXYZRequired = (payload) => {
  let required = true;
  if (payload.X !== 0 || payload.Y !== 0 || payload.Z !== 0) required = false;
  return required;
};

// For tooltip info X,Y,Z axis
export const tooltipInfo = (dataInfo) => {
  let info = '';
  if (dataInfo === 'X')
    info =
      'This axis runs along the length of a single fryer bay. </br>+x points towards the back of the fryer bay while </br>-x points towards the front. </br>Unit measure is in meters (m).';
  else if (dataInfo === 'Y')
    info =
      'This axis runs along the front plane of the fryers. </br>+y will move the offset left while -y will move </br>the offset to the right. </br>Unit measure is in meters (m).';
  else if (dataInfo === 'Z')
    info =
      'This axis runs along the height of the fryers. </br>+z will move the offset up while -z will move </br>the offset down. </br>Unit measure is in meters (m).';
  else info = '';
  return info;
};

/**
 * Check if the the id is exist ALARM_ID
 * @param {number} id
 * @returns {boolean}
 */
export const isAlarmId = (id) => {
  let _id = Object.values(ALARM_ID).find((_id) => _id === id);
  if (_id === 0) _id = String(_id);
  return _id ? true : false;
};

function toKebab(string) {
  return string
    .split('')
    .map((letter) => {
      if (/[A-Z]/.test(letter)) {
        return ` ${letter.toLowerCase()}`;
      }
      return letter;
    })
    .join('');
}

export const transformToCamelCase = (string) => {
  return toKebab(string)
    .split('_')
    .map((word, index) => {
      if (index === DEFAULT_NUMBER.ZERO) return word;
      return (
        word.slice(DEFAULT_NUMBER.ZERO, DEFAULT_NUMBER.ONE).toUpperCase() +
        word.slice(DEFAULT_NUMBER.ONE).toLowerCase()
      );
    })
    .join('');
};

export const transformBatchOrderMsg = (batchOrderMsg) => {
  const oldPropertyName = Object.keys(batchOrderMsg).sort();
  const newPropertyName = [];

  oldPropertyName.map((order) => {
    return newPropertyName.push(transformToCamelCase(order));
  });

  const renameProperty = (obj, newPropertyName, oldPropertyName) => {
    const newObj = {};
    newPropertyName.forEach((prop, i) => {
      newObj[prop] = obj[oldPropertyName[i]];
    });
    return newObj;
  };
  return [renameProperty(batchOrderMsg, newPropertyName, oldPropertyName)];
};

export const getAwsAccountObj = (instance) => {
  let serial;
  if (instance) {
    serial = getInfo(instance).serial;
    if (serial.startsWith(SERIALS.FLIPPY3_QA)) {
      return AWS_ACCOUNTS['F3-QA'];
    } else if (serial.startsWith(SERIALS.FLIPPY3_PROD)) {
      return AWS_ACCOUNTS['F3-PROD'];
    } else {
      console.log(`Encountered invalid serial: ${serial}`);
      return;
    }
  }
};

/**
 * Get specific fryer slot from slotHistory object
 * @param {Array<object>} slotHistory
 * @param {Number} slotId
 * @returns {<object>} returns slot_history object on that index
 */
export const getSpecificSlotHistory = (slotHistory, slotId) => {
  if (slotHistory) {
    let slotHistoryLength = slotHistory.length;
    for (let index = 0; index < slotHistoryLength; index++) {
      if (slotHistory[index].fryer_slot_id === slotId) {
        return slotHistory[index];
      }
    }
  }
};

export const transformSnakeToTitle = (string) => {
  let text = string;
  return text.replace(/^_*(.)|_+(.)/g, (s, c, d) =>
    c ? c.toUpperCase() : ' ' + d.toUpperCase()
  );
};

/**
 * Remove or filter unwanted key and value on the object
 * @param {object} object
 * @param {Array<string>} filter these are the filtered key, val that you want to filter
 */
export const cleanObject = (obj, filter = [], includes = []) => {
  let _obj = {};
  for (let _key in obj) {
    if (includes.length) {
      const is_include = includes.find((key) => key === _key);
      if (is_include) _obj[_key] = obj[_key];
      continue;
    }
    const is_fitered = filter.find((key) => key === _key);
    if (is_fitered) continue;
    _obj[_key] = obj[_key];
  }
  return _obj;
};

export const toFormalCase = (text) => {
  return text.replace(/[_-]/g, ' ');
};

/**
 * Cut the text if max_length is text length is greater than the max_length
 * @param {string} text
 * @param {number} max_length
 */
export const smartCut = (text, max_length) => {
  let _text = text;
  if (_text.length > max_length) {
    _text = `${_text.substring(0, max_length)}...`;
  }
  return _text;
};

/**
 * Convert literal string to pascal case
 * @param {string} text
 * @returns {string}
 */
export const toPascalCase = (text) => {
  const texts = (toFormalCase(text) || '').split(' ');
  let formatted_text = '';

  for (const n in texts) {
    formatted_text +=
      texts[n].substring(0, 1).toUpperCase() +
      texts[n].substring(1).toLowerCase();

    if (parseInt(n) + 1 !== texts.length) formatted_text += ' ';
  }

  return formatted_text;
};

/**
 * @param {object} statuses
 * @returns {boolean}
 */
export const checkInstanceStatus = (statuses = {}) => {
  for (const statusName in statuses.status) {
    if (statuses.status[statusName].status > 0) {
      return true;
    }
  }
  return false;
};

/**
 * @param {boolean} status
 * @return {JSX.Element}
 */
export const getStatus = (status, category, style) => {
  if (status === null) return null;
  const category_list = ['collision', 'e_stop', 'fryer_door'];
  let prev_categories = false;
  if (category.length > 0) {
    prev_categories = category.every((elem) => category_list.includes(elem));
  }
  return status === 0 || status === undefined ? (
    <FaRegCheckCircle
      style={{
        fontSize: '1rem',
        color: 'green',
        padding: '0rem',
        margin: '0rem',
        ...style,
      }}
    />
  ) : status === 2 || (status === 1 && prev_categories) ? (
    <FaRegTimesCircle
      style={{
        fontSize: '1rem',
        color: 'red',
        padding: '0rem',
        margin: '0rem',
        ...style,
      }}
    />
  ) : status === 3 ? (
    <FaRegTimesCircle
      style={{
        fontSize: '1rem',
        color: 'grey',
        padding: '0rem',
        margin: '0rem',
        ...style,
      }}
    />
  ) : status === 255 ? (
    <FaQuestion
      style={{
        fontSize: '1rem',
        color: 'grey',
        padding: '0rem',
        margin: '0rem',
        ...style,
      }}
    />
  ) : (
    <FaExclamationTriangle
      style={{
        fontSize: '1rem',
        color: 'yellow',
        background:
          'linear-gradient(black,black) center bottom/20% 84% no-repeat',
        filter: 'drop-shadow(0.1875rem 0.3125rem 0.125rem rgb(0 0 0 / 0.4))',
        stroke: 'black',
        strokeWidth: '1rem',
        padding: '0rem',
        margin: '0rem',
        ...style,
      }}
    />
  );
};

/**
 * Get the style alert
 * @return {{Icon: JSX.Element, alertStyle: object, severityStatus: number}}
 */
export const getAlertDesign = (info) => {
  let _design = GRAY_ALERT;

  if (!info) return _design;

  if (
    (info.safety_sensor.status === undefined ||
      info.safety_sensor.status === null ||
      info.safety_sensor.status === DIAGNOSTICS_STATUS.OK) &&
    (info.robot_arm.status === null ||
      info.robot_arm.status === DIAGNOSTICS_STATUS.OK) &&
    (info.dispenser.status === null ||
      info.dispenser.status === DIAGNOSTICS_STATUS.OK ||
      info.dispenser.status === DIAGNOSTICS_STATUS.STALE) &&
    (info.fryers.status === undefined ||
      info.fryers.status === null ||
      info.fryers.status === DIAGNOSTICS_STATUS.OK) &&
    (info.autobasket.status === null ||
      info.autobasket.status === DIAGNOSTICS_STATUS.OK) &&
    (info.robot_behaviors.status === null ||
      info.robot_behaviors.status === DIAGNOSTICS_STATUS.OK) &&
    (info.smart_chef.status === undefined ||
      info.smart_chef.status === DIAGNOSTICS_STATUS.OK) &&
    (info.logging.status === null ||
      info.logging.status === DIAGNOSTICS_STATUS.OK) &&
    (info.UI_status.status === null ||
      info.UI_status.status === DIAGNOSTICS_STATUS.OK) &&
    (info.perception.status === undefined ||
      info.perception.status === null ||
      info.perception.status === DIAGNOSTICS_STATUS.OK) &&
    (info.planning_failures.status === null ||
      info.planning_failures.status === DIAGNOSTICS_STATUS.OK) &&
    (info.execution_failures.status === null ||
      info.execution_failures.status === DIAGNOSTICS_STATUS.OK) &&
    (info.misgrabs.status === null ||
      info.misgrabs.status === DIAGNOSTICS_STATUS.OK) &&
    (info.fryer_slots_disabled_ratio.status === undefined ||
      info.fryer_slots_disabled_ratio.status === null ||
      info.fryer_slots_disabled_ratio.status === DIAGNOSTICS_STATUS.OK) &&
    (info.shelf_slots_disabled_ratio.status === undefined ||
      info.shelf_slots_disabled_ratio.status === null ||
      info.shelf_slots_disabled_ratio.status === DIAGNOSTICS_STATUS.OK) &&
    (info.gripper_slot_disabled_ratio.status === undefined ||
      info.gripper_slot_disabled_ratio.status === null ||
      info.gripper_slot_disabled_ratio.status === DIAGNOSTICS_STATUS.OK)
  ) {
    _design = GREEN_ALERT;
  } else if (
    info.fryers.status === DIAGNOSTICS_STATUS.ERROR ||
    info.robot_arm.status === DIAGNOSTICS_STATUS.ERROR ||
    info.autobasket.status === DIAGNOSTICS_STATUS.ERROR ||
    info.dispenser.status === DIAGNOSTICS_STATUS.ERROR ||
    info.robot_behaviors.status === DIAGNOSTICS_STATUS.ERROR ||
    info.smart_chef.status === DIAGNOSTICS_STATUS.ERROR ||
    info.logging.status === DIAGNOSTICS_STATUS.ERROR ||
    info.UI_status.status === DIAGNOSTICS_STATUS.ERROR ||
    info.UI_status.status === DIAGNOSTICS_STATUS.WARN ||
    (info.perception.status !== null &&
      info.perception.status === DIAGNOSTICS_STATUS.ERROR) ||
    (info.planning_failures.status !== null &&
      info.planning_failures.status === DIAGNOSTICS_STATUS.ERROR) ||
    (info.execution_failures.status !== null &&
      info.execution_failures.status === DIAGNOSTICS_STATUS.ERROR) ||
    (info.misgrabs.status !== null &&
      info.misgrabs.status === DIAGNOSTICS_STATUS.ERROR) ||
    (info.fryer_slots_disabled_ratio.status !== null &&
      info.fryer_slots_disabled_ratio.status === DIAGNOSTICS_STATUS.ERROR) ||
    (info.shelf_slots_disabled_ratio.status !== null &&
      info.shelf_slots_disabled_ratio.status === DIAGNOSTICS_STATUS.ERROR) ||
    (info.gripper_slot_disabled_ratio.status !== null &&
      info.gripper_slot_disabled_ratio.status === DIAGNOSTICS_STATUS.ERROR)
  ) {
    _design = RED_ALERT;
  } else if (
    (info.safety_sensor.status !== null &&
      (info.safety_sensor.status === DIAGNOSTICS_STATUS.ERROR ||
        info.safety_sensor.status === DIAGNOSTICS_STATUS.WARN)) ||
    info.safety_sensor.status === DIAGNOSTICS_STATUS.STALE ||
    info.safety_sensor.status === DIAGNOSTICS_STATUS.UNKNOWN ||
    info.robot_arm.status === DIAGNOSTICS_STATUS.WARN ||
    info.robot_arm.status === DIAGNOSTICS_STATUS.STALE ||
    info.robot_arm.status === DIAGNOSTICS_STATUS.UNKNOWN ||
    info.autobasket.status === DIAGNOSTICS_STATUS.STALE ||
    info.autobasket.status === DIAGNOSTICS_STATUS.WARN ||
    info.autobasket.status === DIAGNOSTICS_STATUS.UNKNOWN ||
    info.dispenser.status === DIAGNOSTICS_STATUS.STALE ||
    info.dispenser.status === DIAGNOSTICS_STATUS.WARN ||
    info.dispenser.status === DIAGNOSTICS_STATUS.UNKNOWN ||
    info.fryers.status === DIAGNOSTICS_STATUS.STALE ||
    info.fryers.status === DIAGNOSTICS_STATUS.WARN ||
    info.fryers.status === DIAGNOSTICS_STATUS.UNKNOWN ||
    info.robot_behaviors.status === DIAGNOSTICS_STATUS.WARN ||
    info.robot_behaviors.status === DIAGNOSTICS_STATUS.UNKNOWN ||
    info.smart_chef.status === DIAGNOSTICS_STATUS.WARN ||
    info.smart_chef.status === DIAGNOSTICS_STATUS.UNKNOWN ||
    info.perception.status === DIAGNOSTICS_STATUS.STALE ||
    info.perception.status === DIAGNOSTICS_STATUS.WARN ||
    info.perception.status === DIAGNOSTICS_STATUS.UNKNOWN ||
    info.logging.status === DIAGNOSTICS_STATUS.WARN ||
    info.logging.status === DIAGNOSTICS_STATUS.UNKNOWN ||
    info.UI_status.status === DIAGNOSTICS_STATUS.STALE ||
    info.UI_status.status === DIAGNOSTICS_STATUS.UNKNOWN ||
    info.planning_failures.status === DIAGNOSTICS_STATUS.STALE ||
    info.planning_failures.status === DIAGNOSTICS_STATUS.WARN ||
    info.planning_failures.status === DIAGNOSTICS_STATUS.UNKNOWN ||
    info.execution_failures.status === DIAGNOSTICS_STATUS.STALE ||
    info.execution_failures.status === DIAGNOSTICS_STATUS.WARN ||
    info.execution_failures.status === DIAGNOSTICS_STATUS.UNKNOWN ||
    info.misgrabs.status === DIAGNOSTICS_STATUS.STALE ||
    info.misgrabs.status === DIAGNOSTICS_STATUS.WARN ||
    info.misgrabs.status === DIAGNOSTICS_STATUS.UNKNOWN ||
    info.fryer_slots_disabled_ratio.status === DIAGNOSTICS_STATUS.STALE ||
    info.fryer_slots_disabled_ratio.status === DIAGNOSTICS_STATUS.WARN ||
    info.fryer_slots_disabled_ratio.status === DIAGNOSTICS_STATUS.UNKNOWN ||
    info.shelf_slots_disabled_ratio.status === DIAGNOSTICS_STATUS.STALE ||
    info.shelf_slots_disabled_ratio.status === DIAGNOSTICS_STATUS.WARN ||
    info.shelf_slots_disabled_ratio.status === DIAGNOSTICS_STATUS.UNKNOWN ||
    info.gripper_slot_disabled_ratio.status === DIAGNOSTICS_STATUS.STALE ||
    info.gripper_slot_disabled_ratio.status === DIAGNOSTICS_STATUS.WARN ||
    info.gripper_slot_disabled_ratio.status === DIAGNOSTICS_STATUS.UNKNOWN
  ) {
    _design = YELLOW_ALERT;
  } else if (
    info.robot_behaviors.status === DIAGNOSTICS_STATUS.STALE ||
    info.smart_chef.status === DIAGNOSTICS_STATUS.STALE ||
    info.logging.status === DIAGNOSTICS_STATUS.STALE
  ) {
    _design = RED_ALERT;
  }
  return _design;
};

/**
 * Convert snake case into readable string
 * @param {string} name
 */
export const toReadableString = (name) => {
  let string = '';
  if (typeof name === 'string') {
    if (name === 'e_stop') {
      string = name.replace(/_/g, '-');
    } else {
      string = name.replace(/_/g, ' ');
    }
    return string[0].toUpperCase() + string.slice(1).toLowerCase();
  }
  return name;
};

/**
 * get fryerId
 * @param {'left' | 'right'} offset
 * @param {number} value
 */
export const getFryerId = (offset, value) => {
  let _value = parseInt(value);
  _value *= 2;
  if (offset === 'left') _value -= 1;
  return _value;
};

/**
 * get behaviorName
 * @param {'source'| 'target'} sourceTarget
 * @param {'fryer' | 'shelf'} fryHang
 */
export const getBehaviorName = (sourceTarget, fryHang) => {
  let value = '';

  if (sourceTarget === BEHAVIOR_NAME.SOURCE) {
    value = fryHang + '_to_tune_grab_to_' + fryHang;
  } else if (sourceTarget === BEHAVIOR_NAME.TARGET) {
    value = fryHang + '_to_tune_' + fryHang + '_to_' + fryHang;
  }

  return value;
};

/**
 * Compare two objects in any type of properties and compare its inequality
 * @param {object} prevObj
 * @param {object} currObj
 * @returns {boolean}
 */
export const compareObj = (prevObj, currObj) => {
  const prevKeys = Object.keys(prevObj);
  const prevVals = Object.values(prevObj);
  const currKeys = Object.keys(currObj);

  let status = true;
  if (prevKeys.length !== currKeys.length) return false;

  for (let pk in prevKeys) {
    const currVal = String(currObj[prevKeys[pk]]);
    if (
      !currVal ||
      (prevKeys[pk] === currKeys[pk] && String(prevVals[pk]) !== currVal)
    ) {
      status = false;
    }
  }
  return status;
};

/**
 * Remove circular object reference and return the rest
 * @param {object} obj
 * @returns {object}
 */
const nonCircularStringify = (obj) => {
  let temp = new Set();
  return JSON.stringify(obj, function (key, val) {
    if (typeof val === 'object' && val) {
      if (temp.has(val)) return;
      temp.add(val);
    }
    return val;
  });
};

/**
 * Smart Compare is utility function where it compares the previous and new property of a component
 * @param {Array<string>} checks serve as the property what you want to hookup if gets update
 * @return {boolean} Returns true if has no change return false if has change
 */
export const smartCompare = (checks) => {
  /**
   * @param {object} prev
   * @param {object} next
   * @returns {boolean}
   */
  return (prev, next) => {
    for (const check of checks) {
      let _check = check.includes('.') ? check.split('.') : check,
        flag = false,
        _prev = prev,
        _next = next;

      if (Array.isArray(_check)) {
        for (const prop of _check) {
          _prev = _prev[prop];
          _next = _next[prop];
        }
      } else {
        _prev = prev[check];
        _next = next[check];
      }

      if (typeof _prev === 'object') {
        flag = nonCircularStringify(_prev) === nonCircularStringify(_next);
        // } else if (typeof _prev === 'function') {
        //   flag = String(_prev) === String(_next);
      } else {
        flag = _prev === _next;
      }

      if (!flag) return false;
    }
    return true;
  };
};

export const addAlarmFields = (alarm) => {
  return {
    id: alarm.alarm_id,
    num: alarm.alarm_num,
    causeId: alarm.alarm_cause_id,
    causeNum: alarm.alarm_cause_num,
    severity: alarm.alarm_severity,
    message: alarm.alarm_message,
    causeMessage: alarm.alarm_cause_message,
    severityWord: alarm.alarm_severity_word,
    description: alarm.alarm_description,
  };
};

/**
 * Compute the actual time difference
 * @param {moment.Moment} time1 either the end time, the start time or the current time
 * @param {moment.Moment} time2 either the end time, the start time or the current time
 * @returns {string}
 */
export const toLocaleTimeDifference = (time1, time2) => {
  let time_diff = time2.diff(time1);
  let prefix = '';

  if (isNaN(time_diff)) return '';
  if (time_diff < 0) {
    prefix += '-';
    time_diff = Math.abs(time_diff);
  }

  let seconds = Math.floor(time_diff / 1000) % 60;
  let minutes = Math.floor((time_diff / (1000 * 60)) % 60);

  const timeNumber = Intl.NumberFormat('en-US', {
    minimumIntegerDigits: 2,
  });

  seconds = timeNumber.format(seconds);
  minutes = timeNumber.format(minutes);

  return `${prefix}${minutes}:${seconds}`;
};

export const convertTimestamp = (timestamp) => {
  const date = new Date(timestamp.secs * 1000 + timestamp.nsecs / 1000000);
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  let hours = date.getHours();
  const amPm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12; // Convert to 12-hour format
  const hoursStr = hours.toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const offsetMinutes = date.getTimezoneOffset();
  const offsetHours = Math.abs(offsetMinutes / 60);
  const offsetSign = offsetMinutes > 0 ? '-' : '+';
  const timezone = `GMT${offsetSign}${offsetHours.toString().padStart(2, '0')}:${(Math.abs(offsetMinutes) % 60).toString().padStart(2, '0')}`;
  return `${month}/${day} ${hoursStr}:${minutes} ${amPm} ${timezone}`;
};

/**
 * Takes in a string and boolean to convert a snake case string to a normal one
 * @param {String} string
 * @param {Boolean} withSpace
 * @returns a formatted string that's in title case; with or without spaces; 'hello_world' => Hello World/HelloWorld
 */
export const snakeCaseToNormal = (string) => {
  if (string && string.length > 0) {
    return string
      .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())
      .replace(/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase());
  } else {
    return string;
  }
};

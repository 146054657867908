import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useLocation} from 'react-router-dom';
import {styled} from '@mui/material/styles';

import Typography from '@mui/material/Typography';
import DraggableDialog from '../components/DraggableDialog';
import greenTick from '../assets/green_tick.svg';
import redCross from '../assets/red_cross.svg';
import warning from '../assets/warning.svg';
import nodeDown from '../assets/node_down.svg';

const Table = ({data}) => {
  const StyledBody = styled('div')({
    margin: '2em',
  });
  const StyledBodyText = styled(Typography)({
    fontWeight: '700',
    fontSize: '1.6rem',
    textAlign: 'center',
    alignItems: 'center',
    color: 'red',
  });
  const StyledMainHeader = styled('div')({
    textAlign: 'center',
    alignItems: 'center',
  });
  const StyledHeaderText = styled(Typography)({
    fontWeight: '900',
    fontSize: '1.8rem',
  });
  const StyledTopHeader = styled(Typography)({
    fontWeight: '900',
    fontSize: '1.2rem',
    padding: '0.2em 0em 0.2em 1.5em',
    backgroundColor: '#C7C7C7',
  });
  const StyledTable = styled('table')({
    borderCollapse: 'collapse',
    width: '100%',
  });
  const StyledTableRow = styled('tr')({
    textAlign: 'left',
    padding: '1rem',
  });
  const StyledTableData = styled('td')({
    paddingLeft: '3em',
  });

  if (data.length === 0) {
    return (
      <StyledBody>
        <StyledMainHeader>
          <StyledHeaderText>Diagnostics Aggregator</StyledHeaderText>
        </StyledMainHeader>
        <div style={{padding: '5rem'}}>
          <StyledBodyText>!!! No Data Found. !!!</StyledBodyText>
        </div>
      </StyledBody>
    );
  }

  return (
    <StyledBody>
      <StyledMainHeader>
        <StyledHeaderText>Diagnostics Aggregator</StyledHeaderText>
      </StyledMainHeader>
      <div style={{margin: '1rem 0rem 0rem 1rem'}}>
        {data.map((val, key) => (
          <div key={key}>
            <StyledTopHeader>
              {val.key
                .replaceAll('_', ' ')
                .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                  letter.toUpperCase()
                )}{' '}
              {val.value.length < 1 && (
                <img src={nodeDown} width={20} height={20} alt="node_down" />
              )}
            </StyledTopHeader>
            {val.value.length > 0 &&
              val.value.map((element, key) => (
                <StyledTable key={key}>
                  <StyledTableRow>
                    <StyledTableData style={{textAlign: 'left'}}>
                      {element.key}
                      <span> </span>
                      {(element.value === 'Alive' &&
                        element.key.includes('Heartbeat')) ||
                      (element.value.includes('Desired frequency met') &&
                        element.key.includes('Frequency')) ||
                      element.value === 'OK' ? (
                        <img
                          src={greenTick}
                          width={20}
                          height={20}
                          alt="green_tick"
                        />
                      ) : element.value === 'Stale' ? (
                        <img
                          src={redCross}
                          width={20}
                          height={20}
                          alt="red_tick"
                        />
                      ) : (
                        element.key.includes('Frequency') &&
                        (element.value === 'Frequency too low.' ||
                          element.value === 'Frequency too high.') && (
                          <img
                            src={warning}
                            width={20}
                            height={20}
                            alt="warning"
                          />
                        )
                      )}
                    </StyledTableData>
                    <StyledTableData style={{textAlign: 'right'}}>
                      {element.value}
                    </StyledTableData>
                  </StyledTableRow>
                </StyledTable>
              ))}
          </div>
        ))}
      </div>
    </StyledBody>
  );
};

const DiagnosticsModal = ({open, onClose, diagnosticsState}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const formattedData = Object.entries(diagnosticsState).map(
      ([key, value]) => ({
        key,
        value,
      })
    );
    setData(formattedData);
  }, [diagnosticsState]);

  return (
    <div style={{width: '100%', position: 'relative'}}>
      <DraggableDialog
        open={open}
        onClose={onClose}
        aria-labelledby="jog-dialog"
        fullWidth
        maxWidth="md"
      >
        <Table data={data} />
      </DraggableDialog>
    </div>
  );
};

DiagnosticsModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  diagnosticsState: PropTypes.object.isRequired,
};

export default DiagnosticsModal;

import {styled} from '@mui/material/styles';
import {
  FaExclamationTriangle,
  FaHome,
  FaLongArrowAltLeft,
  FaLongArrowAltRight,
  FaLongArrowAltUp,
  FaRedo,
  FaUndo,
} from 'react-icons/fa';
import JogButton from '../../components/JogButton';
import {DialogContent} from '@mui/material/';

export const StyledRoot = styled(DialogContent)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
});

export const StyledColumnContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '3rem',
});

export const StyledContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

export const StyledRedMessage = styled('p')({
  color: 'red',
  textAlign: 'center',
  fontWeight: 'bold',
  fontSize: '1.5rem',
});

export const StyledIcon = styled('span')({
  fontSize: '5.5rem',
});

export const StyledFaLongArrowAltUpIcon = styled(FaLongArrowAltUp)({
  fontSize: '3.5rem',
});

export const StyledSmallIconExclamation = styled(FaExclamationTriangle)({
  fontSize: '2em',
  marginBottom: '1.25rem',
});
export const StyledFaLongArrowAltRight = styled(FaLongArrowAltRight)({
  fontSize: '2em',
  marginBottom: '1.25rem',
});
export const StyledFaLongArrowAltLeft = styled(FaLongArrowAltLeft)({
  fontSize: '2em',
  marginBottom: '1.25rem',
});
export const StyledFaUndo = styled(FaUndo)({
  fontSize: '2em',
  marginBottom: '1.25rem',
});
export const StyledFaRedo = styled(FaRedo)({
  fontSize: '2em',
  marginBottom: '1.25rem',
});

export const StyledSmallImgIcon = styled('img')({
  width: '3rem',
  height: '3rem',
});

export const StyledMediumIcon = styled('span')({
  fontSize: '3em',
  marginBottom: '1.25rem',
});
export const StyledHomeMediumIcon = styled(FaHome)({
  fontSize: '3em',
  marginBottom: '1.25rem',
});

export const StyledExtraSmallIcon = styled('span')({
  fontSize: '2rem',
  marginBottom: '1rem',
});

export const StyledButtonContainer = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  paddingBottom: '1rem',
});

export const StyledAlarmIcon = styled('span')({
  marginLeft: 'auto',
});

export const StyledSmallButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: '0.5rem',
  gap: '1rem',
});

export const StyledSmallButton = styled(JogButton)({
  width: '7rem',
  height: '4.275rem',
});

export const StyledSmallJogButtonWide = styled(JogButton)({
  height: '7rem',
  marginBottom: '1rem',
});

export const StyledSecondaryText = styled('p')(({theme}) => ({
  color: theme.palette.secondary.main,
}));

export const StyledRebootText = styled('p')({
  textAlign: 'center',
});

export const StyledSmallButtonLabel = styled('span')(({theme}) => ({
  position: 'absolute',
  bottom: theme.spacing(1),
  fontSize: '1em',
}));

// Left Pane, Center Pane, and Right Pane
export const StyledLeftPane = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  gap: '1rem',
  borderRight: 'solid 0.0625rem rgba(0, 0, 0, 0.1)',
  paddingRight: '1rem',
});

export const StyledCenterPane = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 3,
  alignItems: 'center',
});

export const StyledRightPane = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  gap: '1rem',
  borderLeft: 'solid 0.0625rem rgba(0, 0, 0, 0.1)',
  paddingLeft: '1rem',
});

// Additional Styles
export const StyledVerticalSeparator = styled('hr')({
  width: '90%',
  height: '0.0625rem',
  backgroundColor: 'rgba(0, 0, 0, 0.1)',
  padding: 0,
  margin: 0,
});

export const StyledContainerStyle = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '50em',
  height: '18em',
  padding: '1rem 1rem 0.5rem',
  alignItems: 'center',
});

export const StyledNegativeRootBtnStyle = styled('button')(({theme}) => ({
  maxHeight: '9em',
  background: theme.palette.secondary.main,
}));

export const StyledNegativeIconBtnStyle = styled('span')({
  fontWeight: 900,
  fontSize: '3rem',
});

export const StyledPositiveBtnStyle = styled('button')({
  minWidth: '5em',
  fontWeight: 900,
  background: '#e0e0e0',
  fontSize: '3rem',
  padding: '0.2em',
});

export const StyledButtonSmallIcon = styled('span')({
  fontSize: '3rem',
  paddingBottom: '1.5rem',
});

export const StyledRightContainer = styled(StyledContainer)(({theme}) => ({
  flex: 1,
  borderLeft: `0.125rem solid ${theme.palette.divider}`,
  marginLeft: '2rem',
}));

import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, {useRef, useState} from 'react';

import {Button, Typography} from '@mui/material/';
import {Tooltip} from 'react-tooltip';
import DraggableDialog from '../components/DraggableDialog';
import MisoDialogTitle from '../components/MisoDialogTitle';
import {FaExclamationTriangle} from 'react-icons/fa';
import {IconComponent} from '../util';

const StyledContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-around',
});

const StyledIcon = styled('div')({
  fontSize: '5.5rem',
});

const StyledButtonContainer = styled('div')({
  width: '15rem',
  height: '15rem',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
});

const StyledButtonContainerConfirmation = styled('div')({
  float: 'center',
  display: 'flex',
  justifyContent: 'space-evenly',
});

const StyledButton = styled(Button)({
  borderStyle: 'solid',
  borderWidth: '0.3125rem',
  margin: '1em',
  width: '100%',
  height: '5rem',
});

const StyledInfoPadding = styled('div')({
  paddingLeft: '0.75rem',
});

const StyledContainerStyle = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '50em',
  height: '18em',
  padding: '1rem 1rem 0.5rem',
  alignItems: 'center',
});

const StyledNegativeBtnStyle = styled('button')(({theme}) => ({
  maxWidth: '7em',
  fontWeight: '900',
  background: theme.palette.secondary.main,
  fontSize: '3rem',
}));

const StyledPositiveBtnStyle = styled('button')({
  minWidth: '7em',
  fontWeight: '900',
  background: '#e0e0e0',
  fontSize: '3rem',
});

const StyledBottomPaneStyle = styled('div')({
  gap: '3.125rem',
  display: 'flex',
  justifyContent: 'space-between',
});

const StyledSmallIcon = styled('div')({
  fontSize: '3rem',
  paddingBottom: '1.5rem',
});

const ResolveCollisionModal = (props) => {
  const {
    open,
    onClose,
    notificationState,
    openJogModal,
    jogRobot,
    jogging,
    last_mode_user,
    troubleshootingReady,
  } = props;

  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const sourceRef = useRef(null);
  const window_title = 'Resolve Collision';
  const window_query = 'How would you like to resolve the collision?';

  const handleOnCloseClick = () => {
    setConfirmModalVisible(false);
  };

  return (
    <div>
      {notificationState && notificationState.length > 0 && (
        <DraggableDialog
          open={open}
          onClose={onClose}
          aria-labelledby="jog-confirmation-dialog"
          fullWidth={true}
          maxWidth="md"
        >
          <MisoDialogTitle
            id="jog-confirmation-dialog"
            onClose={onClose}
            style={{background: 'grey'}}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <FaExclamationTriangle style={{marginRight: '0.5em'}} />
              <span>{window_title}</span>
            </div>
          </MisoDialogTitle>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="h5"
              style={{textAlign: 'center', paddingBottom: '2rem'}}
            >
              Troubleshooting Mode {jogging ? 'DISABLED' : 'ENABLED'} by: <br />
              <b>{last_mode_user}</b>
            </Typography>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Typography
                variant="h5"
                style={{
                  fontWeight: 'bold',
                  paddingRight: '1rem',
                  paddingBottom: '1rem',
                }}
              >
                System Ready for Troubleshooting:
              </Typography>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <IconComponent
                icon2="Barrier"
                width="2.5vw"
                color={troubleshootingReady ? 'green' : 'red'}
              />
              <Typography
                variant="h5"
                style={{fontWeight: 'bold', paddingLeft: '0.5rem'}}
              >
                {troubleshootingReady ? 'YES' : 'NO'}
              </Typography>
            </div>
          </div>
          <p
            style={{
              color: 'red',
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: '1.5rem',
            }}
          >
            {window_query}
          </p>
          <StyledButtonContainerConfirmation>
            <StyledInfoPadding>
              <a
                ref={sourceRef}
                data-tooltip-id="source-custom-event"
                data-tooltip-html="Use this option if: <br><li>The robot is stuck in a way where any piece of the robot arm (including baskets when it's grabbing them) is in contact with another equipment, OR</li><li>The sleeve is completely bunched in a specific location, OR</li><li>The arm is in contact with itself</li>"
                data-tooltip-place="right"
              >
                <IconComponent
                  icon2="Information"
                  height={30}
                  width={30}
                  color="#000000"
                />
              </a>
              <Tooltip id="source-custom-event" clickable />
            </StyledInfoPadding>
            <StyledButton
              onClick={() => {
                jogRobot();
                onClose();
                if (!jogging) {
                  openJogModal();
                }
              }}
              style={{color: 'black'}}
            >
              <Typography variant="h5" color="black">
                Abort Action and Jog the Robot
              </Typography>
            </StyledButton>
          </StyledButtonContainerConfirmation>
        </DraggableDialog>
      )}
    </div>
  );
};

ResolveCollisionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  openJogModal: PropTypes.func.isRequired,
  jogging: PropTypes.bool.isRequired,
  jogRobot: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  notificationState: PropTypes.arrayOf(PropTypes.object).isRequired,
  elevatorState: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ResolveCollisionModal;

import React, {memo} from 'react';
import {connect} from 'react-redux';
import {smartCompare} from '../../util';

import {
  StyledBottomPane,
  StyledCenterPane,
  StyledMisoLogo,
  StyledSignInPane,
  StyledText,
  StyledTopPane,
} from './styles';

const SignIn = ({appState}) => {
  const isAppLoading = appState.isLoading;

  return (
    <StyledSignInPane>
      <StyledTopPane>
        <StyledMisoLogo />
      </StyledTopPane>
      <StyledCenterPane>
        <StyledText>Miso Support Center</StyledText>
      </StyledCenterPane>
      <StyledBottomPane>
        {isAppLoading && <p>Loading...</p>}
        <div
          id="google-btn"
          style={{display: isAppLoading ? 'none' : 'block'}}
        ></div>
      </StyledBottomPane>
    </StyledSignInPane>
  );
};

const mapState = ({instances, appState}) => ({
  instances,
  appState,
});

const mapDispatch = (dispatch) => ({
  dispatch: (action) => dispatch(action),
});

const Controller = memo(SignIn, smartCompare(['appState']));
export default connect(mapState, mapDispatch)(Controller);

import {Button, Card, Typography} from '@mui/material/';
import {styled} from '@mui/material/styles';
import React from 'react';
import {colors} from '../styles';

const TextTypography = styled(Typography)({
  paddingTop: '0.5rem',
  paddingLeft: '0.5rem',
  fontSize: '1.2rem',
  fontWeight: 600,
  textAlign: 'left',
});

const StyledMainBody = styled('div')({
  position: 'relative',
  maxHeight: '9.375rem',
  maxWidth: '95%',
  margin: '0 auto',
});

const StyledElevatorPortionCard = styled(Card)(({hasNotifications}) => ({
  ...(!hasNotifications
    ? {
        width: '28vw',
        height: '20%',
        position: 'relative',
        border: '0.0625rem solid #000000',
      }
    : {
        width: '28vw',
        height: '20%',
        position: 'relative',
        border: '0.1875rem solid #FC648F',
        background: '#FCD2DE',
      }),
}));

const StyledHopperHeader = styled('div')({
  height: '5.625rem',
  justifyContent: 'space-between',
  '& .clear': {
    margin: '0.9375rem 0.625rem 0.625rem 0.625rem',
    border: '0.0625rem solid black',
    borderRadius: '0.0625rem',
    fontWeight: 600,
    background: colors.grayish,
    float: 'right',
  },
  '& .action': {
    margin: '1.5625rem 0.625rem 0.9375rem 0.625rem',
    fontWeight: 400,
  },
});

const StyledConfirmBox = styled('div')({
  display: 'flex',
  float: 'right',
  '& .confirm': {
    margin: '0.9375rem 0.625rem 0.625rem 0.625rem',
    border: '0.0625rem solid black',
    borderRadius: '0.0625rem',
    fontWeight: 600,
    background: colors.lightRed,
  },
  '& .cancelClear': {
    cursor: 'pointer',
  },
});

const StyledHopperBtns = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

const StyledHopperBtnBody = styled('div')({
  background: colors.grayish,
  display: 'flex',
  border: `0.0625rem solid ${colors.lightGray}`,
  borderRadius: '0.25rem',
  alignItems: 'center',
  width: '100%',
  margin: '0.625rem',
  justifyContent: 'space-around',
});

const StyledCountBox = styled('div')({
  display: 'block',
  alignItems: 'center',
  textAlign: 'center',
  borderRight: `0.0625rem solid ${colors.lightGray}`,
  '& .size': {
    fontSize: '1.5em',
    fontWeight: 500,
  },
});

const StyledArrowBox = styled('div')({
  textAlign: 'center',
  '& .sizeLarge': {
    fontSize: '1.5em',
    fontWeight: 500,
  },
  '& .arrowIcon': {
    transform: 'rotate(180deg)',
  },
});

const StyledWarningOverlay = styled('div')({
  alignItems: 'center',
  backgroundColor: '#ED7C9D',
  display: 'flex',
  color: '#000000',
  fontSize: '2em',
  fontWeight: 'bold',
  height: '100%',
  justifyContent: 'center',
  position: 'absolute',
  opacity: '0.9',
  width: '100%',
  zIndex: 1,
});

const Elevator = ({
  elevatorState,
  openElevatorModal,
  openElevatorConfirmation,
  jogging,
}) => {
  const missingBin =
    elevatorState.notifications &&
    elevatorState.notifications.some(
      (alarm) => alarm.code === 'elevator_bin_missing'
    );

  const doorOpen =
    elevatorState.notifications &&
    elevatorState.notifications.some(
      (alarm) => alarm.code === 'elevator_trapdoor_stuck'
    );

  const handleClick = () => {
    jogging ? openElevatorModal() : openElevatorConfirmation();
  };

  return (
    <StyledMainBody>
      <Button onClick={handleClick}>
        <StyledElevatorPortionCard
          hasNotifications={
            elevatorState.notifications &&
            elevatorState.notifications.length > 0
          }
        >
          <div>
            <TextTypography>Position: {elevatorState.pose}</TextTypography>
            <TextTypography>
              Door:{' '}
              {elevatorState.pose === 'dump' || doorOpen ? 'Open' : 'Closed'}
            </TextTypography>
            <TextTypography>
              Bin: {missingBin ? 'Missing' : 'In Place'}
            </TextTypography>
            <TextTypography>
              Food:{' '}
              {elevatorState.ticket && elevatorState.ticket.length > 0
                ? 'Full'
                : 'Empty'}
            </TextTypography>
          </div>
        </StyledElevatorPortionCard>
      </Button>
    </StyledMainBody>
  );
};

export default Elevator;

import {styled} from '@mui/material/styles';
import {colors} from '../../styles';

export const StyledTableContainer = styled('div')({
  height: '100%',
});

export const StyledTable = styled('table')({
  width: '100%',
  height: '100%',
  borderCollapse: 'collapse',
});

export const StyledTableName = styled('th')({
  fontSize: '0.85em',
  padding: '0.75em',
  color: '#000000',
  textAlign: 'left',
  backgroundColor: colors.lightGray,
});

export const StyledTableHeader = styled('th')({
  padding: '1em',
  color: '#000000',
});

export const StyledTableData = styled('td')({
  padding: '0em 0.5em',
  color: '#000000',
});

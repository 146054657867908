import {snakeCaseToNormal} from '../util';

const useTranslationAlerts = () => {
  const getMessage = (key, path, text, text2, data) => {
    const safeParseData = (data, defaultValue = {}) => {
      try {
        return data ? JSON.parse(data) : defaultValue;
      } catch {
        return defaultValue;
      }
    };

    const parsedData = safeParseData(data);
    const generateMessage =
      {
        abandoned_basket: {
          title: (path) =>
            `${snakeCaseToNormal('place_the_unrecognized_basket_cooking')} ${path} ${snakeCaseToNormal('on_a_fryer_shelf')}`,
          severity: 'red',
        },
        autobasket_needed: {
          title: snakeCaseToNormal(
            'finished_order_waiting_for_available_autobasket_drawer'
          ),
          severity: 'black',
        },
        autobasket_wanted: {
          title: snakeCaseToNormal('empty_an_autobasket_drawer'),
          severity: 'black',
        },
        basket_misaligned: {
          title: (path) =>
            `${snakeCaseToNormal('autobasket_is_not_straight')} ${path}`,
          severity: 'red',
        },
        basket_obscured: {
          title: snakeCaseToNormal(
            'clean_basket_markers_or_try_a_different_basket'
          ),
          severity: 'black',
        },
        basket_partially_obscured: {
          title: (path) =>
            `${snakeCaseToNormal('marker_is_dirty_on_autobasket')} ${parsedData?.basket_id}`,
          severity: 'black',
        },
        basket_rotated: {
          title: (path) => `${snakeCaseToNormal('basket_is_backward')} ${path}`,
          severity: 'red',
        },
        camera_blurry: {
          title: (path) =>
            `${snakeCaseToNormal('flippy_cant_see_the')} ${path}`,
          severity: 'black',
        },
        camera_disconnected: {
          title: (path) =>
            `${snakeCaseToNormal('flippy_cant_see_the')} ${path}`,
          severity: 'black',
        },
        dispenser_connection_error: {
          title: (path, text, text2) =>
            `${snakeCaseToNormal('cant_dispense')} ${text}`,
          severity: 'black',
        },
        dispenser_door_left_open: {
          title: snakeCaseToNormal('dispenser_door_has_been_open_awhile'),
          severity: 'black',
        },
        dispenser_empty: {
          title: (path, text) =>
            `${snakeCaseToNormal('food_dispenser_ran_out_of')} ${text}`,
          severity: 'black',
        },
        dispenser_generic_error: {
          title: (path, text, text2) =>
            `${snakeCaseToNormal('cannnot_dispense')} ${text}`,
          severity: 'black',
        },
        dispenser_not_locked: {
          title: snakeCaseToNormal('dispenser_is_not_in_place'),
          severity: 'black',
        },
        dispenser_tare_shift: {
          title: (path, text) =>
            `${text} ${snakeCaseToNormal('may_be_jammed_in_the_dispenser')}`,
          severity: 'black',
        },
        drawer_left_open: {
          title: (path) =>
            `${snakeCaseToNormal('close_the')} ${path.replace(/in/g, '')}`,
          severity: 'black',
        },
        duplicate_basket: {
          title: (path) =>
            `${snakeCaseToNormal('remove_one_of_the_autobaskets_with_the_same_id')} ${path}`,
          severity: 'red',
        },
        elevator_bin_missing: {
          title: snakeCaseToNormal('food_elevator_bin_is_not_in_place'),
          severity: 'black',
        },
        perception_invalid: {
          title: (path) =>
            `${snakeCaseToNormal('flippy_cant_see_the')} ${path}`,
          severity: 'red',
        },
        fryer_not_locked: {
          title: (path) => `${path} ${snakeCaseToNormal('is_not_in_place')}`,
          severity: 'red',
        },
        handle_in_system: {
          title: snakeCaseToNormal(
            'flippy_had_to_stop_because_a_handle_was_left_on_a_basket'
          ),
          severity: 'red',
        },
        incorrect_fryer_position: {
          title: (path) =>
            `${snakeCaseToNormal('return')} ${path.toLowerCase()} ${snakeCaseToNormal('to_their_correct_position')} `,
          severity: 'red',
        },
        incorrect_hanger: {
          title: snakeCaseToNormal('fryer_shelves_are_not_in_the_right_order'),
          severity: 'black',
        },
        missing_hanger: {
          title: (path) =>
            `${snakeCaseToNormal('fryer')} ${path.replace(/shelf/g, '')} ${snakeCaseToNormal('is_missing_its_fryer_shelf')}`,
          severity: 'black',
        },
        no_available_hanger_slots: {
          title: snakeCaseToNormal('install_at_least_one_fryer_shelf'),
          severity: 'red',
        },
        not_visible: {
          title: (path) =>
            `${snakeCaseToNormal('make_sure_the_markers_on')} ${path} ${snakeCaseToNormal('are_clean_and_can_be_seen_by_flippy')}`,
          severity: 'red',
        },
        slot_partially_obscured: {
          title: (path) => `${path} ${snakeCaseToNormal('marker_is_dirty')}`,
          severity: 'black',
        },
        unrecognized_object: {
          title: snakeCaseToNormal(
            'flippy_spotted_something_he_doesnt_recognize_and_had_to_stop'
          ),
          severity: 'red',
        },
        unidentified_basket: {
          title: (path) =>
            `${snakeCaseToNormal('remove_the_unrecognized_basket_cooking')} ${path} ${snakeCaseToNormal('from_the_system')} `,
          severity: 'red',
        },
        elevator_trapdoor_stuck: {
          title: snakeCaseToNormal('food_elevator_door_is_stuck'),
          severity: 'black',
        },
        no_available_fryer_slots: {
          title: snakeCaseToNormal('flippy_has_no_available_cooking_space'),
          severity: 'red',
        },
        no_available_interface_slots: {
          title: snakeCaseToNormal('all_autobasket_drawers_are_disabled'),
          severity: 'black',
        },
        no_available_baskets: {
          title: snakeCaseToNormal('no_autobaskets_in_the_system'),
          severity: 'red',
        },
        no_cooking_space: {
          title: snakeCaseToNormal('no_available_space_to_cook_this_basket'),
          severity: 'red',
        },
        not_enough_baskets: {
          title: `${snakeCaseToNormal('add_up_to')} ${parsedData?.min_required_baskets - parsedData?.num_baskets} ${snakeCaseToNormal('empty_autobaskets_for_faster_cooking')}`,
          severity: 'black',
        },
        too_many_baskets: {
          title: `${snakeCaseToNormal('remove')} ${parsedData?.num_baskets - parsedData?.max_allowed_baskets} ${snakeCaseToNormal('autobaskets_for_faster_cooking')}`,
          severity: 'red',
        },
        too_many_slots_disabled: {
          title: snakeCaseToNormal('almost_out_of_available_cooking_slots'),
          severity: 'black',
        },
        communication_error: {
          title: snakeCaseToNormal('flippy_had_a_critical_error'),
          severity: 'red',
        },
        collision: {
          title: snakeCaseToNormal(
            'flippy_bumped_into_something_unexpectedly_support_will_help_soon'
          ),
          severity: 'red',
        },
        general_alarm: {
          title: snakeCaseToNormal('flippy_had_a_critical_error'),
          severity: 'red',
        },
        unknown_alarm: {
          title: snakeCaseToNormal('flippy_had_a_critical_error'),
          severity: 'red',
        },
        e_stopped: {
          title: snakeCaseToNormal(
            'flippy_stopped_because_the_emergency_stop_button_was_pressed'
          ),
          severity: 'red',
        },
        e_stop: {
          title: snakeCaseToNormal(
            'flippy_stopped_because_the_emergency_stop_button_was_pressed'
          ),
          severity: 'red',
        },
        reset_needed: {
          title: snakeCaseToNormal('flippy_is_ready_to_resume_cooking'),
          severity: 'red',
        },
        fryer_door: {
          title: snakeCaseToNormal(
            'barrier_doors_opened_unexpectedly_always_stop_flippy_first'
          ),
          severity: 'red',
        },
      }[key] || {};

    const getTranslatedMessage = (messageFunc) => {
      if (typeof messageFunc === 'function') {
        return messageFunc(path, text, text2, data);
      }
      return messageFunc;
    };

    return {
      title: getTranslatedMessage(generateMessage.title),
      severity: getTranslatedMessage(generateMessage.severity),
    };
  };

  return {
    getMessage,
  };
};

export default useTranslationAlerts;

import {
  StyledTable,
  StyledTableContainer,
  StyledTableData,
  StyledTableName,
} from './styles';
import React from 'react';
import {
  GrayErrorLegend,
  GrayQuestionLegend,
  GreenCheckLegend,
  RedErrorLegend,
  YellowCautionLegend,
} from '../../constants/arrays';

const tableHeaders = ['Status Icon', 'Status', 'Description'];

const legendData = [
  {
    icon: (
      <GreenCheckLegend.Icon
        style={{...GreenCheckLegend.legendStyle, fontSize: '2em'}}
      />
    ),
    status: 'OK',
    description: 'Everything is working fine.',
  },
  {
    icon: (
      <YellowCautionLegend.Icon
        style={{...YellowCautionLegend.legendStyle, fontSize: '2em'}}
      />
    ),
    status: 'WARN',
    description: 'If a warning threshold was set and it was exceeded.',
  },
  {
    icon: (
      <RedErrorLegend.Icon
        style={{...RedErrorLegend.legendStyle, fontSize: '2em'}}
      />
    ),
    status: 'ERROR',
    description: "There's an issue or sensor were triggered.",
  },
  {
    icon: (
      <GrayErrorLegend.Icon
        style={{...GrayErrorLegend.legendStyle, fontSize: '2em'}}
      />
    ),
    status: 'STALE',
    description: 'Status are not getting updated.',
  },
  {
    icon: (
      <GrayQuestionLegend.Icon
        style={{...GrayQuestionLegend.legendStyle, fontSize: '2em'}}
      />
    ),
    status: 'UNKNOWN',
    description: 'Cannot determine what the result is.',
  },
];

const LegendTable = () => (
  <StyledTableContainer>
    <StyledTable>
      <thead>
        <tr>
          <StyledTableName colSpan="3">
            Legend for Each Category
          </StyledTableName>
        </tr>
      </thead>
      <tbody>
        {legendData.map((item) => (
          <React.Fragment key={item.status}>
            {/* Row for the icon and status */}
            <tr
              style={{
                borderTop: '0.0625rem solid black',
                height: 'auto',
                verticalAlign: 'middle',
              }}
            >
              <StyledTableData>{item.icon}</StyledTableData>
              <StyledTableData>{item.status}</StyledTableData>
            </tr>

            {/* Row for the description with a border */}
            <tr
              style={{
                borderBottom: '0.0625rem solid black',
                height: 'auto',
                verticalAlign: 'middle',
              }}
            >
              <StyledTableData colSpan="2">{item.description}</StyledTableData>
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    </StyledTable>
  </StyledTableContainer>
);

export default LegendTable;

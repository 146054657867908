import React, {useState} from 'react';
import PropTypes from 'prop-types';
import DraggableDialog from '../../components/DraggableDialog';
import MisoDialogTitle from '../../components/MisoDialogTitle';
import {
  disableSlot,
  elevatorManual,
  jogElevator,
  mqtt_ap,
  resetElevator,
} from '../../ros_init';
import {DISABLING_LEVEL, ELEVATOR_COMMANDS} from '../../enums';
import {callService} from '../../util';
import {StyledContainer, StyledRoot, StyledSmallButtonWide} from './styles';
import {
  StyledButtonContainer,
  StyledSmallIconExclamation,
} from '../JogModal/styles';

const ElevatorModal = ({open, jogging, onClose, elevatorState, instance}) => {
  const [state, setState] = useState({
    resetting: false,
    resetSuccess: false,
    resetFailed: null,
    rebooting: false,
    rebootSuccess: false,
  });

  const moveElevator = async (position) => {
    elevatorManual[instance].setData = {};
    try {
      await mqtt_ap.submit_request(elevatorManual[instance]);
    } catch (error) {
      console.error('Submit_Request error:', error);
    }
    jogElevator[instance].setData = {
      task: position,
    };
    try {
      await mqtt_ap.submit_request(jogElevator[instance]);
    } catch (error) {
      console.error('Submit_Request error:', error);
    }
  };

  const clearElevatorAlarm = () => {
    const request = {};
    setState((prev) => ({...prev, resetting: true, resetFailed: false}));

    const allCallback = () => setState((prev) => ({...prev, resetting: false}));
    const successCallback = () => {
      setState((prev) => ({...prev, resetSuccess: true}));
      setTimeout(
        () => setState((prev) => ({...prev, resetSuccess: false})),
        1500
      );
    };
    const errorCallback = () => {
      setState((prev) => ({...prev, resetFailed: true}));
      setTimeout(
        () => setState((prev) => ({...prev, resetFailed: null})),
        1500
      );
    };

    callService(
      resetElevator,
      instance,
      request,
      'Clear Elevator Alarms',
      allCallback,
      successCallback,
      errorCallback
    );
  };

  const disableElevatorAlarm = (disabled) => {
    const request = {
      user: {
        permissions: DISABLING_LEVEL.UI_USER,
        name: DISABLING_LEVEL.UI_USER,
      },
      component_path: '/agent/elevator_dispenser',
      disabled: disabled,
      reason: 'Toggle Disable Elevator',
    };

    callService(disableSlot, instance, request, 'Toggle Disable Elevator');
  };

  const elevatorAlarms = elevatorState?.notifications?.filter(
    (alarm) => alarm.code === 'collision'
  );
  const alarmActive = elevatorAlarms?.length > 0;

  return (
    <div>
      {jogging && (
        <DraggableDialog
          open={open}
          onClose={onClose}
          aria-labelledby="jog-dialog"
          fullWidth
          maxWidth="sm"
        >
          <MisoDialogTitle id="jog-dialog" onClose={onClose}>
            {'Jog Elevator'}
          </MisoDialogTitle>
          <StyledRoot>
            <StyledContainer>
              <div style={{position: 'relative'}}>
                <StyledButtonContainer>
                  <StyledSmallButtonWide
                    label="Send to Dump Position"
                    onClick={() => moveElevator(ELEVATOR_COMMANDS.DUMP)}
                    disabled={alarmActive || elevatorState.disabled}
                  />
                </StyledButtonContainer>
                <StyledButtonContainer>
                  <StyledSmallButtonWide
                    label="Send to Home Position"
                    onClick={() => moveElevator(ELEVATOR_COMMANDS.HOME)}
                    disabled={alarmActive || elevatorState.disabled}
                  />
                </StyledButtonContainer>
                <StyledButtonContainer>
                  <StyledSmallButtonWide
                    label="Send to Receive Food Position"
                    onClick={() => moveElevator(ELEVATOR_COMMANDS.RECEIVE_FOOD)}
                    disabled={alarmActive || elevatorState.disabled}
                  />
                </StyledButtonContainer>
                <StyledButtonContainer>
                  <StyledSmallButtonWide
                    label="Clear Elevator Alarms"
                    onClick={clearElevatorAlarm}
                    disabled={
                      !alarmActive && !state.rebooting && !state.rebootSuccess
                    }
                    color={alarmActive ? 'error' : 'default'}
                    success={state.resetSuccess}
                    failed={state.resetFailed}
                  >
                    <StyledSmallIconExclamation />
                  </StyledSmallButtonWide>
                </StyledButtonContainer>
                <StyledButtonContainer>
                  <StyledSmallButtonWide
                    label={
                      elevatorState.disabled
                        ? 'Enable Elevator'
                        : 'Disable Elevator'
                    }
                    onClick={() =>
                      disableElevatorAlarm(!elevatorState.disabled)
                    }
                  />
                </StyledButtonContainer>
              </div>
            </StyledContainer>
          </StyledRoot>
        </DraggableDialog>
      )}
    </div>
  );
};

ElevatorModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  instance: PropTypes.string.isRequired,
  jogging: PropTypes.bool.isRequired,
  elevatorState: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ElevatorModal;

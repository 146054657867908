import {styled} from '@mui/material/styles';
import {ReactComponent as MisoLogo} from '../../assets/new_miso_logo.svg';
export const StyledSignInPane = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  width: '100%',
  height: '100%',
  margin: 0,
  padding: 0,
  gap: '1.09375rem',
}));
export const StyledTopPane = styled('div')(() => ({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'center',
  flex: 2,
  backgroundColor: '#fff',
}));
export const StyledMisoLogo = styled(MisoLogo)(() => ({
  width: '50rem',
  color: '#3a2e33',
}));
export const StyledCenterPane = styled('div')(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  flex: 1,
  margin: 0,
  padding: 0,
}));
export const StyledText = styled('div')(() => ({
  color: '#b2b2b2',
  fontSize: '2.5rem',
  margin: 0,
  padding: 0,
}));
export const StyledBottomPane = styled('div')(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  flex: 2,
  backgroundColor: '#fff',
}));

import React from 'react';
import {connect} from 'react-redux';
import {Checkbox, Dialog} from '@mui/material/';
import {IconComponent} from '../../util';
import {
  StyledBottomPane,
  StyledContainer,
  StyledContent,
  StyledContentText,
  StyledLeftRightBtn,
  StyledSubtext,
  StyledTitle,
  StyledTitleContainer,
} from './styles';

const PowerSettingsModal = ({
  classes,
  open,
  title,
  content,
  subtext,
  onClose,
  textLeft,
  onClickLeft,
  textRight,
  onClickRight,
  onQuickStart,
  icon,
}) => {
  return (
    <Dialog maxWidth="md" open={open} onClose={onClose}>
      <StyledContainer>
        <StyledTitleContainer>
          {icon && (
            <IconComponent
              icon2={icon}
              width="3rem"
              height="3rem"
              color="red"
            />
          )}
          <StyledTitle>{title}</StyledTitle>
        </StyledTitleContainer>
        {content && (
          <StyledContent>
            {onQuickStart && <Checkbox onChange={onQuickStart} />}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingTop: '1rem',
              }}
            >
              <StyledContentText>{content}</StyledContentText>
              <StyledSubtext>{subtext}</StyledSubtext>
            </div>
          </StyledContent>
        )}
        <StyledBottomPane>
          {onClickLeft && (
            <StyledLeftRightBtn onClick={onClickLeft}>
              {textLeft}
            </StyledLeftRightBtn>
          )}
          {onClickRight && textRight !== '' && (
            <StyledLeftRightBtn onClick={onClickRight}>
              {textRight}
            </StyledLeftRightBtn>
          )}
        </StyledBottomPane>
      </StyledContainer>
    </Dialog>
  );
};

const mapState = ({instances}) => ({
  instances,
});

const mapDispatch = (dispatch) => ({
  dispatch: (action) => dispatch(action),
});

export default connect(mapState, mapDispatch)(PowerSettingsModal);
